import { Expose } from 'class-transformer';
import { BaseEntity } from './base.entity';

export class InstallationEventCountersEntity extends BaseEntity {
  @Expose()
  public eventType: number;

  @Expose()
  public howMany: number;
}
