import { ConstantGroup } from './base.constants';
import { UserRoleConstantEntity } from './user-role-constant.entity';

const SUPER_ADMIN = new UserRoleConstantEntity({
  value: 0,
  name: 'SUPER_ADMIN',
  translationTag: 'userRole',
  defaultMainPage: '/dashboard',
});

const COMPANY_ADMIN = new UserRoleConstantEntity({
  value: 1,
  name: 'COMPANY_ADMIN',
  translationTag: 'userRole',
  defaultMainPage: '/installations/of-my-company',
});

const EMPLOYEE = new UserRoleConstantEntity({
  value: 2,
  name: 'EMPLOYEE',
  translationTag: 'userRole',
  defaultMainPage: '/installations/of-my-company',
});

const DEMO = new UserRoleConstantEntity({
  value: 3,
  name: 'DEMO',
  translationTag: 'userRole',
  defaultMainPage: '/installations/of-my-company',
});

const USERS_TYPES: UserRoleConstantEntity[] = [SUPER_ADMIN, COMPANY_ADMIN, EMPLOYEE, DEMO];
const SA_CREATE_TYPES: UserRoleConstantEntity[] = [SUPER_ADMIN, DEMO];

export class UserRolesConstants extends ConstantGroup {
  list(): UserRoleConstantEntity[] {
    return USERS_TYPES;
  }

  superAdminsCreate(): UserRoleConstantEntity[] {
    return SA_CREATE_TYPES;
  }

  static get SUPER_ADMIN() {
    return SUPER_ADMIN;
  }

  static get COMPANY_ADMIN() {
    return COMPANY_ADMIN;
  }

  static get EMPLOYEE() {
    return EMPLOYEE;
  }

  static get DEMO() {
    return DEMO;
  }
}
