import { StringIndexedEntities } from '@utils/utils';

export const NAMED_SERIES_COLORS: StringIndexedEntities<string> = {
  default: '#2D3E50',
  environmental: '#2D3E50',
  temperature: '#2D3E50',
  humidity: '#2D3E50',
  co2: '#FDE406',
  co2down: '#B3FF00',
  co2up: '#FE5A17',
  dew: '#FF0000',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  dew_inner_hum: '#0000FF',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  dew_inner_temp: '#FFFF00',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  dew_outer_temp: '#008000',
  volume: '#FF0000',
  percentage: '#0000FF',
  frameCounter: '#6929c4',
  timeCounter: '#9f1853',
  ackCounter: '#198038',
  rssi: '#b28600',
  batteryLevel: '#002d9c',
  batteryCapacity: '#012749',
  batteryLowVoltage: '#fa4d56',
};
