import { Expose } from 'class-transformer';

export class MainMenuItemEntity {
  @Expose()
  public onlyForRoles: number[];

  @Expose()
  public notForRoles: number[];

  @Expose()
  public link: string;

  @Expose()
  public name: string;

  @Expose()
  public icon: string;

  @Expose()
  public children: MainMenuItemEntity[];

  public roleAllowed(userRoleId: number): boolean {
    return (
      (!this.onlyForRoles || !this.onlyForRoles.length || this.onlyForRoles.includes(userRoleId)) &&
      (!this.notForRoles || !this.notForRoles.length || !this.notForRoles.includes(userRoleId))
    );
  }

  @Expose()
  public visibility: () => boolean;

  public get visible(): boolean {
    return this.visibility ? this.visibility() : true;
  }
}
