<div class="d-flex justify-content-center">
  <div><span class="head-line-5">Облако CTC</span></div>
</div>
<p class="font-body-1">
  <span class="font-subtitle-2">ДЕРЖАТЕЛЬ ОБЛАКА CTC </span><br />
  В соответствии с обязанностью по предоставлению информации, изложенной в статье 10 Закона 34/2002
  от 11 июля &laquo;Об услугах информационного общества и электронной торговле&raquo;, отражаются
  следующие данные:
</p>

<span class="font-body-1">Юридическое лицо: Gescaser, S.A.</span><br />
<span class="font-body-1">К.И.Ф.: A25276635</span><br />
<span class="font-body-1">Адрес: Carrer Sant Pere 1, 25100 Almacelles (Lleida)</span><br />
<span class="font-body-1"
  >Зарегистрировано в Торговом реестре Лериды, том 81, лист 44, страница L1450, 1-я надпись. </span
><br />
<span class="font-body-1">Телефон: 973740695</span><br />
<span class="font-body-1"
  >Электронная почта:<a href="mailto:gescaser@gescaser.com">gescaser@gescaser.com</a></span
><br />

<p class="font-body-1">
  <span class="font-subtitle-2">ОБЪЕКТ</span><br />
  Контролер данных предоставляет пользователям доступ к настоящему документу в целях соблюдения
  положений Закона 34/2002 от 11 июля &laquo;Об услугах информационного общества и электронной
  коммерции&raquo; и действующих нормативных актов о защите данных, а также для информирования всех
  пользователей об условиях использования системы &laquo;CTC Cloud&raquo; через веб-поддержку и
  мобильное приложение (&laquo;web/app&raquo;).
</p>
<p class="font-body-1">
  Любое лицо, получающее доступ к этому приложению, принимает на себя роль пользователя, обязуясь
  соблюдать и неукоснительно соблюдать положения, изложенные в настоящем документе, а также любые
  другие законодательные положения, которые могут быть применимы.
</p>
<p class="font-body-1">
  Контролер данных оставляет за собой право изменять любой тип информации, которая может появиться
  на веб-сайте/приложении, без обязательства предварительного уведомления или информирования
  пользователей о таких изменениях, при этом публикация на веб-сайте/в приложении понимается как
  достаточная.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">ОТВЕТСТВЕННОСТЬ </span><br />
  Оператор данных освобождается от любой ответственности, возникающей в связи с информацией,
  опубликованной на веб-сайте/в приложении, при условии, что такая информация была изменена или
  введена третьим лицом, не имеющим к ней отношения.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">ПОЛИТИКА ССЫЛОК </span><br />
  С веб-сайта/приложения возможно перенаправление на контент со сторонних веб-сайтов. Поскольку
  Оператор данных не может контролировать контент, размещенный третьими лицами на своих веб-сайтах/в
  приложении, он не несет никакой ответственности за такой контент.
</p>
<p class="font-body-1">
  Тем не менее, Оператор данных обязуется немедленно удалить любой контент, который может
  противоречить национальному или международному законодательству, морали или общественному порядку.
</p>
<p class="font-body-1">
  Администратор не несет ответственности за информацию и контент, хранящиеся на форумах, в чатах,
  генераторах блогов, комментариях, социальных сетях или любыми другими способами, которые позволяют
  третьим лицам самостоятельно публиковать контент на Веб-сайте/вебе/приложении. В случае, если
  пользователь считает, что на веб-сайте/в приложении есть какой-либо контент, который может
  подпадать под эту классификацию, немедленно сообщите об этом.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">ЗАЩИТА ДАННЫХ </span><br />
  Контролер данных обязуется соблюдать действующие нормы защиты данных, включая GDPR и LOPDGDD.
  Кроме того, Контролер данных информирует, что он соблюдает Закон 34/2002 &laquo;Об услугах
  информационного общества и электронной коммерции&raquo; и в любое время запросит ваше согласие на
  обработку ваших данных в коммерческих целях.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">ИНТЕЛЛЕКТУАЛЬНАЯ И ПРОМЫШЛЕННАЯ СОБСТВЕННОСТЬ </span><br />
  Веб-сайт/приложение, включая его программирование, редактирование, компиляцию и другие элементы,
  необходимые для его работы, дизайн, логотипы, тексты и/или графику являются собственностью
  Администратора или имеют лицензию или специальное разрешение от авторов. Весь контент защищен
  нормами интеллектуальной и промышленной собственности.
</p>
<p class="font-body-1">
  Любое использование, не разрешенное ранее Контролером, будет считаться серьезным нарушением прав
  интеллектуальной или промышленной собственности автора. Дизайн, логотипы, тексты и/или графические
  изображения, которые не принадлежат Контролеру данных и которые могут отображаться на веб-сайте/в
  приложении, принадлежат их соответствующим владельцам.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">ПРИМЕНИМОЕ ЗАКОНОДАТЕЛЬСТВО И ЮРИСДИКЦИЯ </span><br />
  Отношения между Пользователем и Контролером данных регулируются нормативными актами, действующими
  на территории Испании. В случае возникновения спора стороны подчиняются соответствующим судьям и
  судам в соответствии с законом.
</p>
