import { SensorConstantEntity } from '@constants/sensor-constant.entity';
import { SensorTypesConstants } from '@constants/sensor-types.constants';
import { Expose, Transform, Type } from 'class-transformer';
import { BaseUUIDEntity } from './base.entity';
import { ProbeEntity } from './probe.entity';
import { SensorEntity } from './sensor.entity';
import { StringIndexedEntities } from '@utils/utils';

export class SiloEntity extends BaseUUIDEntity {
  @Expose()
  public name: string;

  @Expose()
  public grainType: number;

  @Expose()
  public desktopId: number;

  @Expose()
  public tMax: number;

  @Expose()
  public hMax: number;

  @Expose()
  public hMin: number;

  @Expose()
  public co2Max: number;

  @Expose()
  public grainDensity: number;

  @Expose()
  public volume: number;

  @Expose()
  @Transform(({ value }) => Number(value), { toClassOnly: true })
  public hubType: number;

  @Expose()
  public hubId: string;

  @Expose()
  @Type(() => SensorEntity)
  public sensors: SensorEntity[];

  public get orderedSensors(): SensorEntity[] {
    return this.sensors.sort((s1, s2) => s1.sensorType - s2.sensorType);
  }

  @Expose()
  @Type(() => ProbeEntity)
  public probes: ProbeEntity[];

  @Expose()
  public probesSensorTypes: number[];

  @Expose()
  public excelRows: StringIndexedEntities<StringIndexedEntities<string | number>>;

  public probesSensorConstants(tempUoM: string): SensorConstantEntity[] {
    return new SensorTypesConstants()
      .listProbeSensors(tempUoM)
      .filter((stc) => this.probesSensorTypes.includes(stc.value));
  }


  public get sensorsTypeSelector(): any {
    const typeSelector = new SensorTypesConstants().siloSensorTypesSelector();
    const sensorsTypeSelector = [];
    this.sensors.forEach(sensor => {
      const key = this.findKeyByValue(typeSelector, sensor.sensorType);
      if (key && !sensorsTypeSelector.includes(typeSelector[key][0])) {
        sensorsTypeSelector.push(typeSelector[key][0]);
      }
    });
    return sensorsTypeSelector;
  }


  private findKeyByValue(data, searchValue) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const entities = data[key];
        for (const entity of entities) {
          if (entity.value === searchValue) {
            return key;
          }
        }
      }
    }
    return null;
  }


  public get maxProbeNodes(): number {
    const probesNodeAmounts: number[] = [];
    for (const probe of this.probes) {
      probesNodeAmounts.push(probe.nodes.length);
    }
    return Math.max(...probesNodeAmounts);
  }
}
