import { ConstantEntity, ConstantGroup } from './base.constants';

const CTCPLUS = new ConstantEntity({
  value: 0,
  name: 'CTCPLUS',
  translationTag: 'dataProvider',
});

const CTCLITE = new ConstantEntity({
  value: 1,
  name: 'CTCLITE',
  translationTag: 'dataProvider',
});

const DATA_PROVIDERS: ConstantEntity[] = [CTCPLUS, CTCLITE];

export class DataProvidersConstants extends ConstantGroup {
  list(): ConstantEntity[] {
    return DATA_PROVIDERS;
  }

  static get CTCPLUS() {
    return CTCPLUS;
  }

  static get CTCLITE() {
    return CTCLITE;
  }
}
