<div class="container">
  <div class="register-form d-flex flex-column justify-content-around elevation-3" *ngIf="!loading">
    <div class="header">
      <div class="font-headline-2">
        {{ 'register.form.registerYourCompany' | translate }}
      </div>
    </div>
    <form [formGroup]="regForm" #f="ngForm">
      <div class="row-1">
        <div class="form-column">
          <div class="sub-header font-headline-5">
            {{ 'register.form.section.accessCredentials' | translate }}
          </div>
          <div class="mat-form-field-container">
            <span class="fake-label m3-label-large">{{ 'login.form.email' | translate }}</span>
            <mat-form-field class="input-full-width">
              <mat-label>{{ 'auth.login.emailPlaceholder' | translate }}</mat-label>
              <input matInput formControlName="email" trim="blur" type="email" />
              <mat-error *ngIf="regForm.controls.email.hasError('required')">
                {{ 'form.error.fieldRequired' | translate }}
              </mat-error>
              <mat-error
                *ngIf="
                  regForm.controls.email.hasError('email') ||
                  regForm.controls.email.hasError('pattern')
                "
              >
                {{ 'form.error.typeEmail' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="mat-form-field-container">
            <span class="fake-label m3-label-large">{{ 'login.form.password' | translate }}</span>
            <mat-form-field class="input-full-width">
              <mat-label>{{
                'register.form.placeholders.password8Characters' | translate
              }}</mat-label>
              <input
                matInput
                formControlName="password"
                name="password"
                autocomplete="on"
                type="password"
                [type]="hidePassword ? 'password' : 'text'"
              />
              <div matSuffix style="display: flex">
                <button
                  mat-icon-button
                  type="button"
                  (click)="clearPassword()"
                  [attr.aria-label]="'Clear password'"
                  [attr.aria-pressed]="hidePassword"
                  matTooltip="{{ 'password.field.clear.button.tooltip' | translate }}"
                  matTooltipClass="custom-tooltips"
                >
                  <span class="material-symbols-outlined color-wireframe-neutrals-neutral-40">
                    close
                  </span>
                </button>
                <button
                  mat-icon-button
                  type="button"
                  (click)="hidePassword = !hidePassword"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hidePassword"
                  matTooltip="{{
                    hidePassword
                      ? ('password.field.show.button.tooltip' | translate)
                      : ('password.field.hide.button.tooltip' | translate)
                  }}"
                  matTooltipClass="custom-tooltips"
                >
                  <span class="material-symbols-outlined color-wireframe-neutrals-neutral-40">
                    {{ hidePassword ? 'visibility_off' : 'visibility' }}
                  </span>
                </button>
              </div>
              <mat-error *ngIf="regForm.controls.password.hasError('required')">
                {{ 'form.error.fieldRequired' | translate }}
              </mat-error>
              <mat-error *ngIf="regForm.controls.password.hasError('pattern')">
                {{ 'form.error.password-pattern' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="form-column">
          <div class="sub-header font-headline-5">
            {{ 'register.form.personalDetails' | translate }}
          </div>
          <div class="mat-form-field-container right">
            <span class="fake-label m3-label-large">{{
              'register.form.firstname' | translate
            }}</span>
            <mat-form-field class="input-full-width">
              <mat-label>{{ 'register.form.placeholders.firstname' | translate }}</mat-label>
              <input matInput formControlName="firstname" trim="blur" type="text" />
              <mat-error *ngIf="regForm.controls.firstname.hasError('required')">
                {{ 'form.error.fieldRequired' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="mat-form-field-container right">
            <span class="fake-label m3-label-large">{{
              'register.form.lastname' | translate
            }}</span>
            <mat-form-field class="input-full-width">
              <mat-label>{{ 'register.form.placeholders.lastname' | translate }}</mat-label>
              <input matInput formControlName="lastname" trim="blur" type="text" />
              <mat-error *ngIf="regForm.controls.firstname.hasError('required')">
                {{ 'form.error.fieldRequired' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="mat-form-field-container right">
            <span class="fake-label m3-label-large">{{
              'register.form.yourPosition' | translate
            }}</span>
            <mat-form-field class="input-full-width">
              <mat-label>{{ 'register.form.placeholders.yourPosition' | translate }}</mat-label>
              <input matInput formControlName="position" trim="blur" type="text" />
            </mat-form-field>
          </div>
          <div class="phone-container">
            <app-country-code-selector
              [countryFormControl]="userPhonePrefixFormControl"
            ></app-country-code-selector>
            <div class="mat-form-field-container right">
              <span class="fake-label m3-label-large">{{
                'register.form.mobilePhoneNumber' | translate
              }}</span>
              <mat-form-field class="input-full-width">
                <mat-label>{{ 'register.form.numberExcludingCode' | translate }}</mat-label>
                <input matInput formControlName="userPhone" trim="blur" type="tel" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex row-2">
        <div class="form-column-2">
          <div class="sub-header font-headline-5">
            {{ 'register.form.companyDetails' | translate }}
          </div>
          <div class="company-details">
            <div class="column">
              <div class="mat-form-field-container">
                <span class="fake-label m3-label-large">{{
                  'company.details.companyName' | translate
                }}</span>
                <mat-form-field class="input-full-width">
                  <mat-label>{{
                    'register.form.placeholders.enterCompanyName' | translate
                  }}</mat-label>
                  <input matInput formControlName="companyName" trim="blur" type="text" />
                  <mat-error *ngIf="regForm.controls.companyName.hasError('required')">
                    {{ 'form.error.fieldRequired' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="mat-form-field-container">
                <span class="fake-label m3-label-large">{{
                  'company.form.address' | translate
                }}</span>
                <mat-form-field class="input-full-width">
                  <mat-label>{{
                    'register.form.placeholders.enterCompanyAddress' | translate
                  }}</mat-label>
                  <input matInput formControlName="address" trim="blur" type="text" />
                  <mat-error *ngIf="regForm.controls.address.hasError('required')">
                    {{ 'form.error.fieldRequired' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="mat-form-field-container">
                <span class="fake-label m3-label-large">{{
                  'register.form.townOrCity' | translate
                }}</span>
                <mat-form-field class="input-full-width">
                  <mat-label>{{ 'register.form.placeholders.townOrCity' | translate }}</mat-label>
                  <input matInput formControlName="companyTownOrCity" trim="blur" type="text" />
                </mat-form-field>
              </div>
              <div class="mat-form-field-container">
                <span class="fake-label m3-label-large">{{
                  'register.form.stateOrRegion' | translate
                }}</span>
                <mat-form-field class="input-full-width">
                  <mat-label>{{
                    'register.form.placeholders.stateOrRegion' | translate
                  }}</mat-label>
                  <input matInput formControlName="companyStateOrRegion" trim="blur" type="text" />
                </mat-form-field>
              </div>
              <div class="mat-form-field-container mat-checkbox-field-container">
                <mat-form-field class="input-full-width">
                  <mat-checkbox formControlName="agreeToPrivacyPolicy" labelPosition="after">
                    <span class="fake-label m3-label-large">{{
                      'register.form.agree-to-privacy-policy' | translate
                    }}</span>
                    <span style="color: #999999 !important">&nbsp;*</span>
                  </mat-checkbox>
                  <input matInput [hidden]="true" />
                  <div class="mat-form-field-subscript-wrapper">
                    <div style="opacity: 1; transform: translateY(0%)">
                      <span
                        class="mat-error"
                        *ngIf="
                          regForm.controls.agreeToPrivacyPolicy.hasError('required') &&
                          regForm.controls.agreeToPrivacyPolicy.touched
                        "
                      >
                        {{ 'form.error.acceptance-required' | translate }}
                      </span>
                    </div>
                  </div>
                </mat-form-field>
              </div>
            </div>
            <div class="column-2">
              <div class="phone-container">
                <app-country-code-selector
                  [countryFormControl]="companyPhonePrefixFormControl"
                ></app-country-code-selector>
                <div class="mat-form-field-container right">
                  <span class="fake-label m3-label-large">{{
                    'register.form.landlinePhoneNumber' | translate
                  }}</span>
                  <mat-form-field class="input-full-width">
                    <mat-label>{{ 'register.form.numberExcludingCode' | translate }}</mat-label>
                    <input matInput formControlName="companyPhone" trim="blur" type="tel" />
                    <mat-error *ngIf="regForm.controls.companyPhone.hasError('required')">
                      {{ 'form.error.fieldRequired' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <app-select-country
                [countryFormControl]="countryFormControl"
                [controlLabel]="'register.form.placeholders.chooseCountry'"
              ></app-select-country>
              <div class="mat-form-field-container">
                <span class="fake-label m3-label-large">{{
                  'register.form.zipPostalCode' | translate
                }}</span>
                <mat-form-field class="input-full-width">
                  <mat-label>{{
                    'register.form.placeholders.zipPostalCode' | translate
                  }}</mat-label>
                  <input matInput formControlName="companyZipCode" trim="blur" type="text" />
                </mat-form-field>
              </div>
              <div class="mat-form-field-container">
                <span class="fake-label m3-label-large">{{
                  'register.form.webSite' | translate
                }}</span>
                <mat-form-field class="input-full-width">
                  <mat-label>{{ 'register.form.placeholders.website' | translate }}</mat-label>
                  <input matInput formControlName="webSite" trim="blur" type="text" />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="data-protection font-body-2 d-flex flex-column justify-content-start align-items-start"
      >
        <div>
          <span class="font-subtitle-2 uppercase"
            >{{ 'data-protection-info.header' | translate }}:</span
          >
        </div>
        <div>
          <span class="font-subtitle-2"
            >{{ 'data-protection-info.data-controller.header' | translate }}:
          </span>
          <span>{{ 'data-protection-info.data-controller.content' | translate }}</span>
        </div>
        <div>
          <span class="font-subtitle-2"
            >{{ 'data-protection-info.purpose-of-treatment.header' | translate }}:
          </span>
          <span>{{ 'data-protection-info.purpose-of-treatment.content' | translate }}</span>
        </div>
        <div>
          <span class="font-subtitle-2"
            >{{ 'data-protection-info.legitimation-of-treatment.header' | translate }}:
          </span>
          <span>{{ 'data-protection-info.legitimation-of-treatment.content' | translate }}</span>
        </div>
        <div>
          <span class="font-subtitle-2"
            >{{ 'data-protection-info.data-retention.header' | translate }}:
          </span>
          <span>{{ 'data-protection-info.data-retention.content' | translate }}</span>
        </div>
        <div>
          <span class="font-subtitle-2"
            >{{ 'data-protection-info.recipients.header' | translate }}:
          </span>
          <span>{{ 'data-protection-info.recipients.content' | translate }}</span>
        </div>
        <div>
          <span class="font-subtitle-2"
            >{{ 'data-protection-info.rights.header' | translate }}:
          </span>
          <span>{{ 'data-protection-info.rights.content' | translate }}</span>
        </div>
        <div>
          <span class="font-subtitle-2"
            >{{ 'data-protection-info.additional-information.header' | translate }}:
          </span>
          <span
            >{{ 'data-protection-info.additional-information.content' | translate }}
            <a
              class="privacy-policy-link"
              target="_blank"
              [routerLink]="'/privacy-policy/' + currentLanguage"
              >{{ 'login.privacy-policy-link' | translate }}</a
            >.</span
          >
        </div>
      </div>
      <app-loading-spinner *ngIf="waiting"></app-loading-spinner>
    </form>
    <div class="buttons-list">
      <button
        type="button"
        mat-flat-button
        class="button-cancel font-button"
        (click)="onCancelClicked()"
      >
        <span class="material-symbols-outlined icon"> close </span>
        <span class="btn-text">{{ 'buttons.cancel' | translate }}</span>
      </button>
      <button
        type="button"
        type="submit"
        mat-flat-button
        color="primary"
        class="button-primary font-button"
        [disabled]="regForm.pristine || !regForm.valid"
        (click)="registerCompany()"
      >
        <span class="material-symbols-outlined icon"> app_registration </span>
        <span class="btn-text">{{ 'login.register' | translate }}</span>
      </button>
    </div>
  </div>
  <div class="custom-bar" *ngIf="loading">
    <app-custom-progressbar
      [progressValue]="progressValue"
      [minValue]="0"
      [maxValue]="100"
      [showStatus]="false"
      [loadingTitle]="'auth.login.loadingTitle' | translate"
    ></app-custom-progressbar>
  </div>
</div>
