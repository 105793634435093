import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@core/core.module';
import { SelectMapCoordsDialogComponent } from './select-map-coords-dialog/select-map-coords-dialog.component';
import { MapComponent } from '../map/map.component';
import { InstallationEventsDialogComponent } from './installation-events-dialog/installation-events-dialog.component';
import { RequestDatumDialogComponent } from './request-datum-dialog/request-datum-dialog.component';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { PaymentWizardDialogComponent } from './payment-wizard-dialog/payment-wizard-dialog.component';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    SelectMapCoordsDialogComponent,
    RequestDatumDialogComponent,
    MapComponent,
    InstallationEventsDialogComponent,
    ChangePasswordDialogComponent,
    PaymentWizardDialogComponent,
    LoginDialogComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [
    ConfirmDialogComponent,
    SelectMapCoordsDialogComponent,
    RequestDatumDialogComponent,
    InstallationEventsDialogComponent,
    ChangePasswordDialogComponent,
    PaymentWizardDialogComponent,
    LoginDialogComponent,
  ],
})
export class DialogsModule {}
