import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { UserFlagsEntity } from '@entities/user.entity';
import { BEUsersService } from '@services/backend-services/users/be-users.service';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { initializeClass } from '@utils/utils';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base.component';

export interface AgreeToTOSDialogInjectedData {
  language: string;
}

@Component({
  selector: 'app-agree-to-tos-dialog',
  templateUrl: './agree-to-tos-dialog.component.html',
  styleUrls: ['./agree-to-tos-dialog.component.scss'],
})
export class AgreeToTOSDialogComponent extends BaseComponent {
  public htmlData: SafeHtml;

  constructor(
    public dialog: MatDialogRef<AgreeToTOSDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AgreeToTOSDialogInjectedData,
    private toastr: ToastrService,
    private translationMWService: TranslationMWService,
    private beUsersService: BEUsersService,
    public localStorageService: LocalStorageService
  ) {
    super(localStorageService);
  }

  close(): void {
    this.dialog.close(false);
  }

  agree(): void {
    const flags = initializeClass(UserFlagsEntity, { agreeToTOS: true });
    this.beUsersService
      .saveFlags(flags)
      .pipe(first())
      .subscribe(() => {
        this.toastr.success(
          this.translationMWService.instant('agreeToTOS.accepted.toastr.message')
        );
      });
    this.localStorageService.userAgreesToTOS = true;
    this.dialog.close(true);
  }
}
