import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShowFullRegisterFormService {
  private formVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get formVisible(): Observable<boolean> {
    return this.formVisible$;
  }

  public showForm() {
    this.formVisible$.next(true);
  }

  public hideForm() {
    this.formVisible$.next(false);
  }
}
