<div class="d-flex justify-content-center">
  <div><span class="head-line-5">CTC Cloud</span></div>
</div>
<p class="font-body-1">
  <span class="font-subtitle-2">SUPPORT DE NUAGE CTC </span><br />
  Conform&eacute;ment au devoir d'information pr&eacute;vu &agrave; l'article 10 de la loi 34/2002,
  du 11 juillet, sur les services de la soci&eacute;t&eacute; de l'information et du commerce
  &eacute;lectronique, les donn&eacute;es suivantes sont prises en compte:
</p>

<span class="font-body-1">Entit&eacute; : Gescaser, S.A.</span><br />
<span class="font-body-1">C.A.F. : A25276635</span><br />
<span class="font-body-1">Adresse : Carrer Sant Pere 1, 25100 Almacelles (Lleida)</span><br />
<span class="font-body-1"
  >Inscrit au registre du commerce de Lleida, tome 81, folio 44, page L1450, 1&egrave;re
  inscription. </span
><br />
<span class="font-body-1">T&eacute;l&eacute;phone : 973740695</span><br />
<span class="font-body-1"
  >Courriel :<a href="mailto:gescaser@gescaser.com">gescaser@gescaser.com</a></span
><br />

<p class="font-body-1">
  <span class="font-subtitle-2">OBJET</span><br />
  Le Responsable du traitement met ce document &agrave; la disposition des utilisateurs afin de se
  conformer aux dispositions de la loi 34/2002, du 11 juillet, sur les services de la
  soci&eacute;t&eacute; de l'information et du commerce &eacute;lectronique et de la
  r&eacute;glementation en vigueur en mati&egrave;re de protection des donn&eacute;es, ainsi que
  pour informer tous les utilisateurs sur les conditions d'utilisation du syst&egrave;me CTC Cloud
  par le biais du support web et du application mobile (&laquo; web/app &raquo;).
</p>
<p class="font-body-1">
  Toute personne qui acc&egrave;de &agrave; cette application assume le r&ocirc;le d'utilisateur,
  s'engageant &agrave; observer et &agrave; respecter strictement les dispositions &eacute;tablies
  dans les pr&eacute;sentes, ainsi que toute autre disposition l&eacute;gale qui pourrait &ecirc;tre
  applicable.
</p>
<p class="font-body-1">
  Le Responsable du traitement se r&eacute;serve le droit de modifier tout type d'information
  pouvant appara&icirc;tre sur le site web/l'application sans qu'il y ait l'obligation de donner un
  pr&eacute;avis ou d'informer les utilisateurs de ces modifications, la publication sur le site
  web/l'application &eacute;tant consid&eacute;r&eacute;e comme suffisante.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">RESPONSABILIT&Eacute; </span><br />
  Le responsable du traitement est exon&eacute;r&eacute; de tout type de responsabilit&eacute;
  d&eacute;coulant des informations publi&eacute;es sur le site web/l'application, &agrave;
  condition que ces informations aient &eacute;t&eacute; manipul&eacute;es ou introduites par un
  tiers &eacute;tranger &agrave; celui-ci.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">POLITIQUE DE LIENS </span><br />
  &Agrave; partir du site Web/de l'application, il est possible qu'il soit redirig&eacute; vers du
  contenu de sites Web tiers. &Eacute;tant donn&eacute; que le responsable du traitement ne peut pas
  contr&ocirc;ler le contenu introduit par des tiers sur ses sites web/applications, il n'assume
  aucune responsabilit&eacute; pour ces contenus.
</p>
<p class="font-body-1">
  Toutefois, le Responsable de traitement s'engage &agrave; supprimer imm&eacute;diatement tout
  contenu susceptible de contrevenir &agrave; la l&eacute;gislation nationale ou internationale,
  &agrave; la morale ou &agrave; l'ordre public.
</p>
<p class="font-body-1">
  Le Responsable du traitement n'est pas responsable des informations et du contenu stock&eacute;s
  dans les forums, les chats, les g&eacute;n&eacute;rateurs de blogs, les commentaires, les
  r&eacute;seaux sociaux ou tout autre moyen permettant &agrave; des tiers de publier du contenu de
  mani&egrave;re ind&eacute;pendante sur le Web/le web/l'application. Dans le cas o&ugrave;
  l'utilisateur consid&egrave;re qu'il existe un contenu sur le site Web/l'application qui pourrait
  &ecirc;tre susceptible de cette classification, veuillez le notifier imm&eacute;diatement.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">PROTECTION DES DONN&Eacute;ES </span><br />
  Le Responsable de traitement s'engage &agrave; respecter la r&eacute;glementation en vigueur en
  mati&egrave;re de protection des donn&eacute;es, notamment le RGPD et la LOPDGDD. De m&ecirc;me,
  le responsable du traitement vous informe qu'il respecte la loi 34/2002 sur les services de la
  soci&eacute;t&eacute; de l'information et du commerce &eacute;lectronique et vous demandera
  &agrave; tout moment votre consentement pour le traitement de vos donn&eacute;es &agrave; des fins
  commerciales.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">PROPRI&Eacute;T&Eacute; INTELLECTUELLE ET INDUSTRIELLE </span><br />
  Le site web/l'application, y compris sa programmation, son &eacute;dition, sa compilation et
  d'autres &eacute;l&eacute;ments n&eacute;cessaires &agrave; son fonctionnement, les dessins, les
  logos, les textes et/ou les graphiques sont la propri&eacute;t&eacute; du Responsable du
  traitement ou b&eacute;n&eacute;ficient d'une licence ou d'une autorisation expresse de la part
  des auteurs. Tout le contenu est prot&eacute;g&eacute; par les r&eacute;glementations sur la
  propri&eacute;t&eacute; intellectuelle et industrielle.
</p>
<p class="font-body-1">
  Toute utilisation non autoris&eacute;e au pr&eacute;alable par le Responsable du traitement sera
  consid&eacute;r&eacute;e comme une violation grave des droits de propri&eacute;t&eacute;
  intellectuelle ou industrielle de l'auteur. Les dessins, logos, textes et/ou graphiques qui
  n'appartiennent pas au responsable du traitement et qui peuvent appara&icirc;tre sur le site
  web/l'application appartiennent &agrave; leurs propri&eacute;taires respectifs.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">LOI APPLICABLE ET JURIDICTION </span><br />
  La relation entre l'utilisateur et le responsable du traitement sera r&eacute;gie par la
  r&eacute;glementation en vigueur sur le territoire espagnol. En cas de litige, les parties se
  soumettent aux juges et tribunaux comp&eacute;tents conform&eacute;ment &agrave; la loi.
</p>
