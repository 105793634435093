import { Expose, Transform } from 'class-transformer';
import { BaseUUIDEntity } from './base.entity';
import { SensorConstantEntity, alarmLimits } from '@constants/sensor-constant.entity';
import { PreferenceTypesConstants } from '@constants/preference-types.constants';

export class PreferenceEntity extends BaseUUIDEntity {
  static NULL = 'NULL';

  @Expose()
  public section: string;

  @Expose()
  @Transform(({ value }) => Number(value), { toClassOnly: true })
  public type: number;

  public get typeName(): string {
    return new PreferenceTypesConstants().findById(this.type).name;
  }

  public get settingNTypeName(): string {
    return `${this.setting}_${this.typeName}`;
  }

  @Expose()
  public setting: string;

  @Expose()
  public value: string;

  @Expose()
  public minimum: number;

  @Expose()
  public maximum: number;

  public static buildSensorAlarmSetting(
    sensorTypeConst: SensorConstantEntity,
    limit: string
  ): string {
    return `ALARM_${limit.toUpperCase()}_${sensorTypeConst.name.toUpperCase()}`;
  }

  public static buildActiveControlName(sensorTypeConst: SensorConstantEntity): string {
    return `ALARM_ACTIVE_${sensorTypeConst.name.toUpperCase()}`;
  }

  public get activeControlName(): string {
    let settingName = this.setting;

    for (const limit of alarmLimits) {
      settingName = settingName.replace(limit, 'ACTIVE');
    }

    return settingName;
  }

  public get numberValuesArray(): number[] {
    return Number(this.type) === PreferenceTypesConstants.NUMBER_ARRAY.numberValue
      ? this.value.split(',').map((item) => Number(item))
      : [];
  }
}
