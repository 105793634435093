import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './pages/login/login.component';
import { authRoutes } from './auth.routes';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { RegisterModule } from '@modules/register/register.module';
import { ChangeLanguageModule } from '@shared/components/change-language/change-language.module';
import { AgreeToTOSDialogModule } from './agreetotos-dialog/agree-to-tos-dialog.module';
import { DataProvidersConstants } from '@constants/data-providers.constants';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ChangePasswordConfirmInvitationComponent } from './pages/action-id/change-password-confirm-invitation.component';

@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent, ChangePasswordConfirmInvitationComponent],
  imports: [
    RouterModule.forChild(authRoutes),
    CommonModule,
    SharedModule,
    CoreModule,
    RegisterModule,
    ChangeLanguageModule,
    AgreeToTOSDialogModule,
  ],
  providers: [DataProvidersConstants],
})
export class AuthModule {}
