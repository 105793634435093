import { Expose } from 'class-transformer';
import { BaseHistoricalEntity } from './base-historical.entity';
import { naiveRound } from '@utils/utils';

export class ProbeDebugReadingEntity extends BaseHistoricalEntity {
  @Expose()
  public frameCounter: number;

  @Expose()
  public timeCounter: number;

  @Expose()
  public ackCounter: number;

  @Expose()
  public rssi: number;

  @Expose()
  public batteryLevel: number;

  @Expose()
  public batteryCapacity: number;

  @Expose()
  public batteryLowVoltage: boolean;

  public get batteryLowVoltageAsString(): string {
    return String(this.batteryLowVoltage);
  }

  public get batteryLevelPercentage(): number {
    return this.batteryLowVoltage
      ? 0
      : this.batteryLevel !== undefined && this.batteryLevel > this.batteryCapacity
      ? 0
      : this.batteryCapacity
      ? naiveRound(((this.batteryCapacity - (this.batteryLevel || 0)) * 100) / this.batteryCapacity)
      : undefined;
  }
}
