<div class="auth-layout">
  <div class="column column-left" [class.isRegister]="isRegisterCompany && isRegister">
    <div class="container">
      <div class="logo-container">
        <img class="logo" src="/assets/images/ctc_cloud@4x.png" />
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="column column-right" [class.isRegister]="isRegisterCompany && isRegister">
    <app-change-language
      [iconColorClass]="'c-text'"
      class="change-language-auth"
      [darkTheme]="false"
    ></app-change-language>
    <div class="text-background m3-display-large">
      {{ 'auth.login.bigTitle' | translate }}
    </div>
    <img class="background" src="assets/images/auth-silos-background.jpg" />
  </div>
</div>
