import { Expose } from 'class-transformer';
import { BaseEntity } from './base.entity';
import { formattedDateTime } from '@utils/utils';
import * as moment from 'moment';

export class InstallationEventEntity extends BaseEntity {
  @Expose()
  public readingTime: string;

  public localizedReadingTime(language: string): string {
    return this.readingTime ? formattedDateTime(language, moment(this.readingTime).toDate()) : '';
  }

  @Expose()
  public eventType: number;

  @Expose()
  public siloName: string;

  @Expose()
  public probeName: string;

  @Expose()
  public nodeName: string;

  @Expose()
  public condition: string;

  @Expose()
  public threshold: string;

  @Expose()
  public value: string;
}
