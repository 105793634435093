import { Expose } from 'class-transformer';
import { Base64Sha256dEntity } from './base.entity';

export class InstallationFileEntity extends Base64Sha256dEntity {
  @Expose()
  public installationId: string;

  @Expose()
  public fileType: number;
}
