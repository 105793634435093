import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface RequestDatumDialogInjectedData {
  title: string;
  inputLabel: string;
  confirmBtnLabel: string;
  isAPassword?: boolean;
}

export interface RequestDatumDialogReturnData {
  answer: boolean;
  outputValue: string;
}

@Component({
  selector: 'app-request-datum-dialog',
  templateUrl: './request-datum-dialog.component.html',
  styleUrls: ['./request-datum-dialog.component.scss'],
})
export class RequestDatumDialogComponent {
  private returnData: RequestDatumDialogReturnData = {
    answer: false,
    outputValue: '',
  };

  public datumForm = new FormGroup({
    datum: new FormControl('', [Validators.required]),
  });

  constructor(
    public dialog: MatDialogRef<RequestDatumDialogComponent, RequestDatumDialogReturnData>,
    @Inject(MAT_DIALOG_DATA) public data: RequestDatumDialogInjectedData
  ) {
    this.datumForm.controls.datum.valueChanges.subscribe((value) => {
      this.returnData.outputValue = value;
    });
  }

  close(answer: boolean = false): void {
    this.returnData.answer = answer;
    this.dialog.close(this.returnData);
  }
}
