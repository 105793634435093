import { Component } from '@angular/core';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { Input } from '@angular/core';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { Languages } from '@config/app.languages';
import { BEProfileService } from '@services/backend-services/profile/be-profile.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss'],
})
export class ChangeLanguageComponent {
  public languages = Languages.languages;
  public iterableLanguages = Languages.IterableLanguages;

  @Input() iconColorClass = 'c-primary';
  @Input() darkTheme = false;

  constructor(
    private toastr: ToastrService,
    private translationMWService: TranslationMWService,
    private localStorageService: LocalStorageService,
    private beProfileService: BEProfileService
  ) {}

  setTransLanguage(langId: string) {
    this.translationMWService.setTransLanguage(langId);
    if (this.localStorageService.userIsAuthenticated) {
      this.beProfileService.profile.pipe(first()).subscribe((profile) => {
        if (profile && profile.language !== langId) {
          profile.language = langId;
          this.beProfileService.saveLanguage(profile).subscribe(() => {
            this.toastr.success(
              this.translationMWService.instant('profile.language.saved.toastr.success.message')
            );
            this.beProfileService.getProfile();
            this.beProfileService.lastSavedNext();
          });
        }
      });
    }
  }

  get getLanguageName(): string {
    if (!Object.keys(this.languages).includes(this.localStorageService.currentLanguage)) {
      this.setTransLanguage('en');
    }

    return this.languages[this.localStorageService.currentLanguage];
  }
}
