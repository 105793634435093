<div class="d-flex justify-content-center">
  <div><span class="head-line-5">CTC Wolke</span></div>
</div>
<p class="font-body-1">
  <span class="font-subtitle-2">CTC Cloud HALTERUNG </span><br />
  In &Uuml;bereinstimmung mit der Informationspflicht gem&auml;&szlig; Artikel 10 des Gesetzes
  34/2002 vom 11. Juli &uuml;ber die Dienste der Informationsgesellschaft und den elektronischen
  Gesch&auml;ftsverkehr werden die folgenden Daten ber&uuml;cksichtigt:
</p>

<span class="font-body-1">Unternehmen: Gescaser, S.A.</span><br />
<span class="font-body-1">C.I.F.: A25276635</span><br />
<span class="font-body-1">Adresse: Carrer Sant Pere 1, 25100 Almacelles (Lleida)</span><br />
<span class="font-body-1"
  >Eingetragen im Handelsregister von Lleida, Band 81, Folio 44, Seite L1450, 1. Eintragung. </span
><br />
<span class="font-body-1">Telefon: 973740695</span><br />
<span class="font-body-1"
  >E-Mail:<a href="mailto:gescaser@gescaser.com">gescaser@gescaser.com</a></span
><br />

<p class="font-body-1">
  <span class="font-subtitle-2">OBJEKT</span><br />
  Der f&uuml;r die Datenverarbeitung Verantwortliche stellt den Nutzern dieses Dokument zur
  Verf&uuml;gung, um die Bestimmungen des Gesetzes 34/2002 vom 11. Juli &uuml;ber die Dienste der
  Informationsgesellschaft und den elektronischen Gesch&auml;ftsverkehr und die geltenden
  Datenschutzbestimmungen einzuhalten und alle Benutzer &uuml;ber die Nutzungsbedingungen des CTC
  Cloud-Systems &uuml;ber den Web-Support und die zu informieren mobile Anwendung
  (&quot;Web/App&quot;).
</p>
<p class="font-body-1">
  Jede Person, die auf diese Anwendung zugreift, &uuml;bernimmt die Rolle des Benutzers und
  verpflichtet sich zur Einhaltung und strikten Einhaltung der hierin festgelegten Bestimmungen
  sowie aller anderen anwendbaren gesetzlichen Bestimmungen.
</p>
<p class="font-body-1">
  Der f&uuml;r die Datenverarbeitung Verantwortliche beh&auml;lt sich das Recht vor, jede Art von
  Informationen, die auf der Website/App erscheinen, zu &auml;ndern, ohne dass eine Verpflichtung
  zur vorherigen Ank&uuml;ndigung oder Information der Benutzer &uuml;ber solche &Auml;nderungen
  besteht, wobei die Ver&ouml;ffentlichung auf der Website/App als ausreichend angesehen wird.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">VERANTWORTUNG </span><br />
  Der f&uuml;r die Verarbeitung Verantwortliche ist von jeglicher Haftung befreit, die sich aus den
  auf der Website/App ver&ouml;ffentlichten Informationen ergibt, sofern diese Informationen von
  einem Dritten, der nicht mit ihm in Verbindung steht, manipuliert oder eingef&uuml;hrt wurden.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">LINK-RICHTLINIE </span><br />
  Von der Website/App aus ist es m&ouml;glich, dass sie auf Inhalte von Websites Dritter umgeleitet
  wird. Da der f&uuml;r die Verarbeitung Verantwortliche die von Dritten auf seinen Websites/Apps
  eingestellten Inhalte nicht kontrollieren kann, &uuml;bernimmt er keine Haftung f&uuml;r diese
  Inhalte.
</p>
<p class="font-body-1">
  Der f&uuml;r die Verarbeitung Verantwortliche verpflichtet sich jedoch, alle Inhalte, die gegen
  nationales oder internationales Recht, die Moral oder die &ouml;ffentliche Ordnung versto&szlig;en
  k&ouml;nnten, unverz&uuml;glich zu entfernen.
</p>
<p class="font-body-1">
  Der f&uuml;r die Verarbeitung Verantwortliche ist nicht verantwortlich f&uuml;r die Informationen
  und Inhalte, die in Foren, Chats, Blog-Generatoren, Kommentaren, sozialen Netzwerken oder anderen
  Mitteln gespeichert sind, die es Dritten erm&ouml;glichen, Inhalte unabh&auml;ngig im Web/Web/App
  zu ver&ouml;ffentlichen. F&uuml;r den Fall, dass der Nutzer der Ansicht ist, dass es auf der
  Website/App Inhalte gibt, die f&uuml;r diese Einstufung anf&auml;llig sein k&ouml;nnten, bitten
  wir um unverz&uuml;gliche Mitteilung.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">DATENSCHUTZ </span><br />
  Der f&uuml;r die Verarbeitung Verantwortliche verpflichtet sich zur Einhaltung der geltenden
  Datenschutzbestimmungen, einschlie&szlig;lich der DSGVO und der LOPDGDD. Ebenso informiert der
  f&uuml;r die Datenverarbeitung Verantwortliche dar&uuml;ber, dass er das Gesetz 34/2002 &uuml;ber
  die Dienste der Informationsgesellschaft und den elektronischen Gesch&auml;ftsverkehr einh&auml;lt
  und jederzeit Ihre Zustimmung zur Verarbeitung Ihrer Daten zu kommerziellen Zwecken einholen wird.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">GEISTIGES UND GEWERBLICHES EIGENTUM </span><br />
  Die Website/App, einschlie&szlig;lich ihrer Programmierung, Bearbeitung, Zusammenstellung und
  anderer Elemente, die f&uuml;r ihren Betrieb erforderlich sind, die Designs, Logos, Texte und/oder
  Grafiken sind Eigentum des f&uuml;r die Verarbeitung Verantwortlichen oder verf&uuml;gen &uuml;ber
  eine Lizenz oder eine ausdr&uuml;ckliche Genehmigung der Autoren. Alle Inhalte sind durch die
  Vorschriften &uuml;ber geistiges und gewerbliches Eigentum gesch&uuml;tzt.
</p>
<p class="font-body-1">
  Jede Nutzung, die nicht zuvor vom f&uuml;r die Verarbeitung Verantwortlichen genehmigt wurde, wird
  als schwerwiegende Verletzung der geistigen oder gewerblichen Eigentumsrechte des Autors
  angesehen. Die Designs, Logos, Texte und/oder Grafiken, die nicht Eigentum des f&uuml;r die
  Verarbeitung Verantwortlichen sind und die auf der Website/App erscheinen k&ouml;nnen,
  geh&ouml;ren ihren jeweiligen Eigent&uuml;mern.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">ANWENDBARES RECHT UND GERICHTSSTAND </span><br />
  Die Beziehung zwischen dem Nutzer und dem f&uuml;r die Verarbeitung Verantwortlichen unterliegt
  den auf spanischem Gebiet geltenden Vorschriften. Im Falle von Streitigkeiten unterwerfen sich die
  Parteien den entsprechenden Richtern und Gerichten in &Uuml;bereinstimmung mit dem Gesetz.
</p>
