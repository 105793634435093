import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AuthModule } from '@modules/auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from '@core/core.module';
import { ViewConfig } from '@config/view.config';
import { HttpErrorInterceptor } from '@interceptors/http-error.interceptor';
import { MockInterceptor } from '@interceptors/mock.interceptor';
import { AuthInterceptor } from '@interceptors/auth.interceptor';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppTranslate } from '@config/multi-translate-http-loader';
import { LocalStorageService } from '@services/localStorage/local-storage.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    // routes
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    BrowserModule,
    AuthModule,
    SharedModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(ViewConfig.TOASTR_CONFIG),
    CoreModule,
    TranslateModule.forRoot(AppTranslate.loader()),
  ],
  providers: [
    {
      provide: Window,
      useValue: window,
    },
    // interceptors order is important!!
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    environment.mock
      ? {
          provide: HTTP_INTERCEPTORS,
          useClass: MockInterceptor,
          multi: true,
        }
      : [],
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private translate: TranslateService,
    private localStorageService: LocalStorageService
  ) {
    this.translate.setDefaultLang(
      this.localStorageService.currentLanguage ||
        this.localStorageService.userLanguage ||
        ViewConfig.DEFAULT_LANG
    );
  }
}
