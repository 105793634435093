import { Expose } from 'class-transformer';
import { Base64Sha256dEntity } from './base.entity';

export class InstallationImageEntity extends Base64Sha256dEntity {
  @Expose()
  public installationId: string;

  @Expose()
  public isCanvasImage: boolean;

  @Expose()
  public fileType: string;

  public get src() {
    return this.stringBase64Data && this.stringBase64Data.length
      ? 'data:' + this.fileType + ';base64,' + this.stringBase64Data
      : '';
  }
}
