import { Injectable } from '@angular/core';
import { Router, RouterEvent, Event, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RouteInfoEntity } from './route-info.entity';
import { initializeClass } from '@utils/utils';

@Injectable({ providedIn: 'root' })
export class NavigationRoutesService {
  private routeInfo$: BehaviorSubject<RouteInfoEntity> = new BehaviorSubject<RouteInfoEntity>(
    RouteInfoEntity.INITIAL
  );

  private isInstallationUrl$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public routeInfo = this.routeInfo$.asObservable();
  public isInstallationUrl = this.isInstallationUrl$.asObservable();

  constructor(private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.events
      .pipe(filter((e: Event): e is RouterEvent => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.updateRouteInfo(e.url);
      });
  }

  private updateRouteInfo(suffix: string): void {
    const uuids = /((\w{4,12}-?)){5}/.exec(suffix);
    const uuidParam = uuids && uuids.length ? uuids[0] : '';
    this.updateIsInstallationUrl(suffix);

    const fixedSuffix = suffix
      .toLowerCase()
      .replace(/\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/, '')
      .replace(/\//, '')
      .replace(/_/, '-')
      .replace(/\//g, '-');

    this.routeInfo$.next(
      initializeClass(RouteInfoEntity, { translationLabel: fixedSuffix, uuidParam })
    );
  }

  private updateIsInstallationUrl(suffix: string): void {
    this.isInstallationUrl$.next(
      /installation\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/.test(suffix)
    );
  }
}
