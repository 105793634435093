import { AngularMaterialModule } from './../angular-material.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BoxModule } from '@shared/box/box.module';

import { InlineLoadingSpinnerComponent } from './inline-loading-spinner/inline-loading-spinner.component';
import { TextFilterComponent } from './text-filter/text-filter.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    // Angular Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    BoxModule,
    TranslateModule,
  ],
  declarations: [InlineLoadingSpinnerComponent, TextFilterComponent],
  exports: [InlineLoadingSpinnerComponent, TextFilterComponent],
})
export class InputElementsModule {}
