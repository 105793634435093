import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';

/**
 * Module contains various common box elements including: dialog, dropdown, scrollbox,
 * titlebox and loading components used within these.
 */
@NgModule({
  imports: [
    // Angular
    CommonModule,
    FormsModule,
  ],
  declarations: [
    LoadingOverlayComponent,
    LoadingSpinnerComponent
  ],
  exports: [
    LoadingOverlayComponent,
    LoadingSpinnerComponent
  ]
})
export class BoxModule {}
