import { Expose, Type } from 'class-transformer';
import { BaseUUIDEntity } from './base.entity';
import { SensorEntity } from './sensor.entity';

export class NodeEntity extends BaseUUIDEntity {
  @Expose()
  public position: number;

  @Expose()
  public active: boolean;

  @Expose()
  public connectionCodeMultiplexer: string;

  @Expose()
  public connectionPointInMultiplexer: string;

  @Expose()
  public level: string;

  @Expose()
  public codeH: string;

  @Expose()
  public codeL: string;

  @Expose()
  public coOffsetValue: number;

  @Expose()
  public csMultiplier: number;

  @Expose()
  @Type(() => SensorEntity)
  public sensors: SensorEntity[];
}
