import { CecabankEntity } from '@entities/cecabank.entity';
import { environment } from 'environments/environment';
import { getCecabankCoin } from './cecabank.coins';

export class AppConfig {
  // remote BE modules' URL
  public static get BACKEND_BASE_URL(): string {
    return environment.backendBaseURL;
  }
  // default public page
  // public static get DEFAULT_MAIN_PAGE(): string {
  //   return '/site';
  // }

  public static get LeafletMapAPIKEY(): string {
    return 'AAPKc23f4823f8204048b101f2a1fa6631b0jACO8QDQVxYRbo4cbg5j_Nq9kTU3ZaMinQgOqiEMSdTkmsbYTFgkD5Vzvg8Ujv0b';
  }
  // SUPER-ADMINs' company's UUID
  public static get SuperAdminsCompanyUUID(): string {
    return '25847f6b-5a63-4def-a04b-54eeb04c3924';
  }
  // DEMO users' company's UUID
  public static get DemoUsersCompanyUUID(): string {
    return '8dd829f0-2f44-419c-ac3c-fa1181a7b68c';
  }

  public static get SuperAdminUserUUID(): string {
    return '9b4eaff1-5ae8-4584-bae5-84ae4342cf05';
  }

  public static get DemoAdminUserUUID(): string {
    return 'c7a098d5-1749-40c1-834b-e4ce1355df86';
  }
  // remote POS URL
  public static get POS_URL(): string {
    return environment.posApiURL;
  }
  // remote POS Encryption Key
  public static get POS_ENCRYPTION_KEY(): string {
    return environment.posEncryptionKey;
  }
  // remote POS common IDs & values
  public static get CecabankConfig(): Partial<CecabankEntity> {
    /* eslint-disable @typescript-eslint/naming-convention  */
    return {
      MerchantID: '086641677',
      AcquirerBIN: '0000554027',
      TerminalID: '00000003',
      TipoMoneda: getCecabankCoin('EUR'),
      Exponente: '2',
      Cifrado: 'SHA2',
      Pago_soportado: 'SSL',
      URL_OK: `${window.location.origin}/installations/of-company`,
      URL_NOK: `${window.location.origin}/installations/of-company`,
    };
    /* eslint-enable @typescript-eslint/naming-convention  */
  }
}
