<button mat-button [matMenuTriggerFor]="langMenu" [ngClass]="iconColorClass">
  {{ getLanguageName }}
  <span class="material-symbols-outlined" [ngClass]="iconColorClass"> expand_more </span>
</button>
<mat-menu #langMenu="matMenu" [class]="darkTheme ? 'dark-theme' : 'light-theme'">
  <button
    *ngFor="let language of iterableLanguages"
    mat-menu-item
    (click)="setTransLanguage(language.iso2)"
  >
    {{ language.name }}
  </button>
</mat-menu>
