import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ProfileEntity } from '@entities/profile.entity';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { BackendService } from '@services/api/backend/backend.service';

@Injectable({
  providedIn: 'root',
})
export class BEProfileService {
  private profile$: BehaviorSubject<ProfileEntity> = new BehaviorSubject(undefined);
  private lastSaved$ = new Subject();

  constructor(private be: BackendService, private localStorageService: LocalStorageService) {}

  public lastSaved(): Observable<any> {
    return this.lastSaved$.asObservable();
  }

  public lastSavedNext() {
    this.lastSaved$.next();
  }

  public get profile(): Observable<ProfileEntity> {
    if (!this.profile$.value || this.localStorageService.userId !== this.profile$.value.id) {
      this.getProfile();
    }
    return this.profile$;
  }

  public getProfile(): void {
    this.be.get('/profile', ProfileEntity).subscribe((profile) => {
      this.profile$.next(profile);
    });
  }

  public save(profile: ProfileEntity): Observable<ProfileEntity> {
    return this.be.post('/profile', profile, ProfileEntity).pipe(share());
  }

  public saveLanguage(profile: ProfileEntity): Observable<ProfileEntity> {
    return this.be.post('/profile/language', profile, ProfileEntity).pipe(share());
  }
}
