import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ShowFullRegisterFormService } from '@modules/register/show-full-register-form.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
  isRegister = false;
  isRegisterCompany = false;
  subscriptions: Subscription[];

  constructor(
    private router: Router,
    private showFullRegisterFormService: ShowFullRegisterFormService,
    private cdRef: ChangeDetectorRef
  ) {}

  private get isARegisterPath(): boolean {
    return this.router.url.startsWith('/auth/register');
  }

  ngOnInit(): void {
    this.isRegister = this.isARegisterPath;
    this.subscriptions = [
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // Navigation has ended successfully
          this.isRegister = this.isARegisterPath;
        }
      }),
      this.showFullRegisterFormService.formVisible.subscribe((e) => {
        this.isRegisterCompany = e ? true : false;
        this.isRegister = true;
      }),
    ];
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((e) => e.unsubscribe());
  }
}
