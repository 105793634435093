import { Expose, Transform, Type } from 'class-transformer';
import { BaseUUIDEntity } from './base.entity';
import { UserEntity } from './user.entity';
import { utcDate, utcNow } from '@utils/utils';
import {
  RegisterLiteActionTokenDataEntity,
  RegisterPlusActionTokenDataEntity,
} from './action-token-data.entity';

export class ActionTokenEntity extends BaseUUIDEntity {
  @Expose()
  public expiresAt: string;

  public get hasExpired(): boolean {
    return utcDate(this.expiresAt) < utcNow();
  }

  @Expose()
  public data: string;

  @Expose()
  @Type(() => RegisterPlusActionTokenDataEntity)
  public registerPlusData: RegisterPlusActionTokenDataEntity;

  @Expose()
  @Type(() => RegisterLiteActionTokenDataEntity)
  public registerLiteData: RegisterLiteActionTokenDataEntity;

  @Expose()
  @Transform(({ value }) => Number(value), { toClassOnly: true })
  public kind: number;

  @Expose()
  public language: string;

  @Expose()
  @Type(() => UserEntity)
  public user: UserEntity;
}
