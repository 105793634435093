import { Observable, BehaviorSubject } from 'rxjs';
import { map, first, share } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { UserListEntity } from '@entities/user-list.entity';
import { UserControlsEntity, UserEntity, UserFlagsEntity } from '@entities/user.entity';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { BackendService } from '@services/api/backend/backend.service';
import { BaseDeleteResponse } from '@entities/base-delete-response.entity';
import { IUsersExportRequestContext } from '@interfaces/all-users-export-request-context.interface';

@Injectable({
  providedIn: 'root',
})
export class BEUsersService {
  constructor(private be: BackendService, private localStorageService: LocalStorageService) {}

  public list(companyId: string): Observable<UserEntity[]> {
    let endpointPath = '/users';
    if (this.localStorageService.userIsSuperAdmin && companyId) {
      endpointPath += `/${companyId}`;
    }
    return this.be.get(endpointPath, UserListEntity).pipe(
      first(),
      map((response) => response.users)
    );
  }

  public listAll(): Observable<UserEntity[]> {
    return this.be.get('/all/users', UserListEntity).pipe(
      first(),
      map((response) => response.users)
    );
  }

  public export(payload: IUsersExportRequestContext): Observable<UserEntity[]> {
    return this.be.post('/all/users/export', payload, UserListEntity).pipe(
      first(),
      map((response) => response.users)
    );
  }

  public saveControls(controls: UserControlsEntity): Observable<UserEntity> {
    return this.be.post(`/user/controls`, controls, UserEntity).pipe(share());
  }

  public saveFlags(flags: UserFlagsEntity): Observable<UserEntity> {
    return this.be.post(`/user/flags`, flags, UserEntity).pipe(share());
  }

  public saveExpirationAt(user: UserEntity): Observable<UserEntity> {
    return this.be.post(`/user/${user.id}`, user, UserEntity).pipe(share());
  }

  public delete(userId: string): Observable<BaseDeleteResponse> {
    return this.be.delete(`/user/${userId}`, BaseDeleteResponse).pipe(share());
  }
}
