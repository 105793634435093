import { NgModule } from '@angular/core';
import { AngularMaterialModule } from '@shared/angular-material.module';
import { CommonModule } from '@angular/common';
import { ChangeLanguageComponent } from './change-language.component';

@NgModule({
  declarations: [ChangeLanguageComponent],
  imports: [CommonModule, AngularMaterialModule],
  exports: [ChangeLanguageComponent],
})
export class ChangeLanguageModule {}
