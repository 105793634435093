import {
  Component,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConstantEntity } from '@constants/base.constants';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { CountryConstantEntity } from '@constants/country-constant.entity';
import { CountriesConstants } from '@constants/countries.constants';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss'],
})
export class SelectCountryComponent implements OnInit, OnChanges, OnDestroy {
  public filteredOptions: Observable<ConstantEntity[]>;

  private countries: CountryConstantEntity[] = [...new CountriesConstants().list()].sort((a, b) =>
    new Intl.Collator(this.translateService.currentLang).compare(
      this.translationMWService.instant(a.translationPath),
      this.translationMWService.instant(b.translationPath)
    )
  );
  private subscriptions: Subscription[];
  private destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() clearButton = false;
  @Input() hideLabel = false;
  @Input() countryFormControl = new FormControl('', []);
  @Input() controlLabel = 'country';

  @Output() countryCodeSelected: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private translationMWService: TranslationMWService,
    private translateService: TranslateService
  ) {
    this.linkFilteredOptions();
  }

  ngOnInit() {
    this.subscriptions = [
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.countries.sort((a, b) =>
          new Intl.Collator(event.lang).compare(
            this.translationMWService.instant(a.translationPath),
            this.translationMWService.instant(b.translationPath)
          )
        );
      }),
    ];
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.subscriptions.forEach((el) => {
      el.unsubscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.countryFormControl && changes.countryFormControl.currentValue) {
      this.linkFilteredOptions();
    }
  }

  private linkFilteredOptions() {
    this.filteredOptions = this.countryFormControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value))
    );
  }

  private filter(entry: any): ConstantEntity[] {
    const filterValue =
      entry instanceof ConstantEntity ? entry.name.toLowerCase() : entry.toLowerCase();

    return !entry
      ? this.countries
      : this.countries.filter((constant) =>
          this.translationMWService
            .instant(constant.translationPath)
            .toLowerCase()
            .includes(filterValue)
        );
  }

  displayFn = (country: ConstantEntity): string =>
    country && country.translationPath
      ? this.translationMWService.instant(country.translationPath)
      : '';

  optionSelected() {
    const countryCode =
      this.countryFormControl.value instanceof ConstantEntity
        ? this.countryFormControl.value.name
        : undefined;
    this.countryCodeSelected.emit(countryCode);
  }

  clearFilter() {
    this.countryFormControl.setValue('');
    this.countryCodeSelected.emit(undefined);
  }
}
