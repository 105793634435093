import { Expose, Transform, Type } from 'class-transformer';
import { BaseUUIDEntity } from './base.entity';
import { ProfileEntity } from './profile.entity';
import { CompanyEntity } from './company.entity';
import { formattedDateTime, offsetDate } from '@utils/utils';
import { UserRolesConstants } from '@constants/user-roles.constants';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';

export class UserFlagsEntity extends BaseUUIDEntity {
  @Expose()
  public agreeToTOS: boolean;
}

export class UserControlsEntity extends UserFlagsEntity {
  @Expose()
  public disabled: boolean;

  public get disabledState(): boolean {
    return this.disabled;
  }

  public get disabledText(): string {
    return this.disabled ? 'disabled' : 'enabled';
  }
}

export class UserEntity extends UserControlsEntity {
  @Expose()
  public username: string;

  @Expose()
  public email: string;

  @Expose()
  @Transform(({ value }) => Number(value), { toClassOnly: true })
  public role: number;

  public translatedRole(translationMWService: TranslationMWService): string {
    return this.translatedConstant(
      translationMWService,
      'base.constants.userRole.',
      new UserRolesConstants().findById(this.role).name
    );
  }

  @Expose()
  public lastLogin: string;

  @Expose()
  public expiresAt: string;

  public localizedLastLogin(language: string): string {
    return this.lastLogin ? formattedDateTime(language, offsetDate(this.lastLogin)) : '';
  }

  @Expose()
  @Type(() => ProfileEntity)
  public profile: ProfileEntity;

  @Expose()
  @Type(() => CompanyEntity)
  public company: CompanyEntity;

  public get companyName(): string {
    return this.company.name;
  }

  public get fullName(): string {
    return ProfileEntity.getFullName(this.profile);
  }
}
