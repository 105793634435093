/* eslint-disable @typescript-eslint/naming-convention */
export class ViewConfig {
  public static TOASTR_CONFIG = {
    timeOut: 5000,
    positionClass: 'toast-top-right',
    preventDuplicates: false,
    progressBar: true,
  };
  public static SCROLLBAR_STYLE = {
    '--scrollbar-size': '12px',
    '--scrollbar-thumb-hover-color': '#00000084'
  };

  public static DEFAULT_LANG = 'en';

  public static TIMEZONE_OFFSET = new Date().getTimezoneOffset();
}
