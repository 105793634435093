import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { BackendService } from '@services/api/backend/backend.service';
import { EncryptedCodeResponse } from '@entities/encrypted-code-response.entity';
import { DecryptedCodeResponse } from '@entities/decrypted-code-response.entity';

@Injectable({
  providedIn: 'root',
})
export class BEDesktopService {
  constructor(private be: BackendService) {}

  public getDecryptedCode(encryptedCode: string): Observable<DecryptedCodeResponse> {
    return this.be.get(`/desktop/decrypted/${encryptedCode}`, DecryptedCodeResponse).pipe(share());
  }

  public getEncryptedCode(decryptedCode: string): Observable<EncryptedCodeResponse> {
    return this.be.get(`/desktop/encrypted/${decryptedCode}`, EncryptedCodeResponse).pipe(share());
  }
}
