import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export type MixedDataProviders = {
  mixed: boolean;
  currentDataProvider: number;
};

@Injectable({
  providedIn: 'root',
})
export class MixedDataProvidersNavService {
  private mixedDataProviders$: BehaviorSubject<MixedDataProviders> =
    new BehaviorSubject<MixedDataProviders>({
      mixed: undefined,
      currentDataProvider: undefined,
    });

  public get mixedDataProviders(): Observable<MixedDataProviders> {
    return this.mixedDataProviders$;
  }

  public mixedDataProvidersNext(mixedDataProviders: MixedDataProviders) {
    this.mixedDataProviders$.next(mixedDataProviders);
  }
}
