import { initializeClass } from '@utils/utils';
import { Expose } from 'class-transformer';

export class RouteInfoEntity {
  @Expose()
  public uuidParam: string;

  @Expose()
  public translationLabel: string;

  public get translationPath(): string {
    return `route-title.${this.translationLabel}`;
  }

  public static get INITIAL(): RouteInfoEntity {
    return initializeClass(RouteInfoEntity, { translationLabel: 'site' });
  }
}
