import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActionTokenEntity } from '@entities/action-token.entity';
import { IActionTokenRequestContext } from '@interfaces/action-token-request-context.interface';
import { IActionTokenChangePasswordRequestContext } from '@interfaces/action-token-change-password-context.interface';
import { ActionTokenRequestResponse } from '@entities/action-token-request-response.entity';
import { BackendService } from '@services/api/backend/backend.service';
import { IActionTokenRegisterLiteInstallationRequestContext } from '@interfaces/action-token-register-lite-installation-context.interface';

@Injectable({
  providedIn: 'root',
})
export class BEActionTokensService {
  constructor(private be: BackendService) {}

  public get(id: string): Observable<ActionTokenEntity> {
    return this.be.get(`/action_token/${id}`, ActionTokenEntity).pipe(share());
  }

  public process(
    id: string,
    actionTokenContext?: IActionTokenChangePasswordRequestContext
  ): Observable<ActionTokenEntity> {
    return this.be
      .post(`/action_token/${id}`, actionTokenContext ? actionTokenContext : {}, ActionTokenEntity)
      .pipe(share());
  }

  public request(
    actionTokenContext: IActionTokenRequestContext
  ): Observable<ActionTokenRequestResponse> {
    return this.be
      .put('/action_token', actionTokenContext, ActionTokenRequestResponse)
      .pipe(share());
  }

  public registerLiteToken(
    actionTokenContext: IActionTokenRegisterLiteInstallationRequestContext
  ): Observable<ActionTokenRequestResponse> {
    return this.be
      .put('/register/lite/token', actionTokenContext, ActionTokenRequestResponse)
      .pipe(share());
  }
}
