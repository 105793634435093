import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/localStorage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeeGuard implements CanActivate {
  constructor(private localStorageService: LocalStorageService, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.localStorageService.userIsEmployee) {
      return true;
    }

    // navigate to site page
    void this.router.navigate([this.localStorageService.userRolesMainPage]);
    // you can save redirect url so after authenticating we can move them back to the page they requested
    return false;
  }
}
