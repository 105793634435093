<div class="d-flex justify-content-center">
  <div><span class="head-line-5">CTC Cloud</span></div>
</div>
<p class="font-body-1">
  <span class="font-subtitle-2">CTC Cloud HOLDER </span><br />
  In compliance with the duty of information set out in Article 10 of Law 34/2002, of 11 July, on
  Information Society Services and Electronic Commerce, the following data are reflected:
</p>

<span class="font-body-1">Entity: Gescaser, S.A.</span><br />
<span class="font-body-1">C.I.F.: A25276635</span><br />
<span class="font-body-1">Address: Carrer Sant Pere 1, 25100 Almacelles (Lleida)</span><br />
<span class="font-body-1"
  >Registered in the Mercantile Registry of Lleida, Volume 81, Folio 44, Page L1450, 1st
  Inscription. </span
><br />
<span class="font-body-1">Phone: 973740695</span><br />
<span class="font-body-1"
  >Email:<a href="mailto:gescaser@gescaser.com">gescaser@gescaser.com</a></span
><br />

<p class="font-body-1">
  <span class="font-subtitle-2">OBJECT</span><br />
  The Data Controller makes this document available to users in order to comply with the provisions
  of Law 34/2002, of 11 July, on Information Society Services and Electronic Commerce and the
  regulations in force on Data Protection, as well as to inform all users regarding the conditions
  of use of the CTC Cloud system through the web support and the mobile application
  (&quot;web/app&quot;).
</p>
<p class="font-body-1">
  Any person who accesses this application assumes the role of user, committing to the observance
  and strict compliance with the provisions established herein, as well as any other legal provision
  that may be applicable.
</p>
<p class="font-body-1">
  The Data Controller reserves the right to modify any type of information that may appear on the
  website/app without there being an obligation to give prior notice or inform users of such
  modifications, with publication on the website/app being understood as sufficient.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">RESPONSIBILITY </span><br />
  The Data Controller is exempt from any type of liability arising from the information published on
  the website/app provided that such information has been manipulated or introduced by a third party
  unrelated to it.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">LINKS POLICY </span><br />
  From the website/app it is possible that it is redirected to content from third-party websites.
  Since the Data Controller cannot control the content introduced by third parties on its
  websites/app, it does not assume any liability for such content.
</p>
<p class="font-body-1">
  However, the Data Controller undertakes to immediately remove any content that may contravene
  national or international law, morality or public order.
</p>
<p class="font-body-1">
  The Controller is not responsible for the information and content stored in forums, chats, blog
  generators, comments, social networks or any other means that allow third parties to publish
  content independently on the Web/web/app. In the event that the user considers that there is any
  content on the website/app that may be susceptible to this classification, please notify it
  immediately.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">DATA PROTECTION </span><br />
  The Data Controller is committed to complying with current data protection regulations, including
  the GDPR and the LOPDGDD. Likewise, the Data Controller informs that it complies with Law 34/2002
  on Information Society Services and Electronic Commerce and will request your consent for the
  processing of your data for commercial purposes at all times.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">INTELLECTUAL AND INDUSTRIAL PROPERTY </span><br />
  The website/app, including its programming, editing, compilation and other elements necessary for
  its operation, the designs, logos, texts and/or graphics are the property of the Controller or
  have a licence or express authorisation from the authors. All content is protected by intellectual
  and industrial property regulations.
</p>
<p class="font-body-1">
  Any use not previously authorised by the Controller will be considered a serious breach of the
  author's intellectual or industrial property rights. The designs, logos, texts and/or graphics
  that are not owned by the Data Controller and that may appear on the website/app belong to their
  respective owners.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">APPLICABLE LAW AND JURISDICTION </span><br />
  The relationship between the User and the Data Controller will be governed by the regulations in
  force in Spanish territory. If any dispute arises, the parties shall submit to the corresponding
  judges and courts in accordance with the law.
</p>
