import { StringIndexedEntities } from '@utils/utils';
import { ConstantEntity, IConstantEntity } from './base.constants';
import { SensorValueError } from './sensor-value.errors';

export const alarmLimits = ['MINIMUM', 'MAXIMUM'] as const;
export type AlarmLimit = typeof alarmLimits[number];

type AlarmValueRange = {
  from: number;
  to: number;
};

export interface ISensorConstantEntity extends IConstantEntity {
  minimumFractionDigits: number;
  maximumFractionDigits: number;
  measureUnit: string;
  htmlColors: string[];
  valueErrorCodes: StringIndexedEntities<SensorValueError[]>;
  minColoredValue: number;
  maxColoredValue: number;
  alarmLimits: AlarmLimit[];
  alarmValueRange: AlarmValueRange;
  isCalculatedTemperature: boolean;
}

export class SensorConstantEntity extends ConstantEntity implements ISensorConstantEntity {
  readonly minimumFractionDigits: number;
  readonly maximumFractionDigits: number;
  readonly measureUnit: string;
  readonly htmlColors: string[];
  readonly valueErrorCodes: StringIndexedEntities<SensorValueError[]>;
  readonly minColoredValue: number;
  readonly maxColoredValue: number;
  readonly alarmLimits: AlarmLimit[];
  readonly alarmValueRange: AlarmValueRange;
  readonly isCalculatedTemperature: boolean;

  constructor(obj: ISensorConstantEntity) {
    super(obj);
    this.minimumFractionDigits = obj.minimumFractionDigits;
    this.maximumFractionDigits = obj.maximumFractionDigits;
    this.measureUnit = obj.measureUnit;
    this.htmlColors = obj.htmlColors;
    this.valueErrorCodes = obj.valueErrorCodes;
    this.minColoredValue = obj.minColoredValue;
    this.maxColoredValue = obj.maxColoredValue;
    this.alarmLimits = obj.alarmLimits;
    this.alarmValueRange = obj.alarmValueRange;
    this.isCalculatedTemperature = obj.isCalculatedTemperature;
  }

  public getHTMLColor(value: number) {
    if (!this.htmlColors || !this.htmlColors.length) {
      return 'unset';
    }
    if ([undefined, null].includes(value)) {
      return 'unset';
    }

    const numberValue = Number(value);
    if (numberValue <= this.minColoredValue) {
      return this.htmlColors[0];
    }
    if (numberValue >= this.maxColoredValue) {
      return this.htmlColors[this.htmlColors.length - 1];
    }

    const idxPercentage =
      (numberValue - this.minColoredValue) / (this.maxColoredValue - this.minColoredValue);
    let position = Math.round(this.htmlColors.length * idxPercentage);
    position = Math.min(this.htmlColors.length - 1, position);

    return this.htmlColors[position];
  }

  public getIconClassName(prefix: string): string {
    return prefix.concat(this.name.toLowerCase().replace(/_/g, '-'));
  }

  public matchesErrorValue(dataProvider: string | number, value: number): boolean {
    const numberValue = Number(value);
    for (const error of this.valueErrorCodes[String(dataProvider)]) {
      if (error.value === numberValue) {
        return true;
      }
    }
    return false;
  }

  public getErrorValue(dataProvider: string | number, value: number): string {
    const numberValue = Number(value);
    for (const error of this.valueErrorCodes[String(dataProvider)]) {
      if (error.value === numberValue) {
        return error.error;
      }
    }
    return String(value);
  }
}
