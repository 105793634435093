import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { Observable } from 'rxjs';
import {
  AgreeToTOSDialogComponent,
  AgreeToTOSDialogInjectedData,
} from '@modules/auth/agreetotos-dialog/agree-to-tos-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  private checkIfUserAgreesToTOS(): void {
    if (!this.localStorageService.userAgreesToTOS) {
      const injectedData: AgreeToTOSDialogInjectedData = {
        language: this.localStorageService.userLanguage,
      };
      this.dialog.open(AgreeToTOSDialogComponent, {
        data: injectedData,
        panelClass: 'agree-to-tos-dialog-panel',
        disableClose: true,
      });
    }
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.localStorageService.userIsAuthenticated) {
      this.checkIfUserAgreesToTOS();
      return true;
    }

    // navigate to login page
    void this.router.navigate(['/auth/login']);
    // you can save redirect url so after authenticating we can move them back to the page they requested
    return false;
  }

  canActivateChild() {
    if (this.localStorageService.userIsAuthenticated) {
      this.checkIfUserAgreesToTOS();
      return true;
    }

    // navigate to login page
    void this.router.navigate(['/auth/login']);
    // you can save redirect url so after authenticating we can move them back to the page they requested
    return false;
  }
}
