import { ConstantEntity, ConstantGroup } from './base.constants';

const BTX = new ConstantEntity({
  value: 0,
  name: 'BTX',
  translationTag: 'bluetoothHubType',
});

const MWL1 = new ConstantEntity({
  value: 1,
  name: 'MWL1',
  translationTag: 'bluetoothHubType',
});

const BT_HUB_TYPES: ConstantEntity[] = [BTX, MWL1];

export class BluetoothHubTypeConstants extends ConstantGroup {
  list(): ConstantEntity[] {
    return BT_HUB_TYPES;
  }

  static get BTX() {
    return BTX;
  }

  static get MWL1() {
    return MWL1;
  }
}
