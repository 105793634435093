import { ConstantEntity, IConstantEntity } from './base.constants';

export interface ICountryConstantEntity extends IConstantEntity {
  phonePrefix?: string;
}

export class CountryConstantEntity extends ConstantEntity implements ICountryConstantEntity {
  readonly phonePrefix: string;

  constructor(obj: ICountryConstantEntity) {
    super(obj);
    this.phonePrefix = obj.phonePrefix;
  }
}
