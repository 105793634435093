import { StringIndexedEntities } from '@utils/utils';
import { DataProvidersConstants } from './data-providers.constants';

export type SensorValueError = {
  value: number;
  error: string;
};

export const NO_ERRORS: StringIndexedEntities<SensorValueError[]> = {
  [DataProvidersConstants.CTCPLUS.value.toString()]: [],
  [DataProvidersConstants.CTCLITE.value.toString()]: [],
};

export const TEMPERATURE_CELSIUS_ERRORS: StringIndexedEntities<SensorValueError[]> = {
  [DataProvidersConstants.CTCPLUS.value.toString()]: [
    { value: -10, error: 'E1' },
    { value: -11, error: 'E2' },
    { value: -12, error: 'E3' },
    { value: -13, error: 'E4' },
    { value: -14, error: 'E5' },
    { value: -20, error: 'E6' },
    { value: -25, error: 'E7' },
  ],
  [DataProvidersConstants.CTCLITE.value.toString()]: [
    { value: -10, error: 'W' },
    { value: -12, error: 'E3' },
    { value: -13, error: 'E4' },
    { value: -14, error: 'E5' },
    { value: -20, error: 'E6' },
  ],
};

export const TEMPERATURE_FARENHEIT_ERRORS: StringIndexedEntities<SensorValueError[]> = {
  [DataProvidersConstants.CTCPLUS.value.toString()]: [
    { value: 14, error: 'E1' },
    { value: 12.2, error: 'E2' },
    { value: 10.4, error: 'E3' },
    { value: 8.6, error: 'E4' },
    { value: 6.8, error: 'E5' },
    { value: -4, error: 'E6' },
    { value: -13, error: 'E7' },
  ],
  [DataProvidersConstants.CTCLITE.value.toString()]: [
    { value: 14, error: 'W' },
    { value: 10.4, error: 'E3' },
    { value: 8.6, error: 'E4' },
    { value: 6.8, error: 'E5' },
    { value: -4, error: 'E6' },
  ],
};

export const HUMIDITY_ERRORS: StringIndexedEntities<SensorValueError[]> = {
  [DataProvidersConstants.CTCPLUS.value.toString()]: [
    { value: 0.1, error: 'E1' },
    { value: 1, error: 'E2' },
    { value: 2, error: 'E3' },
    { value: 3, error: 'E4' },
    { value: 4, error: 'E5' },
    { value: 10, error: 'E6' },
  ],
  [DataProvidersConstants.CTCLITE.value.toString()]: [
    { value: 0.1, error: 'W' },
    { value: 1, error: 'E2' },
    { value: 2, error: 'E3' },
    { value: 3, error: 'E4' },
    { value: 10, error: 'E6' },
  ],
};

const COMMON_CO2_ERRORS: SensorValueError[] = [
  { value: 10, error: 'E0' },
  { value: 100, error: 'E1' },
  { value: 200, error: 'E2' },
  { value: 300, error: 'E3' },
  { value: 400, error: 'E4' },
  { value: 500, error: 'E5' },
  { value: 600, error: 'E6' },
];

export const CO2_ERRORS: StringIndexedEntities<SensorValueError[]> = {
  [DataProvidersConstants.CTCPLUS.value.toString()]: COMMON_CO2_ERRORS,
  [DataProvidersConstants.CTCLITE.value.toString()]: COMMON_CO2_ERRORS,
};
