import { Expose } from 'class-transformer';
import { BaseEntity } from './base.entity';

export class CountryEntity extends BaseEntity {
  @Expose()
  public iso3: string;

  @Expose()
  public applyVAT: boolean;

  @Expose()
  public vatAmount: number;
}
