<mat-toolbar class="main-top-bar surface-1 d-flex justify-content-start align-items-center">
  <div><img class="main-top-logo" src="/assets/images/ctc_cloud_blanc_blanc.svg" (click)="goToHome()" /></div>
  <div class="language-control margin-auto-left">
    <app-change-language></app-change-language>
  </div>
  <div class="user-profile-container">
    <button mat-icon-button [matMenuTriggerFor]="profileIconMenu" class="btn-avatar">
      <span class="avatar font-button">{{ initials }}</span>
    </button>
    <mat-menu #profileIconMenu="matMenu" class="profile-icon-menu">
      <button mat-menu-item  routerLink="/profile">
        <span class="material-symbols-outlined"> account_circle </span>
        <span class="menu-item-text">{{ 'nav.options.profile' | translate }}</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <span class="material-symbols-outlined"> logout </span>
        <span class="menu-item-text">{{ 'nav.options.logout' | translate }}</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav #drawer class="sidenav surface-1" mode="side" opened=>
    <div class="sidenav-items-container d-flex flex-column justify-content-start">
      <mat-nav-list class="flex-grow-1 d-flex flex-column justify-content-start">
        <a
          mat-list-item
          class="d-flex justify-content-start"
          (click)="toggleSideNav()"
        >
          <span class="material-symbols-outlined cursor-pointer drawer-key">
            menu
          </span>
        </a>
        <!-- <a
          mat-list-item
          *ngIf="sideNavOpenState"
          class="d-flex justify-content-end"
          (click)="toggleSideNav()"
        >
          <span class="material-symbols-outlined cursor-pointer drawer-key">
            keyboard_double_arrow_left
          </span>
        </a>
        <a mat-list-item *ngIf="!sideNavOpenState" (click)="toggleSideNav()">
          <span class="material-symbols-outlined cursor-pointer drawer-key">
            keyboard_double_arrow_right
          </span>
        </a> -->
        <ng-container *ngFor="let item of menuItems">
          <app-main-menu-item [item]="item" [sideNavOpenState]="sideNavOpenState"> </app-main-menu-item>
        </ng-container>
      </mat-nav-list>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>

    <mat-toolbar class="custom-toolbar" *ngIf="showCurrentPageTitle()"
    [ngClass]="{ 'd-flex flex-column align-items-start': imASuperAdmin }">
      <div *ngIf="imASuperAdmin"><app-breadcrumbs></app-breadcrumbs></div>
      <div>
        <!-- DEBUG: {{ currentRouteInfo.translationLabel }} -->
        <span class="head-line-4">{{ currentRouteInfo.translationPath | translate }}</span>
      </div>
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
