import { Injectable } from '@angular/core';
import { ViewConfig } from '@config/view.config';
import { UserRoleConstantEntity } from '@constants/user-role-constant.entity';
import { UserRolesConstants } from '@constants/user-roles.constants';
import { AuthLoginResponse } from '@entities/auth-login-response.entity';

type JWTokenContents = {
  id: string;
  iat: number;
  exp: number;
};

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  /**
   * Get api token
   */
  public get apiToken(): string {
    return localStorage.getItem('api-token');
  }
  /**
   * Sets api token
   */
  public set apiToken(token: string) {
    localStorage.setItem('api-token', token);
    // extract info from token
    const jwtData = token.split('.')[1];
    const decodedJwtJsonData = window.atob(jwtData);
    const decodedJwtData: JWTokenContents = JSON.parse(decodedJwtJsonData);
    localStorage.setItem('user-id', decodedJwtData.id);
  }

  /**
   * Get user is LOGGED-IN
   */

  public get userIsAuthenticated(): boolean {
    return this.apiToken != null;
  }

  /**
   * Get user is SUPER_ADMIN role
   */
  public get userIsSuperAdmin(): boolean {
    return Boolean(this.userRole === UserRolesConstants.SUPER_ADMIN.value);
  }

  /**
   * Get user is COMPANY_ADMIN role
   */
  public get userIsCompanyAdmin(): boolean {
    return Boolean(this.userRole === UserRolesConstants.COMPANY_ADMIN.value);
  }

  /**
   * Get user is SUPER_ADMIN or COMPANY_ADMIN role
   */
  public get userIsAdmin(): boolean {
    return Boolean(
      [UserRolesConstants.SUPER_ADMIN.value, UserRolesConstants.COMPANY_ADMIN.value].includes(
        this.userRole
      )
    );
  }

  /**
   * Get user is EMPLOYEE role
   */
  public get userIsEmployee(): boolean {
    return Boolean(this.userRole === UserRolesConstants.EMPLOYEE.value);
  }

  /**
   * Get user's role
   */
  public get userRole(): number {
    return Number(localStorage.getItem('user-role'));
  }
  /**
   * Sets user's role and role's main page
   */
  public set userRole(role: number) {
    localStorage.setItem('user-role', String(role));
    const defaultMainPage = (
      new UserRolesConstants().findById(Number(role)) as UserRoleConstantEntity
    ).defaultMainPage;
    localStorage.setItem('user-roles-main-page', defaultMainPage);
  }
  /**
   * Get user role's main page
   */
  public get userRolesMainPage(): string {
    return localStorage.getItem('user-roles-main-page');
  }

  /**
   * Get user's agreeToTOS
   */
  public get userAgreesToTOS(): boolean {
    return localStorage.getItem('user-agrees-to-tos') === 'true';
  }
  /**
   * Sets user's agreeToTOS
   */
  public set userAgreesToTOS(agrees: boolean) {
    localStorage.setItem('user-agrees-to-tos', String(agrees));
  }

  /**
   * Get user's language
   */
  public get userLanguage(): string {
    return localStorage.getItem('user-language');
  }

  /**
   * Sets user's language
   */
  public set userLanguage(language: string) {
    localStorage.setItem('user-language', language);
  }

  /**
   * Get current language
   */
  public get currentLanguage(): string {
    return localStorage.getItem('current-language') || ViewConfig.DEFAULT_LANG;
  }

  /**
   * Sets current language
   */
  public set currentLanguage(language: string) {
    localStorage.setItem('current-language', language);
  }

  /**
   * Get user's ID
   */
  public get userId(): string {
    return localStorage.getItem('user-id');
  }

  /**
   * Removes api from session
   */
  public deleteApiToken() {
    localStorage.removeItem('api-token');
  }

  public clear() {
    // remember language selection...
    const currentLanguage = this.currentLanguage;
    localStorage.clear();
    // ...even after logging out
    this.currentLanguage = currentLanguage;
  }

  public feedFromLoginResponse(response: AuthLoginResponse) {
    this.apiToken = response.token;
    this.userRole = response.role;
    this.userAgreesToTOS = response.agreeToTOS;
    this.userLanguage = response.language;
  }
}
