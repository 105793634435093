import { Expose } from 'class-transformer';
import { BaseEntity } from './base.entity';
import { urlSafeBase64Decode } from '@utils/utils';

export class BaseRegisterActionTokenDataEntity extends BaseEntity {
  @Expose()
  public dataProvider: number;
}

export class RegisterPlusActionTokenDataEntity extends BaseRegisterActionTokenDataEntity {
  @Expose()
  public origin: 'token' | 'qr';

  @Expose()
  public encryptedDesktopKey: string;

  @Expose()
  public encryptedReference: string;

  get desktopKey(): string {
    return urlSafeBase64Decode(this.encryptedDesktopKey);
  }

  get reference(): string {
    return urlSafeBase64Decode(this.encryptedReference);
  }

  get valid(): boolean {
    return !!this.encryptedReference && !!this.encryptedDesktopKey;
  }

  get validQR(): boolean {
    return this.valid && this.origin === 'qr';
  }
}

export class RegisterLiteActionTokenDataEntity extends BaseRegisterActionTokenDataEntity {
  @Expose()
  public registrationCode: string;

  @Expose()
  public email: string;
}
