import { Expose, Type } from 'class-transformer';
import { BaseEntity } from './base.entity';
import { UserFlagsEntity } from './user.entity';

export class AuthLoginResponse extends BaseEntity {
  @Expose()
  public token: string;

  @Expose()
  public role: number;

  @Expose()
  public language: string;

  @Expose()
  public agreeToTOS: boolean;

  @Expose()
  @Type(() => UserFlagsEntity)
  public flags: UserFlagsEntity;
}
