import { Component, OnDestroy, OnInit } from '@angular/core';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { NavigationRoutesService } from '@services/navigation-routes/navigation-routes.service';
import { BaseComponent } from '@shared/components/base.component';
import { Subscription } from 'rxjs';
import { BreadcrumbItemEntity } from '../entities/breadcrumb-item.entity';
import { initializeClass } from '@utils/utils';
import { RouteInfoEntity } from '@services/navigation-routes/route-info.entity';
import { BECompaniesService } from '@services/backend-services/companies/be-companies.service';
import { first } from 'rxjs/operators';
import { BEInstallationsService } from '@services/backend-services/installations/be-installations.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent extends BaseComponent implements OnInit, OnDestroy {
  public currentRouteInfo: RouteInfoEntity = RouteInfoEntity.INITIAL;
  public breadcrumbs: BreadcrumbItemEntity[] = [];

  private subscriptions: Subscription[];

  constructor(
    public localStorageService: LocalStorageService,
    private navigationRoutesService: NavigationRoutesService,
    private beCompaniesService: BECompaniesService,
    private beInstallationsService: BEInstallationsService
  ) {
    super(localStorageService);
  }

  ngOnInit() {
    this.subscriptions = [
      // this.navigationRoutesService.isInstallationUrl.subscribe((itIs) => {
      //   // Auto-hide side navigation if the URL contains "installation/uuid"
      //   this.sideNavOpenState = !itIs;
      // }),
      this.navigationRoutesService.routeInfo.subscribe((routeInfo) => {
        this.currentRouteInfo = routeInfo;
        this.feedBreadcrumbs();
      }),
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((el) => {
      el.unsubscribe();
    });
  }

  private feedBreadcrumbs() {
    const companiesBreadcrumb = initializeClass(BreadcrumbItemEntity, {
      link: '/companies',
      translationLabel: 'companies',
    });
    const installationsBreadcrumb = initializeClass(BreadcrumbItemEntity, {
      link: '/installations/all/ctcplus',
      translationLabel: 'installations',
    });
    // common start breadcrumb
    this.breadcrumbs = [
      initializeClass(BreadcrumbItemEntity, { link: '/dashboard', translationLabel: 'dashboard' }),
    ];
    // second breadcrumb
    if (
      ['super-admin-company-users', 'installation', 'installations-of-company'].includes(
        this.currentRouteInfo.translationLabel
      )
    ) {
      this.breadcrumbs.push(companiesBreadcrumb);
    }
    if (
      ['installations-all-ctcplus', 'installations-all-ctclite'].includes(
        this.currentRouteInfo.translationLabel
      )
    ) {
      this.breadcrumbs.push(installationsBreadcrumb);
    }
    // third breadcrumb
    if (
      ['super-admin-company-users', 'installations-of-company'].includes(
        this.currentRouteInfo.translationLabel
      )
    ) {
      this.beCompaniesService
        .getCompanyById(this.currentRouteInfo.uuidParam)
        .pipe(first())
        .subscribe((company) => {
          this.breadcrumbs.push(
            initializeClass(BreadcrumbItemEntity, {
              link: `/companies/${this.currentRouteInfo.uuidParam}`,
              name: company.name,
            })
          );
        });
    }
    if (this.currentRouteInfo.translationLabel === 'installation') {
      this.beInstallationsService
        .getInstallationById(this.currentRouteInfo.uuidParam)
        .pipe(first())
        .subscribe((installation) => {
          this.breadcrumbs.push(
            initializeClass(BreadcrumbItemEntity, {
              link: `/companies/${installation.company.id}`,
              name: installation.company.name,
            })
          );
          this.breadcrumbs.push(
            initializeClass(BreadcrumbItemEntity, {
              link: `/installations/of-company/${installation.company.id}`,
              translationLabel: 'installations',
            })
          );
        });
    }
  }
}
