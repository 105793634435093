import { Injectable } from '@angular/core';
import { CanActivate, Router, CanActivateChild } from '@angular/router';
import { LocalStorageService } from '@services/localStorage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private localStorageService: LocalStorageService) {}

  canActivate() {
    if (!this.localStorageService.userIsAuthenticated) {
      return true;
    }

    void this.router.navigate([this.localStorageService.userRolesMainPage]);
    return false;
  }

  canActivateChild() {
    if (!this.localStorageService.userIsAuthenticated) {
      return true;
    }

    void this.router.navigate([this.localStorageService.userRolesMainPage]);
    return false;
  }
}
