import { Expose, Type } from 'class-transformer';
import { BaseUUIDEntity } from './base.entity';
import { NodeEntity } from './node.entity';
import { SensorConstantEntity } from '@constants/sensor-constant.entity';
import { SensorTypesConstants } from '@constants/sensor-types.constants';
import { NodeSensorByType, StringIndexedEntities } from '@utils/utils';
import { ProbeDebugReadingEntity } from './probe-debug-reading.entity';

export class ProbeEntity extends BaseUUIDEntity {
  @Expose()
  public name: string;

  @Expose()
  public isTwoWires: boolean;

  @Expose()
  public isAnalogic: boolean;

  @Expose()
  public batteryCapacity: number;

  public twoWiresHasHum: boolean;

  @Expose()
  @Type(() => NodeEntity)
  public nodes: NodeEntity[];

  public get orderedNodes(): NodeEntity[] {
    return this.nodes.sort((a, b) => Number(a.position) - Number(b.position));
  }

  public gapLessNodesBySensorType: StringIndexedEntities<NodeSensorByType[]> = {};

  public feedGapLessNodesBySensorType(sensorType: number): void {
    const couples: NodeSensorByType[] = [];
    for (const [index, node] of this.orderedNodes.entries()) {
      let sensorTypeFound = false;
      for (const sensor of node.sensors) {
        if (sensor.sensorType === sensorType) {
          sensorTypeFound = true;
          couples.push({ node, sensor });
          break;
        }
      }
      if (!sensorTypeFound) {
        couples.push(undefined);
      }
    }

    this.gapLessNodesBySensorType[String(sensorType)] = couples;
  }

  @Expose()
  @Type(() => NodeEntity)
  public multiplexerNodes: NodeEntity[];

  @Expose()
  @Type(() => NodeEntity)
  public twoWiresNodes: NodeEntity[];

  @Expose()
  public sensorTypes: number[];

  public sensorConstants(tempUoM: string): SensorConstantEntity[] {
    return new SensorTypesConstants()
      .listProbeSensors(tempUoM)
      .filter((stc) => this.sensorTypes.includes(stc.value));
  }

  @Expose()
  @Type(() => ProbeDebugReadingEntity)
  public debugs: ProbeDebugReadingEntity[];

  private debugReadings$: StringIndexedEntities<ProbeDebugReadingEntity> = {};

  public get debugReadings(): StringIndexedEntities<ProbeDebugReadingEntity> {
    if (!Object.keys(this.debugReadings$).length) {
      this.feedDebugReadings();
    }

    return this.debugReadings$;
  }

  private feedDebugReadings(): void {
    for (const reading of this.debugs) {
      this.debugReadings$[reading.readingTime] = reading;
    }
  }
}
