import * as HttpStatus from 'http-status-codes';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { ToastrService } from 'ngx-toastr';

export const errorLoginUser = (
  error: HttpErrorResponse,
  toastr: ToastrService,
  translationMWService: TranslationMWService
) => {
  if (error.status === HttpStatus.StatusCodes.NOT_FOUND) {
    toastr.error(translationMWService.instant('error.message.UserNotFoundError'));
  }
  if (error.status === HttpStatus.StatusCodes.UNAVAILABLE_FOR_LEGAL_REASONS) {
    toastr.error(
      translationMWService.instant('login.toastr.error.user-disabled.message'),
      translationMWService.instant('login.toastr.error.user-disabled.title')
    );
  }
  if (error.status === HttpStatus.StatusCodes.LOCKED) {
    toastr.error(
      translationMWService.instant('login.toastr.error.company-disabled.message'),
      translationMWService.instant('login.toastr.error.company-disabled.title')
    );
  }
};
