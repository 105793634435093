<div class="d-flex justify-content-center">
  <div><span class="head-line-5">CTC Cloud</span></div>
</div>
<p class="font-body-1">
  <span class="font-subtitle-2">TITULAR DE CTC Cloud</span><br />
  En compliment amb el deure d'informaci&oacute; recollit a l'article 10 de la Llei 34/2002, d'11 de
  juliol, de Serveis de la Societat de la Informaci&oacute; i del Comer&ccedil; Electr&ograve;nic,
  es reflecteixen les seg&uuml;ents dades:
</p>

<span class="font-body-1">Entitat: Gescaser, S.A.</span><br />
<span class="font-body-1">C.I.F.: A25276635</span><br />
<span class="font-body-1">Domicili: Carrer Sant Pere 1, 25100 Almacelles (Lleida)</span><br />
<span class="font-body-1"
  >Inscrit al Registre Mercantil de Lleida, Tom 81, Foli 44, Full L1450, Inscripci&oacute; 1a.</span
><br />
<span class="font-body-1">Tel&egrave;fon: 973740695</span><br />
<span class="font-body-1"
  >Correu electr&ograve;nic:<a href="mailto:gescaser@gescaser.com">gescaser@gescaser.com</a></span
><br />

<p class="font-body-1">
  <span class="font-subtitle-2">OBJECTE</span><br />
  El Responsable posa a disposici&oacute; dels usuaris el present document amb l'objectiu de complir
  amb el que disposa la Llei 34/2002, d'11 de juliol, de Serveis de la Societat de la
  Informaci&oacute; i del Comer&ccedil; Electr&ograve;nic i les normatives vigents en mat&egrave;ria
  de Protecci&oacute; de Dades, aix&iacute; com d'informar a tots els usuaris respecte a les
  condicions d'&uacute;s del sistema CTC Cloud a trav&eacute;s del suport web i de la
  aplicaci&oacute; m&ograve;bil (&quot;web/app&quot;).
</p>
<p class="font-body-1">
  Tota persona que accedeixi a aquesta aplicaci&oacute; assumeix el rol d' usuari, comprometent-se a
  l' observan&ccedil;a i compliment rigor&oacute;s de les disposicions aqu&iacute; establertes,
  aix&iacute; com a qualsevol altra disposici&oacute; legal que sigui aplicable.
</p>
<p class="font-body-1">
  El Responsable es reserva el dret de modificar qualsevol tipus d'informaci&oacute; que pugui
  apar&egrave;ixer al web/app sense que hi hagi l'obligaci&oacute; de preavisar o informar els
  usuaris d'aquestes modificacions, entenent-se com a suficient la publicaci&oacute; al web/app.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">RESPONSABILITAT</span><br />
  El Responsable s'eximeix de qualsevol tipus de responsabilitat derivada de la informaci&oacute;
  publicada al web/app sempre que aquesta informaci&oacute; hagi estat manipulada o introdu&iuml;da
  per un tercer ali&egrave; al mateix.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">POL&Iacute;TICA D' ENLLA&Ccedil;OS </span><br />
  Des del web/app &eacute;s possible que es redirigi a continguts de llocs web de tercers.
  At&egrave;s que el Responsable no pot controlar els continguts introdu&iuml;ts pels tercers en els
  seus llocs web/app, no assumeix cap tipus de responsabilitat respecte a aquests continguts.
</p>
<p class="font-body-1">
  No obstant aix&ograve;, el Responsable es compromet a la retirada immediata de qualsevol contingut
  que pugui contravenir la legislaci&oacute; nacional o internacional, la moral o l' ordre
  p&uacute;blic.
</p>
<p class="font-body-1">
  El Responsable no es fa responsable de la informaci&oacute; i continguts emmagatzemats en
  f&ograve;rums, xats, generadors de blogs, comentaris, xarxes socials o qualsevol altre
  mitj&agrave; que permeti a tercers publicar continguts de manera independent al Web/web/app. En
  cas que l'usuari consideri que existeix al web/app algun contingut que pugui ser susceptible
  d'aquesta classificaci&oacute;, es prega que ho notifiqui de forma immediata.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">PROTECCI&Oacute; DE DADES </span><br />
  El Responsable est&agrave; comprom&egrave;s amb el compliment de la normativa vigent en
  mat&egrave;ria de protecci&oacute; de dades, incloent-hi el RGPD i la LOPDGDD. Aix&iacute; mateix,
  el Responsable informa que compleix amb la Llei 34/2002 de Serveis de la Societat de la
  Informaci&oacute; i del Comer&ccedil; Electr&ograve;nic i sol&middot;licitar&agrave; el seu
  consentiment per al tractament de les seves dades amb finalitats comercials en cada moment.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">PROPIETAT INTEL&middot;LECTUAL I INDUSTRIAL </span><br />
  La web/app, incloent la seva programaci&oacute;, edici&oacute;, compilaci&oacute; i altres
  elements necessaris per al seu funcionament, els dissenys, logotips, textos i/o gr&agrave;fics
  s&oacute;n propietat del Responsable o disposa de llic&egrave;ncia o autoritzaci&oacute; expressa
  per part dels autors. Tots els continguts estan protegits per la normativa de propietat
  intel&middot;lectual i industrial.
</p>
<p class="font-body-1">
  Qualsevol &uacute;s no autoritzat pr&egrave;viament per part del Responsable ser&agrave;
  considerat un incompliment greu dels drets de propietat intel&middot;lectual o industrial de
  l'autor. Els dissenys, logotips, textos i/o gr&agrave;fics aliens al Responsable i que puguin
  apar&egrave;ixer al web/app pertanyen als seus respectius propietaris.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">LEGISLACI&Oacute; APLICABLE I JURISDICCI&Oacute; </span><br />
  La relaci&oacute; entre l' Usuari i el Responsable es regir&agrave; per la normativa vigent en el
  territori espanyol. Si sorgeix qualsevol controv&egrave;rsia, les parts se sotmetran als jutges i
  tribunals que corresponguin conforme a dret.
</p>
