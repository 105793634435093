import { Expose, Transform, Type } from 'class-transformer';
import { UserEntity } from './user.entity';
import { BaseAddressStringCountryEntity } from './base-address.entity';

export class CompanyEntity extends BaseAddressStringCountryEntity {
  @Expose()
  public name: string;

  @Expose()
  public phone: string;

  @Expose()
  public webSite: string;

  @Expose()
  public installationsLimit: number;

  @Expose()
  @Transform(({ value }) => Number(value), { toClassOnly: true })
  public dataSummary: number;

  @Expose()
  public markedForRemoval: boolean;

  @Expose()
  @Transform(({ value }) => Number(value), { toClassOnly: true })
  public totalInstallations: number;

  @Expose()
  @Transform(({ value }) => Number(value), { toClassOnly: true })
  public totalUsers: number;

  @Expose()
  @Type(() => UserEntity)
  companyAdmin: UserEntity;

  public get adminName(): string {
    return this.companyAdmin.fullName;
  }

  public get adminEmail(): string {
    return this.companyAdmin.email;
  }

  @Expose()
  public deletable: boolean;

  @Expose()
  public disabled: boolean;

  get changeDisabledStateText(): string {
    return this.disabled ? 'enable' : 'disable';
  }

  get currentDisabledStateText(): string {
    return this.disabled ? 'disabled' : 'enabled';
  }
}
