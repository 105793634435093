import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';

import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

export const TRANSLATE_FOLDERS = {
  locales: 'locales',
};

const i18nFolder = './assets/i18n';

/**
 * Translate Http Loaded class, loads the given array files
 *
 * @implements TranslateLoader
 * @param http a http client
 * @param files a file array to load
 */
export class MultiTranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient, public folders: string[]) {}

  public getTranslation(lang: string): any {
    return forkJoin(
      this.folders.map((folder) => this.http.get(`${i18nFolder}/${folder}/${lang}.json`))
    ).pipe(map((response) => response.reduce((a, b) => Object.assign(a, b))));
  }
}

const getMultiTranslateHttpLoader = (http: HttpClient) =>
  new MultiTranslateHttpLoader(http, Object.values(TRANSLATE_FOLDERS));
/**
 * App Translate Class to set the Translate loader
 */
export class AppTranslate {
  static loader() {
    return {
      loader: {
        provide: TranslateLoader,
        useFactory: getMultiTranslateHttpLoader,
        deps: [HttpClient],
      },
    };
  }
}
