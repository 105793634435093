import { ConstantEntity, ConstantGroup } from './base.constants';

const ACTIVATEACCOUNT = new ConstantEntity({
  value: 0,
  name: 'ACTIVATEACCOUNT',
  translationTag: 'actionTokenKind',
});

const CHANGEPASSWORD = new ConstantEntity({
  value: 1,
  name: 'CHANGEPASSWORD',
  translationTag: 'actionTokenKind',
});

const CHANGEEMAIL = new ConstantEntity({
  value: 2,
  name: 'CHANGEEMAIL',
  translationTag: 'actionTokenKind',
});

const PASSWDCONFIRMINVITATION = new ConstantEntity({
  value: 3,
  name: 'PASSWDCONFIRMINVITATION',
  translationTag: 'actionTokenKind',
});

const REGISTERCOMPANY = new ConstantEntity({
  value: 4,
  name: 'REGISTERCOMPANY',
  translationTag: 'actionTokenKind',
});

const ACTION_TOKEN_KINDS: ConstantEntity[] = [
  ACTIVATEACCOUNT,
  CHANGEPASSWORD,
  CHANGEEMAIL,
  PASSWDCONFIRMINVITATION,
  REGISTERCOMPANY,
];

export class ActionTokenKindsConstants extends ConstantGroup {
  list(): ConstantEntity[] {
    return ACTION_TOKEN_KINDS;
  }

  static get ACTIVATEACCOUNT() {
    return ACTIVATEACCOUNT;
  }

  static get CHANGEPASSWORD() {
    return CHANGEPASSWORD;
  }

  static get CHANGEEMAIL() {
    return CHANGEEMAIL;
  }

  static get PASSWDCONFIRMINVITATION() {
    return PASSWDCONFIRMINVITATION;
  }

  static get REGISTERCOMPANY() {
    return REGISTERCOMPANY;
  }
}
