import { ConstantEntity, IConstantEntity } from './base.constants';

export interface IEventConstantEntity extends IConstantEntity {
  zeroColor?: string;
  positiveColor?: string;
}

export class EventConstantEntity extends ConstantEntity implements IEventConstantEntity {
  readonly zeroColor: string;
  readonly positiveColor: string;

  constructor(obj: IEventConstantEntity) {
    super(obj);
    this.zeroColor = obj.zeroColor;
    this.positiveColor = obj.positiveColor;
  }
}
