<div class="progressbar-container">
  <div class="progressbar-title font-body-2">
    {{loadingTitle}}
  </div>
  <dx-progress-bar
    #progressBar
    [class.complete]="maxValue === progressValue"
    [min]="minValue"
    [max]="maxValue"
    [showStatus]="showStatus"
    [value]="progressValue"
    width="97%"
  >
  </dx-progress-bar>
  <div class="progressbar-subtitle font-subtitle-2">
    {{'auth.login.loadingSubtitle' | translate}}
  </div>
</div>
