import * as moment from 'moment';
import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { AppConfig } from '@config/app.config';
import { BluetoothHubTypeConstants } from '@constants/bluetooth-hub-type.constants';
import { DataProvidersConstants } from '@constants/data-providers.constants';
import { SensorTypesConstants } from '@constants/sensor-types.constants';
import { UserRolesConstants } from '@constants/user-roles.constants';
import { BaseEntity } from '@entities/base.entity';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { customDataSummary, dateIntlFormatForDX, FormFieldValidatorsDef } from '@utils/utils';
import { ViewConfig } from '@config/view.config';
import { PreferenceEntity } from '@entities/preference.entity';

@Component({ template: '' })
export class BaseComponent {
  // fast access to configuration values (mostly on templates)
  public scrollbarStyle = ViewConfig.SCROLLBAR_STYLE;
  // fast access to constant values (mostly on templates)
  public nullPreferenceValue = PreferenceEntity.NULL;
  public superAdminsCompanyUUID = AppConfig.SuperAdminsCompanyUUID;
  public superAdminRoleId = UserRolesConstants.SUPER_ADMIN.value;
  public superAdminRoleName = UserRolesConstants.SUPER_ADMIN.name.toLowerCase();
  public companyAdminRoleId = UserRolesConstants.COMPANY_ADMIN.value;
  public companyAdminRoleName = UserRolesConstants.COMPANY_ADMIN.name.toLowerCase();
  public employeeRoleId = UserRolesConstants.EMPLOYEE.value;
  public employeeRoleName = UserRolesConstants.EMPLOYEE.name.toLowerCase();
  public demoRoleId = UserRolesConstants.DEMO.value;
  public demoRoleName = UserRolesConstants.DEMO.name.toLowerCase();
  public ctcPlusDataProviderId = DataProvidersConstants.CTCPLUS.numberValue;
  public ctcLiteDataProviderId = DataProvidersConstants.CTCLITE.numberValue;
  public btxBluetoothHubTypeId = BluetoothHubTypeConstants.BTX.numberValue;
  public mlw1BluetoothHubTypeId = BluetoothHubTypeConstants.MWL1.numberValue;
  public temperatureSensorTypeId = SensorTypesConstants.TEMPERATURE_C.numberValue;
  // dummy sensor type values for extra buttons
  public javelinDebug = SensorTypesConstants.JAVELIN_DEBUG.numberValue;

  private excelScriptLoaded = false;

  constructor(public localStorageService: LocalStorageService) {}

  public customDataSummary(dataSummary: number): string {
    return customDataSummary(Number(dataSummary), this.currentLanguage);
  }

  public imageUrl(filename: string): string {
    return `${AppConfig.BACKEND_BASE_URL}/uploads/${filename}`;
  }

  public get userId(): string {
    return this.localStorageService.userId;
  }

  public get userRoleId(): number {
    return this.localStorageService.userRole;
  }

  public get imASuperAdmin(): boolean {
    return this.localStorageService.userIsSuperAdmin;
  }

  public get imACompanyAdmin(): boolean {
    return this.localStorageService.userIsCompanyAdmin;
  }

  public get imAnAdmin(): boolean {
    return this.localStorageService.userIsAdmin;
  }

  public get imAnEmployee(): boolean {
    return this.localStorageService.userIsEmployee;
  }

  public get currentLanguage(): string {
    return this.localStorageService.currentLanguage;
  }

  public get dxDateDisplayFormat(): string {
    return dateIntlFormatForDX(this.currentLanguage);
  }

  protected initFormGroupAndValues(
    entity: BaseEntity,
    formFields: FormFieldValidatorsDef,
    fieldsExceptions = {}
  ): FormGroup {
    const formGroup = new FormGroup({});

    if (entity) {
      Object.keys(formFields).forEach((field) => {
        const fieldValue =
          fieldsExceptions && fieldsExceptions[field]
            ? fieldsExceptions[field](entity)
            : entity[field];

        formGroup.setControl(field, new FormControl(fieldValue, formFields[field]));
      });
    }

    return formGroup;
  }

  protected initFormArrayAndValues(
    entities: BaseEntity[],
    fieldName: string,
    validators: any[]
  ): FormArray {
    const formArray = new FormArray([]);

    if (entities && entities.length) {
      entities.forEach((entity) => {
        formArray.push(new FormControl(entity[fieldName], validators));
      });
    }

    return formArray;
  }

  protected upsertFormControl(
    formGroup: FormGroup,
    controlName: string,
    controlValues: AbstractControl
  ) {
    if (formGroup.contains(controlName)) {
      formGroup.setControl(controlName, controlValues);
    } else {
      formGroup.addControl(controlName, controlValues);
    }
  }

  public openCoordinatesOnGoogleMaps(latitude: number, longitude: number) {
    window.open(
      `https://www.google.com/maps/@?api=1&map_action=map&basemap=satellite&zoom=19&center=${latitude},${longitude}`,
      '_blank'
    );
  }

  protected async loadXLSXScript(): Promise<any> {
    return new Promise((resolve) => {
      if (this.excelScriptLoaded) {
        resolve('already loaded');
        return;
      }
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://unpkg.com/xlsx-js-style@1.2.0/dist/xlsx.min.js';
      script.onload = () => {
        this.excelScriptLoaded = true;
        resolve('loaded');
      };
      script.onerror = (err) => resolve('failed to load xslx script');
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }

  public quantityTranslationSuffix(value: number): string {
    return value === 1 ? 'singular' : 'plural';
  }
}
