<div class="d-flex justify-content-center">
  <div><span class="head-line-5">CTC Cloud</span></div>
</div>
<p class="font-body-1">
  <span class="font-subtitle-2">TITULAR DE CTC Cloud </span><br />
  En cumplimiento con el deber de informaci&oacute;n recogido en el art&iacute;culo 10 de la Ley
  34/2002, de 11 de julio, de Servicios de la Sociedad de la Informaci&oacute;n y del Comercio
  Electr&oacute;nico, se reflejan los siguientes datos:
</p>

<span class="font-body-1">Entidad: Gescaser, S.A.</span><br />
<span class="font-body-1">C.I.F.: A25276635</span><br />
<span class="font-body-1">Domicilio: Carrer Sant Pere 1, 25100 Almacelles (Lleida)</span><br />
<span class="font-body-1"
  >Inscrito en el Registro Mercantil de Lleida, Tomo 81, Folio 44, Hoja L1450, Inscripci&oacute;n
  1&ordf;. </span
><br />
<span class="font-body-1">Tel&eacute;fono: 973740695</span><br />
<span class="font-body-1"
  >Correo electr&oacute;nico:<a href="mailto:gescaser@gescaser.com">gescaser@gescaser.com</a></span
><br />

<p class="font-body-1">
  <span class="font-subtitle-2">OBJETO</span><br />
  El Responsable pone a disposici&oacute;n de los usuarios el presente documento con el objetivo de
  cumplir con lo dispuesto en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
  Informaci&oacute;n y del Comercio Electr&oacute;nico y las normativas vigentes en materia de
  Protecci&oacute;n de Datos, as&iacute; como de informar a todos los usuarios respecto a las
  condiciones de uso del sistema CTC Cloud a trav&eacute;s del soporte web y de la aplicaci&oacute;n
  m&oacute;vil (&ldquo;web/app&rdquo;).
</p>
<p class="font-body-1">
  Toda persona que acceda a esta aplicaci&oacute;n asume el rol de usuario, comprometi&eacute;ndose
  a la observancia y cumplimiento riguroso de las disposiciones aqu&iacute; establecidas, as&iacute;
  como a cualquier otra disposici&oacute;n legal que sea aplicable.
</p>
<p class="font-body-1">
  El Responsable se reserva el derecho de modificar cualquier tipo de informaci&oacute;n que pueda
  aparecer en la web/app sin que exista la obligaci&oacute;n de preavisar o informar a los usuarios
  de dichas modificaciones, entendi&eacute;ndose como suficiente la publicaci&oacute;n en la
  web/app.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">RESPONSABILIDAD </span><br />
  El Responsable se exime de cualquier tipo de responsabilidad derivada de la informaci&oacute;n
  publicada en la web/app siempre que dicha informaci&oacute;n haya sido manipulada o introducida
  por un tercero ajeno al mismo.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">POL&Iacute;TICA DE ENLACES </span><br />
  Desde la web/app es posible que se redirija a contenidos de sitios web de terceros. Dado que el
  Responsable no puede controlar los contenidos introducidos por los terceros en sus sitios web/app,
  no asume ning&uacute;n tipo de responsabilidad respecto a dichos contenidos.
</p>
<p class="font-body-1">
  No obstante, el Responsable se compromete a la retirada inmediata de cualquier contenido que pueda
  contravenir la legislaci&oacute;n nacional o internacional, la moral o el orden p&uacute;blico.
</p>
<p class="font-body-1">
  El Responsable no se hace responsable de la informaci&oacute;n y contenidos almacenados en foros,
  chats, generadores de blogs, comentarios, redes sociales o cualquier otro medio que permita a
  terceros publicar contenidos de manera independiente en la Web/web/app. En caso de que el usuario
  considere que existe en la web/app alg&uacute;n contenido que pueda ser susceptible de esta
  clasificaci&oacute;n, se ruega que lo notifique de forma inmediata.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">PROTECCI&Oacute;N DE DATOS </span><br />
  El Responsable est&aacute; comprometido con el cumplimiento de la normativa vigente en materia de
  protecci&oacute;n de datos, incluyendo el RGPD y la LOPDGDD. Asimismo, el Responsable informa que
  cumple con la Ley 34/2002 de Servicios de la Sociedad de la Informaci&oacute;n y del Comercio
  Electr&oacute;nico y solicitar&aacute; su consentimiento para el tratamiento de sus datos con
  fines comerciales en cada momento.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">PROPIEDAD INTELECTUAL E INDUSTRIAL </span><br />
  La web/app, incluyendo su programaci&oacute;n, edici&oacute;n, compilaci&oacute;n y dem&aacute;s
  elementos necesarios para su funcionamiento, los dise&ntilde;os, logotipos, textos y/o
  gr&aacute;ficos son propiedad del Responsable o dispone de licencia o autorizaci&oacute;n expresa
  por parte de los autores. Todos los contenidos est&aacute;n protegidos por la normativa de
  propiedad intelectual e industrial.
</p>
<p class="font-body-1">
  Cualquier uso no autorizado previamente por parte del Responsable ser&aacute; considerado un
  incumplimiento grave de los derechos de propiedad intelectual o industrial del autor. Los
  dise&ntilde;os, logotipos, textos y/o gr&aacute;ficos ajenos al Responsable y que puedan aparecer
  en la web/app pertenecen a sus respectivos propietarios.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">LEGISLACI&Oacute;N APLICABLE Y JURISDICCI&Oacute;N </span><br />
  La relaci&oacute;n entre el Usuario y el Responsable se regir&aacute; por la normativa vigente en
  el territorio espa&ntilde;ol. Si surge cualquier controversia, las partes se someter&aacute;n a
  los jueces y tribunales que correspondan conforme a derecho.
</p>
