type POSCoins = {
  [iso4217: string]: string;
};

const cecabankCoins: POSCoins = {
  eur: '978',
  usd: '840',
  gbp: '826',
  aud: '036',
  mxn: '484',
  chf: '756',
  jpy: '392',
  dkk: '208',
  sek: '752',
  nok: '578',
  default: '978', //  euro
};

export const getCecabankCoin = (iso4217: string): string =>
  cecabankCoins[iso4217.toLowerCase()] || cecabankCoins.default;
