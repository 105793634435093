<div class="d-flex justify-content-center">
  <div><span class="head-line-5">CTC debesis</span></div>
</div>
<p class="font-body-1">
  <span class="font-subtitle-2">CTC debesies laikiklis </span><br />
  Vykdant informavimo pareigą, nustatytą 2002 m. liepos 11 d. Įstatymo Nr. 34/2002 dėl informacinės
  visuomenės paslaugų ir elektroninės komercijos 10 straipsnyje, pateikiami &scaron;ie duomenys:
</p>

<span class="font-body-1">Subjektas: Gescaser, S.A.</span><br />
<span class="font-body-1">C.I.F.: A25276635</span><br />
<span class="font-body-1">Adresas: Carrer Sant Pere 1, 25100 Almacelles (Lleida)</span><br />
<span class="font-body-1"
  >Įregistruotas Lleidos merkantiliniame registre, 81 tomas, Folio 44, puslapis L1450, 1-asis
  užra&scaron;as. </span
><br />
<span class="font-body-1">Telefonas: 973740695</span><br />
<span class="font-body-1"
  >El. pa&scaron;tas:<a href="mailto:gescaser@gescaser.com">gescaser@gescaser.com</a></span
><br />

<p class="font-body-1">
  <span class="font-subtitle-2">DAIKTAS</span><br />
  Duomenų valdytojas pateikia &scaron;į dokumentą vartotojams, kad būtų laikomasi 2002 m. liepos 11
  d. įstatymo Nr. 34/2002 dėl informacinės visuomenės paslaugų ir elektroninės komercijos nuostatų
  ir galiojančių duomenų apsaugos taisyklių, taip pat kad visi vartotojai būtų informuoti apie CTC
  debesijos sistemos naudojimo sąlygas per žiniatinklio palaikymą ir mobilioji programa
  (&quot;žiniatinklis / programa&quot;).
</p>
<p class="font-body-1">
  Bet kuris asmuo, turintis prieigą prie &scaron;ios programos, prisiima vartotojo vaidmenį,
  įsipareigodamas laikytis ir griežtai laikytis čia nustatytų nuostatų, taip pat bet kokių kitų
  teisinių nuostatų, kurios gali būti taikomos.
</p>
<p class="font-body-1">
  Duomenų valdytojas pasilieka teisę keisti bet kokio tipo informaciją, kuri gali būti rodoma
  svetainėje / programėlėje, neprivalėdamas i&scaron; anksto įspėti ar informuoti vartotojus apie
  tokius pakeitimus, o paskelbimas svetainėje / programėlėje yra suprantamas kaip pakankamas.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">ATSAKOMYBĖ </span><br />
  Duomenų valdytojas atleidžiamas nuo bet kokios rū&scaron;ies atsakomybės, kylančios i&scaron;
  svetainėje / programėlėje paskelbtos informacijos, jei tokia informacija manipuliavo ar ją pateikė
  su ja nesusijusi trečioji &scaron;alis.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">NUORODŲ POLITIKA </span><br />
  I&scaron; svetainės / programos gali būti, kad ji bus nukreipta į trečiųjų &scaron;alių svetainių
  turinį. Kadangi Duomenų valdytojas negali kontroliuoti turinio, kurį trečiosios &scaron;alys
  pateikia savo svetainėse / programėlėse, jis neprisiima jokios atsakomybės už tokį turinį.
</p>
<p class="font-body-1">
  Tačiau Duomenų valdytojas įsipareigoja nedelsiant pa&scaron;alinti bet kokį turinį, kuris gali
  prie&scaron;tarauti nacionalinei ar tarptautinei teisei, moralei ar vie&scaron;ajai tvarkai.
</p>
<p class="font-body-1">
  Administratorius neatsako už informaciją ir turinį, saugomą forumuose, pokalbiuose,
  tinklara&scaron;čių generatoriuose, komentaruose, socialiniuose tinkluose ar bet kokiomis kitomis
  priemonėmis, leidžiančiomis trečiosioms &scaron;alims savaranki&scaron;kai skelbti turinį
  žiniatinklyje / žiniatinklyje / programoje. Jei naudotojas mano, kad svetainėje / programoje yra
  turinio, kuriam gali būti taikoma &scaron;i klasifikacija, nedelsdami apie tai prane&scaron;kite.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">DUOMENŲ APSAUGA </span><br />
  Duomenų valdytojas yra įsipareigojęs laikytis galiojančių duomenų apsaugos reglamentų, įskaitant
  BDAR ir LOPDGDD. Duomenų valdytojas taip pat informuoja, kad laikosi Įstatymo Nr. 34/2002 dėl
  informacinės visuomenės paslaugų ir elektroninės komercijos ir visada pra&scaron;ys jūsų sutikimo
  tvarkyti jūsų duomenis komerciniais tikslais.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">INTELEKTINĖ IR PRAMONINĖ NUOSAVYBĖ </span><br />
  Svetainė / programa, įskaitant jos programavimą, redagavimą, kompiliavimą ir kitus jos veikimui
  reikalingus elementus, dizainas, logotipai, tekstai ir (arba) grafika yra Valdytojo nuosavybė arba
  turi licenciją ar ai&scaron;kų autorių įgaliojimą. Visą turinį saugo intelektinės ir pramoninės
  nuosavybės reglamentai.
</p>
<p class="font-body-1">
  Bet koks naudojimas, kuriam anksčiau Valdytojas nedavė leidimo, bus laikomas rimtu autoriaus
  intelektinės ar pramoninės nuosavybės teisių pažeidimu. Dizainai, logotipai, tekstai ir (arba)
  grafika, kurie nepriklauso Duomenų valdytojui ir kurie gali būti rodomi svetainėje / programėlėje,
  priklauso atitinkamiems jų savininkams.
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">TAIKYTINA TEISĖ IR JURISDIKCIJA </span><br />
  Vartotojo ir duomenų valdytojo santykius reglamentuos Ispanijos teritorijoje galiojantys teisės
  aktai. Kilus ginčui, &scaron;alys įstatymų nustatyta tvarka kreipiasi į atitinkamus teisėjus ir
  teismus.
</p>
