import { NgModule } from '@angular/core';
import { AngularMaterialModule } from '@shared/angular-material.module';
import { CommonModule } from '@angular/common';
import { CountriesConstants } from '@constants/countries.constants';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectCountryFilterComponent } from './select-country-filter/select-country-filter.component';
import { SelectCountryComponent } from './select-country-form-input/select-country.component';

@NgModule({
  declarations: [SelectCountryComponent, SelectCountryFilterComponent],
  imports: [CommonModule, AngularMaterialModule, TranslateModule, ReactiveFormsModule],
  providers: [CountriesConstants],
  exports: [SelectCountryComponent, SelectCountryFilterComponent],
})
export class SelectCountryModule {}
