import { Expose } from 'class-transformer';
import { BaseEntity } from './base.entity';

export class SensorAlarmEntity extends BaseEntity {
  @Expose()
  public sensorType: number;

  @Expose()
  public minimum: number;

  @Expose()
  public maximum: number;

  @Expose()
  public active: boolean;

  public matchAlarm(value: number): boolean {
    if (!this.active) {
      return false;
    }
    const numberValue = Number(value);
    const hasMinimum = ![undefined, null].includes(this.minimum);
    const hasMaximum = ![undefined, null].includes(this.maximum);

    return (hasMinimum && numberValue < this.minimum) || (hasMaximum && numberValue > this.maximum);
  }
}
