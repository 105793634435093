import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { CompanyEntity } from '@entities/company.entity';
import { BackendService } from '@services/api/backend/backend.service';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { CompanyListEntity } from '@entities/company-list.entity';
import { first, map, share } from 'rxjs/operators';
import { SwitchCompanyAdminResponse } from '@entities/switch-company-admin-response.entity';
import { IBaseUUIDContext } from '@interfaces/base-uuid-context.interface';
import { ICompaniesExportRequestContext } from '@interfaces/all-companies-export-request-context.interface';

@Injectable({
  providedIn: 'root',
})
export class BECompaniesService {
  private company$: BehaviorSubject<CompanyEntity> = new BehaviorSubject(undefined);
  private lastSaved$ = new Subject();

  public companiesList$: BehaviorSubject<CompanyEntity[]> = new BehaviorSubject<CompanyEntity[]>(
    undefined
  );

  constructor(private be: BackendService, private localStorageService: LocalStorageService) {}

  public lastSaved(): Observable<any> {
    return this.lastSaved$.asObservable();
  }

  public lastSavedNext() {
    this.lastSaved$.next();
  }

  public get company(): Observable<CompanyEntity> {
    if (!this.company$.value) {
      this.getCompany();
    }
    return this.company$;
  }

  public getCompany(): void {
    this.be.get('/company', CompanyEntity).subscribe((company) => {
      this.company$.next(company);
    });
  }

  public getCompanyById(id: string): Observable<CompanyEntity> {
    if (this.localStorageService.userIsSuperAdmin) {
      return this.be.get(`/company/${id}`, CompanyEntity).pipe(share());
    }
  }

  public list(): Observable<CompanyEntity[]> {
    return this.localStorageService.userIsSuperAdmin
      ? this.be.get('/companies', CompanyListEntity).pipe(
          first(),
          map((response) => response.companies)
        )
      : new BehaviorSubject<CompanyEntity[]>([]);
  }

  public update(company: CompanyEntity): Observable<CompanyEntity> {
    return this.be.post('/company', company, CompanyEntity).pipe(share());
  }

  public updateControls(company: CompanyEntity): Observable<CompanyEntity> {
    return this.be.post('/company/controls', company, CompanyEntity).pipe(share());
  }

  public updateFlags(company: CompanyEntity): Observable<CompanyEntity> {
    return this.be.post('/company/flags', company, CompanyEntity).pipe(share());
  }

  public switchAdminUser(
    companyId: string,
    admin: IBaseUUIDContext
  ): Observable<SwitchCompanyAdminResponse> {
    if (this.localStorageService.userIsSuperAdmin) {
      return this.be
        .post(`/company/${companyId}/admin`, admin, SwitchCompanyAdminResponse)
        .pipe(share());
    }
    if (this.localStorageService.userIsCompanyAdmin) {
      return this.be.post('/company/admin', admin, SwitchCompanyAdminResponse).pipe(share());
    }
  }

  public delete(companyId: string): Observable<CompanyEntity> {
    return this.be.delete(`/company/${companyId}`, CompanyEntity).pipe(share());
  }

  public export(payload: ICompaniesExportRequestContext): Observable<CompanyEntity[]> {
    if (this.localStorageService.userIsSuperAdmin) {
      return this.be.post('/companies/export', payload, CompanyListEntity).pipe(
        first(),
        map((response) => response.companies)
      );
    }
  }

  // public exportToCSV(filters: CompaniesFiltersNSortingContext): Observable<HttpResponse<Blob>> {
  //   return this.localStorageService.userIsSuperAdmin
  //     ? this.be.postNGetFile('/companies/csv', filters).pipe(share())
  //     : new Observable<HttpResponse<Blob>>();
  // }

  // public exportFullTreeCSV(): Observable<HttpResponse<Blob>> {
  //   return this.localStorageService.userIsSuperAdmin
  //     ? this.be.getFile('/companies/full-tree-csv').pipe(share())
  //     : new Observable<HttpResponse<Blob>>();
  // }
}
