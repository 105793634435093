import { Expose, Type } from 'class-transformer';
import { BaseUUIDEntity } from './base.entity';
import { ProfileUserEntity } from './profile-user-entity';

export class ProfileEntity extends BaseUUIDEntity {
  @Expose()
  public firstname: string;

  @Expose()
  public lastname: string;

  @Expose()
  public position: string;

  @Expose()
  public phone: string;

  @Expose()
  public language: string;

  @Expose()
  @Type(() => ProfileUserEntity)
  public user: ProfileUserEntity;

  public get fullName(): string {
    return (this.firstname || '').concat(' ', this.lastname || '');
  }

  static getFullName(profile: ProfileEntity): string {
    if (profile === undefined) {
      return '';
    }
    return profile.fullName;
  }
}
