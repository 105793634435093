import { Component, Input } from '@angular/core';

/**
 * A simple loading 'spinner' component.
 * Parent element should have a position relative/absolute (not static) and loading spinner
 * position itself in the center of parent element. Use a *ngIf to show hide while loading.
 */
@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {
  /**
   * The color of the loading spinner. primary colour is used by default.
   */
  @Input() color: string;
}
