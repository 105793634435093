import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IEntity } from '@utils/utils';
import { plainToClass } from 'class-transformer';

interface IHttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export class ApiService {
  protected baseUrlApi: string;

  constructor(protected http: HttpClient) {}

  public getFile(endpoint: string) {
    const url = this.baseUrlApi + endpoint;

    return this.http.get<Blob>(url, { observe: 'response', responseType: 'blob' as 'json' });
  }

  public postNGetFile(endpoint: string, body: any) {
    const url = this.baseUrlApi + endpoint;

    return this.http.post<Blob>(url, body, { observe: 'response', responseType: 'blob' as 'json' });
  }

  public get<T>(endpoint: string, responseType?: IEntity<T>, options?: IHttpOptions) {
    const url = this.baseUrlApi + endpoint;

    if (responseType) {
      return this.processResponse(this.http.get<T>(url, options), responseType);
    }

    return this.http.get<T>(url, options);
  }

  public post<T>(endpoint: string, body: any, responseType?: IEntity<T>, options?: IHttpOptions) {
    const url = this.baseUrlApi + endpoint;

    if (responseType) {
      return this.processResponse(this.http.post<T>(url, body, options), responseType);
    }

    return this.http.post<T>(url, body, options);
  }

  public put<T>(endpoint: string, body: any, responseType?: IEntity<T>, options?: IHttpOptions) {
    const url = this.baseUrlApi + endpoint;

    if (responseType) {
      return this.processResponse(this.http.put<T>(url, body, options), responseType);
    }

    return this.http.put<T>(url, body, options);
  }

  public delete<T>(endpoint: string, responseType?: IEntity<T>, options?: IHttpOptions) {
    const url = this.baseUrlApi + endpoint;

    if (responseType) {
      return this.processResponse(this.http.delete<T>(url, options), responseType);
    }

    return this.http.delete<T>(url, options);
  }

  private processResponse<T>(response: Observable<T>, responseType: IEntity<T>) {
    return response.pipe(
      map((data) => plainToClass(responseType, data, { excludeExtraneousValues: true }))
    );
  }
}
