import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {

    @Input() public appAutoFocus: boolean;
    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.el.nativeElement.focus();
        }, 500);
    }
}
