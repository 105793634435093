import { validate as isValidUUID } from 'uuid';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-register-redirection',
  template: '',
})
export class RegisterRedirectionComponent implements OnInit {
  constructor(
    @Inject(Window) private window: Window,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const actionTokenId = this.activatedRoute.snapshot.paramMap.get('id');
    console.log('Entered', this.window.location.pathname);
    if (!actionTokenId || ['register-split', 'register', 'offline'].includes(actionTokenId)) {
      console.log('Redirected to', '/auth/register-split/offline');
      this.router.navigate(['/auth/register-split/offline']);
    } else if (isValidUUID(actionTokenId)) {
      console.log('Redirected to', `/auth/register-split/token/${actionTokenId}`);
      this.router.navigate([`/auth/register-split/token/${actionTokenId}`]);
    } else {
      console.log('app-register-redirection: Giving up!');
      console.log('app-register-redirection: Path:', this.window.location.pathname);
      console.log('app-register-redirection: actionTokenId:', actionTokenId);
    }
  }
}
