import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BaseComponent } from '@shared/components/base.component';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { Subscription } from 'rxjs';
import { MainMenuItemEntity } from '@core/layout/entities/main-menu-item.entity';

@Component({
  selector: 'app-main-menu-item',
  templateUrl: './main-menu-item.component.html',
  styleUrls: ['./main-menu-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
  ],
})
export class MainMenuItemComponent extends BaseComponent implements OnInit, OnDestroy {
  expanded = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: MainMenuItemEntity;
  @Input() depth: number;
  @Input() sideNavOpenState = false;
  private routerSubscription: Subscription;
  public selectedItem = false;

  constructor(public router: Router, public localStorageService: LocalStorageService) {
    super(localStorageService);
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    this.selectedItem = this.router.url === this.item.link;
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.selectedItem = event.url === this.item.link;
      }
    });
  }

  ngOnDestroy() {
    // Unsubscribe from router events to prevent memory leaks
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  onItemSelected(item: any) {
    if (item.link) {
      this.router.navigate([item.link]);
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }

  handleClick(event, item) {
    event.stopPropagation();
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }
}
