import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionTokenKindsConstants } from '@constants/action-tokens.constants';
import { IActionTokenRequestContext } from '@interfaces/action-token-request-context.interface';
import { BEActionTokensService } from '@services/backend-services/action-tokens/be-action-tokens.service';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { BaseComponent } from '@shared/components/base.component';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-action',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit {
  public waiting = false;

  public forgotPasswdForm: FormGroup = new FormGroup({
    kind: new FormControl(ActionTokenKindsConstants.CHANGEPASSWORD.value),
    domain: new FormControl(''),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
  });

  constructor(
    @Inject(Window) private window: Window,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translationMWService: TranslationMWService,
    public localStorageService: LocalStorageService,
    private beActionTokensService: BEActionTokensService
  ) {
    super(localStorageService);

    const currentDomain =
      this.window.location.hostname +
      (this.window.location.port.length ? ':'.concat(this.window.location.port) : '');
    this.forgotPasswdForm.controls.domain.setValue(currentDomain);
  }

  ngOnInit() {
    const emailPrm = this.route.snapshot.paramMap.get('email');
    if (emailPrm) {
      this.forgotPasswdForm.controls.email.setValue(emailPrm);
    }
  }

  onCancelClicked() {
    // navigate to login page
    void this.router.navigate(['/auth/login']);
  }

  requestAction() {
    if (this.forgotPasswdForm.valid) {
      this.waiting = true;
      this.beActionTokensService
        .request({
          ...this.forgotPasswdForm.value,
          language: this.currentLanguage,
        } as IActionTokenRequestContext)
        .pipe(first())
        .subscribe(
          (response) => {
            this.waiting = false;
            if (response.emailResult) {
              // show account's email confirmation instructions
              this.toastr.info(
                this.translationMWService.instant('forgot-password.toastr.instructions.message', {
                  email: this.forgotPasswdForm.controls.email.value,
                }),
                this.translationMWService.instant('forgot-password.toastr.instructions.title'),
                {
                  disableTimeOut: true,
                  closeButton: true,
                }
              );
              // navigate to main site
              /* void this.router.navigate([this.localStorageService.userRolesMainPage]); */
              this.router.navigate(['/auth/login']);
            } else {
              this.toastr.error(
                this.translationMWService.instant(
                  'forgot-password.toastr.action-token-email.error.message',
                  {
                    email: this.forgotPasswdForm.controls.email.value,
                  }
                ),
                this.translationMWService.instant(
                  'forgot-password.toastr.action-token-email.error.title'
                )
              );
            }
          },
          () => {
            this.waiting = false;
            this.toastr.error(
              this.translationMWService.instant(
                'forgot-password.toastr.action-token-email.error.message',
                {
                  email: this.forgotPasswdForm.controls.email.value,
                }
              ),
              this.translationMWService.instant(
                'forgot-password.toastr.action-token-email.error.title'
              )
            );
          }
        );
    }
  }
}
