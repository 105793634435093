import { Expose } from 'class-transformer';

export class BreadcrumbItemEntity {
  @Expose()
  public link: string;

  @Expose()
  public name: string;

  @Expose()
  public translationLabel: string;

  public get translationPath(): string {
    return this.translationLabel ? `breadcrumbs.${this.translationLabel}` : this.name;
  }
}
