import { Expose } from 'class-transformer';
import { BaseEntity } from './base.entity';


export class ProfileUserEntity extends BaseEntity {

  @Expose()
  public email: string;

  @Expose()
  public role: number;

}
