<div class="mat-form-field-container">
  <span *ngIf="formStyle" class="fake-label m3-label-large">{{
    'register.form.countryPhonePrefix' | translate
  }}</span>
  <mat-form-field>
    <mat-label>{{ 'register.form.countryPhonePrefix' | translate }}</mat-label>
    <span matPrefix *ngIf="this.countryFormControl.value">+{{this.countryFormControl.value.phonePrefix}}</span>
    <input
      type="text"
      trim="blur"
      #input
      matInput
      [formControl]="countryFormControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      (optionSelected)="optionSelected()"
    >
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
       +{{option.phonePrefix}} {{ option.translationPath | translate }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="countryFormControl.hasError('required')">
      {{ 'form.error.fieldRequired' | translate }}
    </mat-error>
  </mat-form-field>
</div>
