import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ChangePasswordConfirmInvitationComponent } from './pages/action-id/change-password-confirm-invitation.component';
import { RegisterRedirectionComponent } from '@modules/register/components/root-path/register-redirection.component';

export const authRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  { path: 'auth/register', redirectTo: 'auth/register-split/offline', pathMatch: 'full' },
  { path: 'auth/register-split', redirectTo: 'auth/register-split/offline', pathMatch: 'full' },
  {
    path: 'auth/register/:id',
    pathMatch: 'full',
    component: RegisterRedirectionComponent,
  },
  {
    path: 'register-split',
    loadChildren: () => import('../register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    children: [
      {
        // path => '/forgot-password/'
        path: '',
        component: ForgotPasswordComponent,
      },
      {
        // path => '/forgot-password/:email'
        path: ':email',
        component: ForgotPasswordComponent,
      },
    ],
  },
  //Route to CHANGEPASSWORD and PASSWDCONFIRMINVITATION
  {
    path: 'reset-password/:id',
    component: ChangePasswordConfirmInvitationComponent,
  },
];
