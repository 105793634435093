import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-progressbar',
  templateUrl: './custom-progressbar.component.html',
  styleUrls: ['./custom-progressbar.component.scss'],
})
export class CustomProgressBarComponent implements OnInit {
  @Input()
  progressValue = 0;
  @Input()
  minValue = 0;
  @Input()
  maxValue = 100;
  @Input()
  showStatus = false;
  @Input()
  loadingTitle = 'Loading';

  constructor() {}

  ngOnInit(): void {}
}
