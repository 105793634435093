export const NODE_POSITIONS_COLORS: string[] = [
  '#DD0907',
  '#0000D3',
  '#FBF305',
  '#1FB714',
  '#808080',
  '#562C05',
  '#000000',
  '#4700A5',
  '#FF6403',
  '#E03A3E',
  '#00A6EE',
  '#FDB827',
  '#61BB46',
  '#C0C0C0',
  '#90713A',
  '#963D97',
  '#F5821F',
  '#F20884',
  '#00FFFF',
  '#CCFF00',
  '#00FF00',
  '#979AAA',
  '#E3A857',
  '#DF00FF',
  '#FF3800',
  '#A40000',
  '#000080',
  '#E3A857',
  '#00693E',
  '#333333',
  '#6C541E',
  '#FF69B4',
  '#2D3E50',
];
