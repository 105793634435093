import { NgModule } from '@angular/core';
import { RandomPipe } from './random/random.pipe';
import { SecondsToHHMMSSPipe } from './s2hhmmss/s2hhmmss.pipe';
import { SpaceToLineBreakPipe } from './sp2br/sp2br.pipe';


@NgModule({
  declarations: [
    RandomPipe,
    SpaceToLineBreakPipe,
    SecondsToHHMMSSPipe
  ],
  exports: [
    RandomPipe,
    SpaceToLineBreakPipe,
    SecondsToHHMMSSPipe
  ]
})
export class CustomPipesModule {}
