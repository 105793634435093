import { Injectable } from '@angular/core';
import { UserEntity } from '../../../entities/user.entity';
import { Router } from '@angular/router';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { BehaviorSubject } from 'rxjs';
import { ILoginContext } from '@interfaces/login-context.interface';
import { IBaseRegisterContext } from '@interfaces/base-register-context.interface';
import { AuthRegisterResponse } from '@entities/auth-register-response.entity';
import { AuthLoginResponse } from '@entities/auth-login-response.entity';
import { BackendService } from '@services/api/backend/backend.service';
import { ConstantEntity } from '@constants/base.constants';
import { CompanyRegisterContext } from '@interfaces/company-register-context.interface';
import {
  DemoUserRegisterContext,
  UserRegisterContext,
} from '@interfaces/user-register-context.interface';
import { DataProvidersConstants } from '@constants/data-providers.constants';
import { MatchResponseEntity } from '@entities/match-response.entity';
import { AppConfig } from '@config/app.config';

@Injectable({
  providedIn: 'root',
})
export class BEAuthService {
  private lastRegisteredSubject$ = new BehaviorSubject<UserEntity>(undefined);

  public lastRegistered = this.lastRegisteredSubject$.asObservable();

  constructor(
    private be: BackendService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  login(loginContext: ILoginContext) {
    return this.be.post('/auth/login', loginContext, AuthLoginResponse);
  }

  registerUser(role: ConstantEntity, registerContext: UserRegisterContext) {
    return this.be.post(`/${role.lowerCaseName}/register`, registerContext, AuthRegisterResponse);
  }

  registerCompanyEmployee(companyId: string, registerContext: UserRegisterContext) {
    if (this.localStorageService.userIsSuperAdmin) {
      return this.be.post(
        `/company/${companyId}/employee/register`,
        registerContext,
        AuthRegisterResponse
      );
    }
  }

  registerDemoUser(registerContext: DemoUserRegisterContext) {
    if (this.localStorageService.userIsAdmin) {
      return this.be.post(
        `/company/${AppConfig.DemoUsersCompanyUUID}/demo/register`,
        registerContext,
        AuthRegisterResponse
      );
    }
  }

  registerCompany(registerContext: CompanyRegisterContext, dataProvider: number) {
    const platformPrefix =
      dataProvider === DataProvidersConstants.CTCLITE.numberValue ? 'lite' : 'plus';
    return this.be.post(
      `/auth/register/${platformPrefix}/company`,
      registerContext,
      AuthRegisterResponse
    );
  }

  changePassword(baseRegisterContext: IBaseRegisterContext) {
    return this.be.post('/auth/password', baseRegisterContext, UserEntity);
  }

  confirmPassword(baseRegisterContext: IBaseRegisterContext) {
    return this.be.post('/auth/confirm/password', baseRegisterContext, MatchResponseEntity);
  }

  public lastRegisteredNext(user: UserEntity) {
    return this.lastRegisteredSubject$.next(user);
  }

  logout(): void {
    this.localStorageService.clear();
    void this.router.navigate(['/auth/login']);
  }

  test() {
    return this.be.get('', null);
  }
}
