import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BEAuthService } from '@services/backend-services/auth/be-auth.service';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { ILoginContext } from '@interfaces/login-context.interface';
import { AuthLoginResponse } from '@entities/auth-login-response.entity';
import { errorLoginUser } from '@utils/users';

// export interface LoginDialogInjectedData {}

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent {
  public hidePassword = true;
  public loginForm: FormGroup;

  constructor(
    public dialog: MatDialogRef<LoginDialogComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: LoginDialogInjectedData
    private beAuthService: BEAuthService,
    private toastr: ToastrService,
    private translationMWService: TranslationMWService
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl(undefined, [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
      password: new FormControl(undefined, [Validators.required]),
    });
  }

  deny(): void {
    this.dialog.close(undefined);
  }

  private confirmLoggedIn(response: AuthLoginResponse): void {
    this.dialog.close(response);
  }

  public tryLogin() {
    if (this.loginForm.valid) {
      this.beAuthService
        .login(this.loginForm.value as ILoginContext)
        .pipe(first())
        .subscribe(
          (response) => {
            this.confirmLoggedIn(response);
          },
          (error) => {
            errorLoginUser(error, this.toastr, this.translationMWService);

            this.loginForm.controls.password.setErrors({ [error.error]: true });
          }
        );
    }
  }
}
