import { ConstantEntity, IConstantEntity } from './base.constants';

export interface IUserRoleConstantEntity extends IConstantEntity {
  defaultMainPage: string;
}

export class UserRoleConstantEntity extends ConstantEntity implements IUserRoleConstantEntity {
  readonly defaultMainPage: string;

  constructor(obj: IUserRoleConstantEntity) {
    super(obj);
    this.defaultMainPage = obj.defaultMainPage;
  }
}
