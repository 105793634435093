import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MockInterface } from '@mocks/base';

// if mock is in list, it will be active
const mocksList: MockInterface[] = [];

@Injectable()
export class MockInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    for (const element of mocksList) {
      if (request.url === element.url) {
        if (!element.validator || element.validator(request.body)) {
          console.log('Using mock! ', element.json.default);
          return of(new HttpResponse({ status: 200, body: element.json.default }));
        }
        this.toastr.error(element.error);
        console.log('Using mock! Error');
        throw new HttpErrorResponse({ status: 400, error: element.error });
      }
    }
    return next.handle(request);
  }
}
