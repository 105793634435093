<div class="mat-form-field-container">
  <span *ngIf="!hideLabel" class="fake-label m3-label-large">{{ controlLabel | translate }}</span>
  <mat-form-field class="input-full-width">
    <mat-label *ngIf="!hideLabel"></mat-label>
    <input
      type="text"
      trim="blur"
      matInput
      [formControl]="countryFormControl"
      [matAutocomplete]="auto"
      placeholder="{{ controlLabel | translate }}"
    />
    <div matSuffix *ngIf="clearButton">
      <span (click)="clearFilter()" class="cursor-pointer material-symbols-outlined"> cancel </span>
    </div>
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      (optionSelected)="optionSelected()"
    >
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{ option.translationPath | translate }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="countryFormControl.hasError('required')">
      {{ 'form.error.fieldRequired' | translate }}
    </mat-error>
  </mat-form-field>
</div>
