<div class="container">
  <div class="auth-title-container">
    <div class="auth-title head-line-3">{{ 'auth.login.forgotPassword' | translate }}</div>
    <div class="auth-subtitle m3-title-small">
      {{ 'action.forgot-password.header' | translate }}
    </div>
  </div>
  <form [formGroup]="forgotPasswdForm" #f="ngForm" (ngSubmit)="requestAction()">
    <div class="mat-form-field-container inputs">
      <span class="fake-label m3-label-large">{{ 'action.form.email' | translate }}</span>
      <mat-form-field class="input-full-width">
        <mat-label>{{ 'auth.login.emailPlaceholder' | translate }}</mat-label>
        <input
          matInput
          formControlName="email"
          trim="blur"
          type="email"
          placeholder="{{ 'auth.login.emailPlaceholder' | translate }}"
          appAutofocus
        />
        <mat-error *ngIf="forgotPasswdForm.controls.email.hasError('required')">
          {{ 'form.error.fieldRequired' | translate }}
        </mat-error>
        <mat-error
          *ngIf="
            forgotPasswdForm.controls.email.hasError('email') ||
            forgotPasswdForm.controls.email.hasError('pattern')
          "
        >
          {{ 'form.error.typeEmail' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="buttons-list d-flex justify-content-between">
      <button
        type="button"
        mat-button
        (click)="onCancelClicked()"
        class="cancel font-button button-outline"
        color="primary"
        mat-stroked-button
      >
        <span>{{ 'buttons.cancel' | translate }}</span>
      </button>
      <button type="submit" color="primary" mat-flat-button class="font-button button-primary">
        <span>{{ 'action.form.button.request' | translate }}</span>
      </button>
    </div>
  </form>
</div>
