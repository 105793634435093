import { Expose } from 'class-transformer';
import { UserEntity } from './user.entity';

export class SwitchCompanyAdminResponse {
  @Expose()
  public oldAdmin: UserEntity;

  @Expose()
  public newAdmin: UserEntity;
}
