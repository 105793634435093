import { ConstantGroup } from './base.constants';
import { CountryConstantEntity } from './country-constant.entity';

const ABW = new CountryConstantEntity({
  value: 533,
  name: 'ABW',
  translationTag: 'country',
  phonePrefix: '297',
});
const AFG = new CountryConstantEntity({
  value: 4,
  name: 'AFG',
  translationTag: 'country',
  phonePrefix: '93',
});
const AGO = new CountryConstantEntity({
  value: 24,
  name: 'AGO',
  translationTag: 'country',
  phonePrefix: '244',
});
const AIA = new CountryConstantEntity({
  value: 660,
  name: 'AIA',
  translationTag: 'country',
  phonePrefix: '1-264',
});
const ALA = new CountryConstantEntity({
  value: 248,
  name: 'ALA',
  translationTag: 'country',
  phonePrefix: '358',
});
const ALB = new CountryConstantEntity({
  value: 8,
  name: 'ALB',
  translationTag: 'country',
  phonePrefix: '355',
});
const AND = new CountryConstantEntity({
  value: 20,
  name: 'AND',
  translationTag: 'country',
  phonePrefix: '376',
});
const ARE = new CountryConstantEntity({
  value: 784,
  name: 'ARE',
  translationTag: 'country',
  phonePrefix: '971',
});
const ARG = new CountryConstantEntity({
  value: 32,
  name: 'ARG',
  translationTag: 'country',
  phonePrefix: '54',
});
const ARM = new CountryConstantEntity({
  value: 51,
  name: 'ARM',
  translationTag: 'country',
  phonePrefix: '374',
});
const ASM = new CountryConstantEntity({
  value: 16,
  name: 'ASM',
  translationTag: 'country',
  phonePrefix: '1-684',
});
const ATA = new CountryConstantEntity({
  value: 10,
  name: 'ATA',
  translationTag: 'country',
  phonePrefix: '672',
});
const ATF = new CountryConstantEntity({
  value: 260,
  name: 'ATF',
  translationTag: 'country',
  phonePrefix: '262',
});
const ATG = new CountryConstantEntity({
  value: 28,
  name: 'ATG',
  translationTag: 'country',
  phonePrefix: '1-268',
});
const AUS = new CountryConstantEntity({
  value: 36,
  name: 'AUS',
  translationTag: 'country',
  phonePrefix: '61',
});
const AUT = new CountryConstantEntity({
  value: 40,
  name: 'AUT',
  translationTag: 'country',
  phonePrefix: '43',
});
const AZE = new CountryConstantEntity({
  value: 31,
  name: 'AZE',
  translationTag: 'country',
  phonePrefix: '994',
});
const BDI = new CountryConstantEntity({
  value: 108,
  name: 'BDI',
  translationTag: 'country',
  phonePrefix: '257',
});
const BEL = new CountryConstantEntity({
  value: 56,
  name: 'BEL',
  translationTag: 'country',
  phonePrefix: '32',
});
const BEN = new CountryConstantEntity({
  value: 204,
  name: 'BEN',
  translationTag: 'country',
  phonePrefix: '229',
});
const BES = new CountryConstantEntity({
  value: 535,
  name: 'BES',
  translationTag: 'country',
  phonePrefix: '599',
});
const BFA = new CountryConstantEntity({
  value: 854,
  name: 'BFA',
  translationTag: 'country',
  phonePrefix: '226',
});
const BGD = new CountryConstantEntity({
  value: 50,
  name: 'BGD',
  translationTag: 'country',
  phonePrefix: '880',
});
const BGR = new CountryConstantEntity({
  value: 100,
  name: 'BGR',
  translationTag: 'country',
  phonePrefix: '359',
});
const BHR = new CountryConstantEntity({
  value: 48,
  name: 'BHR',
  translationTag: 'country',
  phonePrefix: '973',
});
const BHS = new CountryConstantEntity({
  value: 44,
  name: 'BHS',
  translationTag: 'country',
  phonePrefix: '1-242',
});
const BIH = new CountryConstantEntity({
  value: 70,
  name: 'BIH',
  translationTag: 'country',
  phonePrefix: '387',
});
const BLM = new CountryConstantEntity({
  value: 652,
  name: 'BLM',
  translationTag: 'country',
  phonePrefix: '590',
});
const BLR = new CountryConstantEntity({
  value: 112,
  name: 'BLR',
  translationTag: 'country',
  phonePrefix: '375',
});
const BLZ = new CountryConstantEntity({
  value: 84,
  name: 'BLZ',
  translationTag: 'country',
  phonePrefix: '501',
});
const BMU = new CountryConstantEntity({
  value: 60,
  name: 'BMU',
  translationTag: 'country',
  phonePrefix: '1-441',
});
const BOL = new CountryConstantEntity({
  value: 68,
  name: 'BOL',
  translationTag: 'country',
  phonePrefix: '591',
});
const BRA = new CountryConstantEntity({
  value: 76,
  name: 'BRA',
  translationTag: 'country',
  phonePrefix: '55',
});
const BRB = new CountryConstantEntity({
  value: 52,
  name: 'BRB',
  translationTag: 'country',
  phonePrefix: '1-246',
});
const BRN = new CountryConstantEntity({
  value: 96,
  name: 'BRN',
  translationTag: 'country',
  phonePrefix: '673',
});
const BTN = new CountryConstantEntity({
  value: 64,
  name: 'BTN',
  translationTag: 'country',
  phonePrefix: '975',
});
const BVT = new CountryConstantEntity({
  value: 74,
  name: 'BVT',
  translationTag: 'country',
  phonePrefix: '47',
});
const BWA = new CountryConstantEntity({
  value: 72,
  name: 'BWA',
  translationTag: 'country',
  phonePrefix: '267',
});
const CAF = new CountryConstantEntity({
  value: 140,
  name: 'CAF',
  translationTag: 'country',
  phonePrefix: '236',
});
const CAN = new CountryConstantEntity({
  value: 124,
  name: 'CAN',
  translationTag: 'country',
  phonePrefix: '1',
});
const CCK = new CountryConstantEntity({
  value: 166,
  name: 'CCK',
  translationTag: 'country',
  phonePrefix: '61',
});
const CHE = new CountryConstantEntity({
  value: 756,
  name: 'CHE',
  translationTag: 'country',
  phonePrefix: '41',
});
const CHL = new CountryConstantEntity({
  value: 152,
  name: 'CHL',
  translationTag: 'country',
  phonePrefix: '56',
});
const CHN = new CountryConstantEntity({
  value: 156,
  name: 'CHN',
  translationTag: 'country',
  phonePrefix: '86',
});
const CIV = new CountryConstantEntity({
  value: 384,
  name: 'CIV',
  translationTag: 'country',
  phonePrefix: '225',
});
const CMR = new CountryConstantEntity({
  value: 120,
  name: 'CMR',
  translationTag: 'country',
  phonePrefix: '237',
});
const COD = new CountryConstantEntity({
  value: 180,
  name: 'COD',
  translationTag: 'country',
  phonePrefix: '243',
});
const COG = new CountryConstantEntity({
  value: 178,
  name: 'COG',
  translationTag: 'country',
  phonePrefix: '242',
});
const COK = new CountryConstantEntity({
  value: 184,
  name: 'COK',
  translationTag: 'country',
  phonePrefix: '682',
});
const COL = new CountryConstantEntity({
  value: 170,
  name: 'COL',
  translationTag: 'country',
  phonePrefix: '57',
});
const COM = new CountryConstantEntity({
  value: 174,
  name: 'COM',
  translationTag: 'country',
  phonePrefix: '269',
});
const CPV = new CountryConstantEntity({
  value: 132,
  name: 'CPV',
  translationTag: 'country',
  phonePrefix: '238',
});
const CRI = new CountryConstantEntity({
  value: 188,
  name: 'CRI',
  translationTag: 'country',
  phonePrefix: '506',
});
const CUB = new CountryConstantEntity({
  value: 192,
  name: 'CUB',
  translationTag: 'country',
  phonePrefix: '53',
});
const CUW = new CountryConstantEntity({
  value: 531,
  name: 'CUW',
  translationTag: 'country',
  phonePrefix: '599',
});
const CXR = new CountryConstantEntity({
  value: 162,
  name: 'CXR',
  translationTag: 'country',
  phonePrefix: '61',
});
const CYM = new CountryConstantEntity({
  value: 136,
  name: 'CYM',
  translationTag: 'country',
  phonePrefix: '1-345',
});
const CYP = new CountryConstantEntity({
  value: 196,
  name: 'CYP',
  translationTag: 'country',
  phonePrefix: '357',
});
const CZE = new CountryConstantEntity({
  value: 203,
  name: 'CZE',
  translationTag: 'country',
  phonePrefix: '420',
});
const DEU = new CountryConstantEntity({
  value: 276,
  name: 'DEU',
  translationTag: 'country',
  phonePrefix: '49',
});
const DJI = new CountryConstantEntity({
  value: 262,
  name: 'DJI',
  translationTag: 'country',
  phonePrefix: '253',
});
const DMA = new CountryConstantEntity({
  value: 212,
  name: 'DMA',
  translationTag: 'country',
  phonePrefix: '1-767',
});
const DNK = new CountryConstantEntity({
  value: 208,
  name: 'DNK',
  translationTag: 'country',
  phonePrefix: '45',
});
const DOM = new CountryConstantEntity({
  value: 214,
  name: 'DOM',
  translationTag: 'country',
  phonePrefix: '1-809,1-829,1-849',
});
const DZA = new CountryConstantEntity({
  value: 12,
  name: 'DZA',
  translationTag: 'country',
  phonePrefix: '213',
});
const ECU = new CountryConstantEntity({
  value: 218,
  name: 'ECU',
  translationTag: 'country',
  phonePrefix: '593',
});
const EGY = new CountryConstantEntity({
  value: 818,
  name: 'EGY',
  translationTag: 'country',
  phonePrefix: '20',
});
const ERI = new CountryConstantEntity({
  value: 232,
  name: 'ERI',
  translationTag: 'country',
  phonePrefix: '291',
});
const ESH = new CountryConstantEntity({
  value: 732,
  name: 'ESH',
  translationTag: 'country',
  phonePrefix: '212',
});
const ESP = new CountryConstantEntity({
  value: 724,
  name: 'ESP',
  translationTag: 'country',
  phonePrefix: '34',
});
const EST = new CountryConstantEntity({
  value: 233,
  name: 'EST',
  translationTag: 'country',
  phonePrefix: '372',
});
const ETH = new CountryConstantEntity({
  value: 231,
  name: 'ETH',
  translationTag: 'country',
  phonePrefix: '251',
});
const FIN = new CountryConstantEntity({
  value: 246,
  name: 'FIN',
  translationTag: 'country',
  phonePrefix: '358',
});
const FJI = new CountryConstantEntity({
  value: 242,
  name: 'FJI',
  translationTag: 'country',
  phonePrefix: '679',
});
const FLK = new CountryConstantEntity({
  value: 238,
  name: 'FLK',
  translationTag: 'country',
  phonePrefix: '500',
});
const FRA = new CountryConstantEntity({
  value: 250,
  name: 'FRA',
  translationTag: 'country',
  phonePrefix: '33',
});
const FRO = new CountryConstantEntity({
  value: 234,
  name: 'FRO',
  translationTag: 'country',
  phonePrefix: '298',
});
const FSM = new CountryConstantEntity({
  value: 583,
  name: 'FSM',
  translationTag: 'country',
  phonePrefix: '691',
});
const GAB = new CountryConstantEntity({
  value: 266,
  name: 'GAB',
  translationTag: 'country',
  phonePrefix: '241',
});
const GBR = new CountryConstantEntity({
  value: 826,
  name: 'GBR',
  translationTag: 'country',
  phonePrefix: '44',
});
const GEO = new CountryConstantEntity({
  value: 268,
  name: 'GEO',
  translationTag: 'country',
  phonePrefix: '995',
});
const GGY = new CountryConstantEntity({
  value: 831,
  name: 'GGY',
  translationTag: 'country',
  phonePrefix: '44',
});
const GHA = new CountryConstantEntity({
  value: 288,
  name: 'GHA',
  translationTag: 'country',
  phonePrefix: '233',
});
const GIB = new CountryConstantEntity({
  value: 292,
  name: 'GIB',
  translationTag: 'country',
  phonePrefix: '350',
});
const GIN = new CountryConstantEntity({
  value: 324,
  name: 'GIN',
  translationTag: 'country',
  phonePrefix: '224',
});
const GLP = new CountryConstantEntity({
  value: 312,
  name: 'GLP',
  translationTag: 'country',
  phonePrefix: '590',
});
const GMB = new CountryConstantEntity({
  value: 270,
  name: 'GMB',
  translationTag: 'country',
  phonePrefix: '220',
});
const GNB = new CountryConstantEntity({
  value: 624,
  name: 'GNB',
  translationTag: 'country',
  phonePrefix: '245',
});
const GNQ = new CountryConstantEntity({
  value: 226,
  name: 'GNQ',
  translationTag: 'country',
  phonePrefix: '240',
});
const GRC = new CountryConstantEntity({
  value: 300,
  name: 'GRC',
  translationTag: 'country',
  phonePrefix: '30',
});
const GRD = new CountryConstantEntity({
  value: 308,
  name: 'GRD',
  translationTag: 'country',
  phonePrefix: '1-473',
});
const GRL = new CountryConstantEntity({
  value: 304,
  name: 'GRL',
  translationTag: 'country',
  phonePrefix: '299',
});
const GTM = new CountryConstantEntity({
  value: 320,
  name: 'GTM',
  translationTag: 'country',
  phonePrefix: '502',
});
const GUF = new CountryConstantEntity({
  value: 254,
  name: 'GUF',
  translationTag: 'country',
  phonePrefix: '594',
});
const GUM = new CountryConstantEntity({
  value: 316,
  name: 'GUM',
  translationTag: 'country',
  phonePrefix: '1-671',
});
const GUY = new CountryConstantEntity({
  value: 328,
  name: 'GUY',
  translationTag: 'country',
  phonePrefix: '592',
});
const HKG = new CountryConstantEntity({
  value: 344,
  name: 'HKG',
  translationTag: 'country',
  phonePrefix: '852',
});
const HMD = new CountryConstantEntity({
  value: 334,
  name: 'HMD',
  translationTag: 'country',
  phonePrefix: '672',
});
const HND = new CountryConstantEntity({
  value: 340,
  name: 'HND',
  translationTag: 'country',
  phonePrefix: '504',
});
const HRV = new CountryConstantEntity({
  value: 191,
  name: 'HRV',
  translationTag: 'country',
  phonePrefix: '385',
});
const HTI = new CountryConstantEntity({
  value: 332,
  name: 'HTI',
  translationTag: 'country',
  phonePrefix: '509',
});
const HUN = new CountryConstantEntity({
  value: 348,
  name: 'HUN',
  translationTag: 'country',
  phonePrefix: '36',
});
const IDN = new CountryConstantEntity({
  value: 360,
  name: 'IDN',
  translationTag: 'country',
  phonePrefix: '62',
});
const IMN = new CountryConstantEntity({
  value: 833,
  name: 'IMN',
  translationTag: 'country',
  phonePrefix: '44',
});
const IND = new CountryConstantEntity({
  value: 356,
  name: 'IND',
  translationTag: 'country',
  phonePrefix: '91',
});
const IOT = new CountryConstantEntity({
  value: 86,
  name: 'IOT',
  translationTag: 'country',
  phonePrefix: '246',
});
const IRL = new CountryConstantEntity({
  value: 372,
  name: 'IRL',
  translationTag: 'country',
  phonePrefix: '353',
});
const IRN = new CountryConstantEntity({
  value: 364,
  name: 'IRN',
  translationTag: 'country',
  phonePrefix: '98',
});
const IRQ = new CountryConstantEntity({
  value: 368,
  name: 'IRQ',
  translationTag: 'country',
  phonePrefix: '964',
});
const ISL = new CountryConstantEntity({
  value: 352,
  name: 'ISL',
  translationTag: 'country',
  phonePrefix: '354',
});
const ISR = new CountryConstantEntity({
  value: 376,
  name: 'ISR',
  translationTag: 'country',
  phonePrefix: '972',
});
const ITA = new CountryConstantEntity({
  value: 380,
  name: 'ITA',
  translationTag: 'country',
  phonePrefix: '39',
});
const JAM = new CountryConstantEntity({
  value: 388,
  name: 'JAM',
  translationTag: 'country',
  phonePrefix: '1-876',
});
const JEY = new CountryConstantEntity({
  value: 832,
  name: 'JEY',
  translationTag: 'country',
  phonePrefix: '44',
});
const JOR = new CountryConstantEntity({
  value: 400,
  name: 'JOR',
  translationTag: 'country',
  phonePrefix: '962',
});
const JPN = new CountryConstantEntity({
  value: 392,
  name: 'JPN',
  translationTag: 'country',
  phonePrefix: '81',
});
const KAZ = new CountryConstantEntity({
  value: 398,
  name: 'KAZ',
  translationTag: 'country',
  phonePrefix: '7',
});
const KEN = new CountryConstantEntity({
  value: 404,
  name: 'KEN',
  translationTag: 'country',
  phonePrefix: '254',
});
const KGZ = new CountryConstantEntity({
  value: 417,
  name: 'KGZ',
  translationTag: 'country',
  phonePrefix: '996',
});
const KHM = new CountryConstantEntity({
  value: 116,
  name: 'KHM',
  translationTag: 'country',
  phonePrefix: '855',
});
const KIR = new CountryConstantEntity({
  value: 296,
  name: 'KIR',
  translationTag: 'country',
  phonePrefix: '686',
});
const KNA = new CountryConstantEntity({
  value: 659,
  name: 'KNA',
  translationTag: 'country',
  phonePrefix: '1-869',
});
const KOR = new CountryConstantEntity({
  value: 410,
  name: 'KOR',
  translationTag: 'country',
  phonePrefix: '82',
});
const KWT = new CountryConstantEntity({
  value: 414,
  name: 'KWT',
  translationTag: 'country',
  phonePrefix: '965',
});
const LAO = new CountryConstantEntity({
  value: 418,
  name: 'LAO',
  translationTag: 'country',
  phonePrefix: '856',
});
const LBN = new CountryConstantEntity({
  value: 422,
  name: 'LBN',
  translationTag: 'country',
  phonePrefix: '961',
});
const LBR = new CountryConstantEntity({
  value: 430,
  name: 'LBR',
  translationTag: 'country',
  phonePrefix: '231',
});
const LBY = new CountryConstantEntity({
  value: 434,
  name: 'LBY',
  translationTag: 'country',
  phonePrefix: '218',
});
const LCA = new CountryConstantEntity({
  value: 662,
  name: 'LCA',
  translationTag: 'country',
  phonePrefix: '1-758',
});
const LIE = new CountryConstantEntity({
  value: 438,
  name: 'LIE',
  translationTag: 'country',
  phonePrefix: '423',
});
const LKA = new CountryConstantEntity({
  value: 144,
  name: 'LKA',
  translationTag: 'country',
  phonePrefix: '94',
});
const LSO = new CountryConstantEntity({
  value: 426,
  name: 'LSO',
  translationTag: 'country',
  phonePrefix: '266',
});
const LTU = new CountryConstantEntity({
  value: 440,
  name: 'LTU',
  translationTag: 'country',
  phonePrefix: '370',
});
const LUX = new CountryConstantEntity({
  value: 442,
  name: 'LUX',
  translationTag: 'country',
  phonePrefix: '352',
});
const LVA = new CountryConstantEntity({
  value: 428,
  name: 'LVA',
  translationTag: 'country',
  phonePrefix: '371',
});
const MAC = new CountryConstantEntity({
  value: 446,
  name: 'MAC',
  translationTag: 'country',
  phonePrefix: '853',
});
const MAF = new CountryConstantEntity({
  value: 663,
  name: 'MAF',
  translationTag: 'country',
  phonePrefix: '590',
});
const MAR = new CountryConstantEntity({
  value: 504,
  name: 'MAR',
  translationTag: 'country',
  phonePrefix: '212',
});
const MCO = new CountryConstantEntity({
  value: 492,
  name: 'MCO',
  translationTag: 'country',
  phonePrefix: '377',
});
const MDA = new CountryConstantEntity({
  value: 498,
  name: 'MDA',
  translationTag: 'country',
  phonePrefix: '373',
});
const MDG = new CountryConstantEntity({
  value: 450,
  name: 'MDG',
  translationTag: 'country',
  phonePrefix: '261',
});
const MDV = new CountryConstantEntity({
  value: 462,
  name: 'MDV',
  translationTag: 'country',
  phonePrefix: '960',
});
const MEX = new CountryConstantEntity({
  value: 484,
  name: 'MEX',
  translationTag: 'country',
  phonePrefix: '52',
});
const MHL = new CountryConstantEntity({
  value: 584,
  name: 'MHL',
  translationTag: 'country',
  phonePrefix: '692',
});
const MKD = new CountryConstantEntity({
  value: 807,
  name: 'MKD',
  translationTag: 'country',
  phonePrefix: '389',
});
const MLI = new CountryConstantEntity({
  value: 466,
  name: 'MLI',
  translationTag: 'country',
  phonePrefix: '223',
});
const MLT = new CountryConstantEntity({
  value: 470,
  name: 'MLT',
  translationTag: 'country',
  phonePrefix: '356',
});
const MMR = new CountryConstantEntity({
  value: 104,
  name: 'MMR',
  translationTag: 'country',
  phonePrefix: '95',
});
const MNE = new CountryConstantEntity({
  value: 499,
  name: 'MNE',
  translationTag: 'country',
  phonePrefix: '382',
});
const MNG = new CountryConstantEntity({
  value: 496,
  name: 'MNG',
  translationTag: 'country',
  phonePrefix: '976',
});
const MNP = new CountryConstantEntity({
  value: 580,
  name: 'MNP',
  translationTag: 'country',
  phonePrefix: '1-670',
});
const MOZ = new CountryConstantEntity({
  value: 508,
  name: 'MOZ',
  translationTag: 'country',
  phonePrefix: '258',
});
const MRT = new CountryConstantEntity({
  value: 478,
  name: 'MRT',
  translationTag: 'country',
  phonePrefix: '222',
});
const MSR = new CountryConstantEntity({
  value: 500,
  name: 'MSR',
  translationTag: 'country',
  phonePrefix: '1-664',
});
const MTQ = new CountryConstantEntity({
  value: 474,
  name: 'MTQ',
  translationTag: 'country',
  phonePrefix: '596',
});
const MUS = new CountryConstantEntity({
  value: 480,
  name: 'MUS',
  translationTag: 'country',
  phonePrefix: '230',
});
const MWI = new CountryConstantEntity({
  value: 454,
  name: 'MWI',
  translationTag: 'country',
  phonePrefix: '265',
});
const MYS = new CountryConstantEntity({
  value: 458,
  name: 'MYS',
  translationTag: 'country',
  phonePrefix: '60',
});
const MYT = new CountryConstantEntity({
  value: 175,
  name: 'MYT',
  translationTag: 'country',
  phonePrefix: '262',
});
const NAM = new CountryConstantEntity({
  value: 516,
  name: 'NAM',
  translationTag: 'country',
  phonePrefix: '264',
});
const NCL = new CountryConstantEntity({
  value: 540,
  name: 'NCL',
  translationTag: 'country',
  phonePrefix: '687',
});
const NER = new CountryConstantEntity({
  value: 562,
  name: 'NER',
  translationTag: 'country',
  phonePrefix: '227',
});
const NFK = new CountryConstantEntity({
  value: 574,
  name: 'NFK',
  translationTag: 'country',
  phonePrefix: '672',
});
const NGA = new CountryConstantEntity({
  value: 566,
  name: 'NGA',
  translationTag: 'country',
  phonePrefix: '234',
});
const NIC = new CountryConstantEntity({
  value: 558,
  name: 'NIC',
  translationTag: 'country',
  phonePrefix: '505',
});
const NIU = new CountryConstantEntity({
  value: 570,
  name: 'NIU',
  translationTag: 'country',
  phonePrefix: '683',
});
const NLD = new CountryConstantEntity({
  value: 528,
  name: 'NLD',
  translationTag: 'country',
  phonePrefix: '31',
});
const NOR = new CountryConstantEntity({
  value: 578,
  name: 'NOR',
  translationTag: 'country',
  phonePrefix: '47',
});
const NPL = new CountryConstantEntity({
  value: 524,
  name: 'NPL',
  translationTag: 'country',
  phonePrefix: '977',
});
const NRU = new CountryConstantEntity({
  value: 520,
  name: 'NRU',
  translationTag: 'country',
  phonePrefix: '674',
});
const NZL = new CountryConstantEntity({
  value: 554,
  name: 'NZL',
  translationTag: 'country',
  phonePrefix: '64',
});
const OMN = new CountryConstantEntity({
  value: 512,
  name: 'OMN',
  translationTag: 'country',
  phonePrefix: '968',
});
const PAK = new CountryConstantEntity({
  value: 586,
  name: 'PAK',
  translationTag: 'country',
  phonePrefix: '92',
});
const PAN = new CountryConstantEntity({
  value: 591,
  name: 'PAN',
  translationTag: 'country',
  phonePrefix: '507',
});
const PCN = new CountryConstantEntity({
  value: 612,
  name: 'PCN',
  translationTag: 'country',
  phonePrefix: '870',
});
const PER = new CountryConstantEntity({
  value: 604,
  name: 'PER',
  translationTag: 'country',
  phonePrefix: '51',
});
const PHL = new CountryConstantEntity({
  value: 608,
  name: 'PHL',
  translationTag: 'country',
  phonePrefix: '63',
});
const PLW = new CountryConstantEntity({
  value: 585,
  name: 'PLW',
  translationTag: 'country',
  phonePrefix: '680',
});
const PNG = new CountryConstantEntity({
  value: 598,
  name: 'PNG',
  translationTag: 'country',
  phonePrefix: '675',
});
const POL = new CountryConstantEntity({
  value: 616,
  name: 'POL',
  translationTag: 'country',
  phonePrefix: '48',
});
const PRI = new CountryConstantEntity({
  value: 630,
  name: 'PRI',
  translationTag: 'country',
  phonePrefix: '1',
});
const PRK = new CountryConstantEntity({
  value: 408,
  name: 'PRK',
  translationTag: 'country',
  phonePrefix: '850',
});
const PRT = new CountryConstantEntity({
  value: 620,
  name: 'PRT',
  translationTag: 'country',
  phonePrefix: '351',
});
const PRY = new CountryConstantEntity({
  value: 600,
  name: 'PRY',
  translationTag: 'country',
  phonePrefix: '595',
});
const PSE = new CountryConstantEntity({
  value: 275,
  name: 'PSE',
  translationTag: 'country',
  phonePrefix: '970',
});
const PYF = new CountryConstantEntity({
  value: 258,
  name: 'PYF',
  translationTag: 'country',
  phonePrefix: '689',
});
const QAT = new CountryConstantEntity({
  value: 634,
  name: 'QAT',
  translationTag: 'country',
  phonePrefix: '974',
});
const REU = new CountryConstantEntity({
  value: 638,
  name: 'REU',
  translationTag: 'country',
  phonePrefix: '262',
});
const ROU = new CountryConstantEntity({
  value: 642,
  name: 'ROU',
  translationTag: 'country',
  phonePrefix: '40',
});
const RUS = new CountryConstantEntity({
  value: 643,
  name: 'RUS',
  translationTag: 'country',
  phonePrefix: '7',
});
const RWA = new CountryConstantEntity({
  value: 646,
  name: 'RWA',
  translationTag: 'country',
  phonePrefix: '250',
});
const SAU = new CountryConstantEntity({
  value: 682,
  name: 'SAU',
  translationTag: 'country',
  phonePrefix: '966',
});
const SDN = new CountryConstantEntity({
  value: 729,
  name: 'SDN',
  translationTag: 'country',
  phonePrefix: '249',
});
const SEN = new CountryConstantEntity({
  value: 686,
  name: 'SEN',
  translationTag: 'country',
  phonePrefix: '221',
});
const SGP = new CountryConstantEntity({
  value: 702,
  name: 'SGP',
  translationTag: 'country',
  phonePrefix: '65',
});
const SGS = new CountryConstantEntity({
  value: 239,
  name: 'SGS',
  translationTag: 'country',
  phonePrefix: '500',
});
const SHN = new CountryConstantEntity({
  value: 654,
  name: 'SHN',
  translationTag: 'country',
  phonePrefix: '290',
});
const SJM = new CountryConstantEntity({
  value: 744,
  name: 'SJM',
  translationTag: 'country',
  phonePrefix: '47',
});
const SLB = new CountryConstantEntity({
  value: 90,
  name: 'SLB',
  translationTag: 'country',
  phonePrefix: '677',
});
const SLE = new CountryConstantEntity({
  value: 694,
  name: 'SLE',
  translationTag: 'country',
  phonePrefix: '232',
});
const SLV = new CountryConstantEntity({
  value: 222,
  name: 'SLV',
  translationTag: 'country',
  phonePrefix: '503',
});
const SMR = new CountryConstantEntity({
  value: 674,
  name: 'SMR',
  translationTag: 'country',
  phonePrefix: '378',
});
const SOM = new CountryConstantEntity({
  value: 706,
  name: 'SOM',
  translationTag: 'country',
  phonePrefix: '252',
});
const SPM = new CountryConstantEntity({
  value: 666,
  name: 'SPM',
  translationTag: 'country',
  phonePrefix: '508',
});
const SRB = new CountryConstantEntity({
  value: 688,
  name: 'SRB',
  translationTag: 'country',
  phonePrefix: '381',
});
const SSD = new CountryConstantEntity({
  value: 728,
  name: 'SSD',
  translationTag: 'country',
  phonePrefix: '211',
});
const STP = new CountryConstantEntity({
  value: 678,
  name: 'STP',
  translationTag: 'country',
  phonePrefix: '239',
});
const SUR = new CountryConstantEntity({
  value: 740,
  name: 'SUR',
  translationTag: 'country',
  phonePrefix: '597',
});
const SVK = new CountryConstantEntity({
  value: 703,
  name: 'SVK',
  translationTag: 'country',
  phonePrefix: '421',
});
const SVN = new CountryConstantEntity({
  value: 705,
  name: 'SVN',
  translationTag: 'country',
  phonePrefix: '386',
});
const SWE = new CountryConstantEntity({
  value: 752,
  name: 'SWE',
  translationTag: 'country',
  phonePrefix: '46',
});
const SWZ = new CountryConstantEntity({
  value: 748,
  name: 'SWZ',
  translationTag: 'country',
  phonePrefix: '268',
});
const SXM = new CountryConstantEntity({
  value: 534,
  name: 'SXM',
  translationTag: 'country',
  phonePrefix: '1-721',
});
const SYC = new CountryConstantEntity({
  value: 690,
  name: 'SYC',
  translationTag: 'country',
  phonePrefix: '248',
});
const SYR = new CountryConstantEntity({
  value: 760,
  name: 'SYR',
  translationTag: 'country',
  phonePrefix: '963',
});
const TCA = new CountryConstantEntity({
  value: 796,
  name: 'TCA',
  translationTag: 'country',
  phonePrefix: '1-649',
});
const TCD = new CountryConstantEntity({
  value: 148,
  name: 'TCD',
  translationTag: 'country',
  phonePrefix: '235',
});
const TGO = new CountryConstantEntity({
  value: 768,
  name: 'TGO',
  translationTag: 'country',
  phonePrefix: '228',
});
const THA = new CountryConstantEntity({
  value: 764,
  name: 'THA',
  translationTag: 'country',
  phonePrefix: '66',
});
const TJK = new CountryConstantEntity({
  value: 762,
  name: 'TJK',
  translationTag: 'country',
  phonePrefix: '992',
});
const TKL = new CountryConstantEntity({
  value: 772,
  name: 'TKL',
  translationTag: 'country',
  phonePrefix: '690',
});
const TKM = new CountryConstantEntity({
  value: 795,
  name: 'TKM',
  translationTag: 'country',
  phonePrefix: '993',
});
const TLS = new CountryConstantEntity({
  value: 626,
  name: 'TLS',
  translationTag: 'country',
  phonePrefix: '670',
});
const TON = new CountryConstantEntity({
  value: 776,
  name: 'TON',
  translationTag: 'country',
  phonePrefix: '676',
});
const TTO = new CountryConstantEntity({
  value: 780,
  name: 'TTO',
  translationTag: 'country',
  phonePrefix: '1-868',
});
const TUN = new CountryConstantEntity({
  value: 788,
  name: 'TUN',
  translationTag: 'country',
  phonePrefix: '216',
});
const TUR = new CountryConstantEntity({
  value: 792,
  name: 'TUR',
  translationTag: 'country',
  phonePrefix: '90',
});
const TUV = new CountryConstantEntity({
  value: 798,
  name: 'TUV',
  translationTag: 'country',
  phonePrefix: '688',
});
const TWN = new CountryConstantEntity({
  value: 158,
  name: 'TWN',
  translationTag: 'country',
  phonePrefix: '886',
});
const TZA = new CountryConstantEntity({
  value: 834,
  name: 'TZA',
  translationTag: 'country',
  phonePrefix: '255',
});
const UGA = new CountryConstantEntity({
  value: 800,
  name: 'UGA',
  translationTag: 'country',
  phonePrefix: '256',
});
const UKR = new CountryConstantEntity({
  value: 804,
  name: 'UKR',
  translationTag: 'country',
  phonePrefix: '380',
});
const UMI = new CountryConstantEntity({
  value: 581,
  name: 'UMI',
  translationTag: 'country',
  phonePrefix: ' ',
});
const URY = new CountryConstantEntity({
  value: 858,
  name: 'URY',
  translationTag: 'country',
  phonePrefix: '598',
});
const USA = new CountryConstantEntity({
  value: 840,
  name: 'USA',
  translationTag: 'country',
  phonePrefix: '1',
});
const UZB = new CountryConstantEntity({
  value: 860,
  name: 'UZB',
  translationTag: 'country',
  phonePrefix: '998',
});
const VAT = new CountryConstantEntity({
  value: 336,
  name: 'VAT',
  translationTag: 'country',
  phonePrefix: '39-06',
});
const VCT = new CountryConstantEntity({
  value: 670,
  name: 'VCT',
  translationTag: 'country',
  phonePrefix: '1-784',
});
const VEN = new CountryConstantEntity({
  value: 862,
  name: 'VEN',
  translationTag: 'country',
  phonePrefix: '58',
});
const VGB = new CountryConstantEntity({
  value: 92,
  name: 'VGB',
  translationTag: 'country',
  phonePrefix: '1-284',
});
const VIR = new CountryConstantEntity({
  value: 850,
  name: 'VIR',
  translationTag: 'country',
  phonePrefix: '1-340',
});
const VNM = new CountryConstantEntity({
  value: 704,
  name: 'VNM',
  translationTag: 'country',
  phonePrefix: '84',
});
const VUT = new CountryConstantEntity({
  value: 548,
  name: 'VUT',
  translationTag: 'country',
  phonePrefix: '678',
});
const WLF = new CountryConstantEntity({
  value: 876,
  name: 'WLF',
  translationTag: 'country',
  phonePrefix: '681',
});
const WSM = new CountryConstantEntity({
  value: 882,
  name: 'WSM',
  translationTag: 'country',
  phonePrefix: '685',
});
const YEM = new CountryConstantEntity({
  value: 887,
  name: 'YEM',
  translationTag: 'country',
  phonePrefix: '967',
});
const ZAF = new CountryConstantEntity({
  value: 710,
  name: 'ZAF',
  translationTag: 'country',
  phonePrefix: '27',
});
const ZMB = new CountryConstantEntity({
  value: 894,
  name: 'ZMB',
  translationTag: 'country',
  phonePrefix: '260',
});
const ZWE = new CountryConstantEntity({
  value: 716,
  name: 'ZWE',
  translationTag: 'country',
  phonePrefix: '263',
});

const COUNTRIES: CountryConstantEntity[] = [
  AFG,
  ALA,
  ALB,
  DZA,
  ASM,
  AND,
  AGO,
  AIA,
  ATA,
  ATG,
  ARG,
  ARM,
  ABW,
  AUS,
  AUT,
  AZE,
  BHS,
  BHR,
  BGD,
  BRB,
  BLR,
  BEL,
  BLZ,
  BEN,
  BMU,
  BTN,
  BOL,
  BES,
  BIH,
  BWA,
  BVT,
  BRA,
  IOT,
  BRN,
  BGR,
  BFA,
  BDI,
  KHM,
  CMR,
  CAN,
  CPV,
  CYM,
  CAF,
  TCD,
  CHL,
  CHN,
  CXR,
  CCK,
  COL,
  COM,
  COG,
  COD,
  COK,
  CRI,
  CIV,
  HRV,
  CUB,
  CUW,
  CYP,
  CZE,
  DNK,
  DJI,
  DMA,
  DOM,
  ECU,
  EGY,
  SLV,
  GNQ,
  ERI,
  EST,
  ETH,
  FLK,
  FRO,
  FJI,
  FIN,
  FRA,
  GUF,
  PYF,
  ATF,
  GAB,
  GMB,
  GEO,
  DEU,
  GHA,
  GIB,
  GRC,
  GRL,
  GRD,
  GLP,
  GUM,
  GTM,
  GGY,
  GIN,
  GNB,
  GUY,
  HTI,
  HMD,
  VAT,
  HND,
  HKG,
  HUN,
  ISL,
  IND,
  IDN,
  IRN,
  IRQ,
  IRL,
  IMN,
  ISR,
  ITA,
  JAM,
  JPN,
  JEY,
  JOR,
  KAZ,
  KEN,
  KIR,
  PRK,
  KOR,
  KWT,
  KGZ,
  LAO,
  LVA,
  LBN,
  LSO,
  LBR,
  LBY,
  LIE,
  LTU,
  LUX,
  MAC,
  MKD,
  MDG,
  MWI,
  MYS,
  MDV,
  MLI,
  MLT,
  MHL,
  MTQ,
  MRT,
  MUS,
  MYT,
  MEX,
  FSM,
  MAR,
  MDA,
  MCO,
  MNG,
  MNE,
  MSR,
  MOZ,
  MMR,
  NAM,
  NRU,
  NPL,
  NLD,
  NCL,
  NZL,
  NIC,
  NER,
  NGA,
  NIU,
  NFK,
  MNP,
  NOR,
  OMN,
  PAK,
  PLW,
  PSE,
  PAN,
  PNG,
  PRY,
  PER,
  PHL,
  PCN,
  POL,
  PRT,
  PRI,
  QAT,
  REU,
  ROU,
  RUS,
  RWA,
  BLM,
  SHN,
  KNA,
  LCA,
  MAF,
  SPM,
  VCT,
  WSM,
  SMR,
  STP,
  SAU,
  SEN,
  SRB,
  SYC,
  SLE,
  SGP,
  SXM,
  SVK,
  SVN,
  SLB,
  SOM,
  ZAF,
  SGS,
  SSD,
  ESP,
  LKA,
  SDN,
  SUR,
  SJM,
  SWZ,
  SWE,
  CHE,
  SYR,
  TWN,
  TJK,
  TZA,
  THA,
  TLS,
  TGO,
  TKL,
  TON,
  TTO,
  TUN,
  TUR,
  TKM,
  TCA,
  TUV,
  UGA,
  UKR,
  ARE,
  GBR,
  USA,
  UMI,
  URY,
  UZB,
  VUT,
  VEN,
  VNM,
  VGB,
  VIR,
  WLF,
  ESH,
  YEM,
  ZMB,
  ZWE,
];

export class CountriesConstants extends ConstantGroup {
  list(): CountryConstantEntity[] {
    return COUNTRIES;
  }
}
