import { ConstantGroup } from './base.constants';
import { EventConstantEntity } from './event-constant.entity';

const ALARMS = new EventConstantEntity({
  value: 0,
  name: 'ALARMS',
  translationTag: 'eventType',
  zeroColor: '#2DC76F',
  positiveColor: '#D13828',
});

const ALERTS = new EventConstantEntity({
  value: 1,
  name: 'ALERTS',
  translationTag: 'eventType',
  zeroColor: '#2DC76F',
  positiveColor: '#E4781D',
});

const COMMENTS = new EventConstantEntity({
  value: 2,
  name: 'COMMENTS',
  translationTag: 'eventType',
  zeroColor: '#2DC76F',
  positiveColor: '#2651B5',
});

const FAULTS = new EventConstantEntity({
  value: 3,
  name: 'FAULTS',
  translationTag: 'eventType',
  zeroColor: '#2DC76F',
  positiveColor: '#E9B500',
});

const EVENT_TYPES: EventConstantEntity[] = [ALARMS, ALERTS, FAULTS];

export class EventTypesConstants extends ConstantGroup {
  list(): EventConstantEntity[] {
    return EVENT_TYPES;
  }

  static get ALARMS() {
    return ALARMS;
  }

  static get ALERTS() {
    return ALERTS;
  }

  static get FAULTS() {
    return FAULTS;
  }

  static get COMMENTS() {
    return COMMENTS;
  }
}
