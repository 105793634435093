import { LanguageEntity } from '@entities/language.entity';
import { StringIndexedEntities, initializeClass } from '@utils/utils';

export class Languages {
  public static languages: StringIndexedEntities<string> = {
    en: 'English',
    ca: 'Català',
    es: 'Castellano',
    fr: 'Français',
    ru: 'Pусский',
    de: 'Deutsch',
    lt: 'Lietuvių kalba',
    zh: '中文',
  };

  public static get IterableLanguages(): LanguageEntity[] {
    const languages = [];
    for (const [iso2, name] of Object.entries(Languages.languages)) {
      languages.push(initializeClass(LanguageEntity, { iso2, name }));
    }
    return languages;
  }

  public static get Map(): Map<any, LanguageEntity> {
    const initialMap = new Map<any, LanguageEntity>();
    return Languages.IterableLanguages.reduce((map, obj) => {
      map[obj.iso2] = obj;
      return map;
    }, initialMap);
  }
}
