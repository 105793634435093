import { NgModule } from '@angular/core';

import {
  DxChartModule,
  DxDateBoxModule,
  DxCalendarModule,
  DxProgressBarModule,
  DxRangeSliderModule,
  DxSliderModule,
} from 'devextreme-angular';

@NgModule({
  declarations: [],
  imports: [
    DxChartModule,
    DxDateBoxModule,
    DxCalendarModule,
    DxProgressBarModule,
    DxSliderModule,
    DxRangeSliderModule,
  ],
  exports: [
    DxChartModule,
    DxDateBoxModule,
    DxCalendarModule,
    DxProgressBarModule,
    DxSliderModule,
    DxRangeSliderModule,
  ],
})
export class DevExtremeModule { }
