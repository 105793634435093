import { ConstantEntity, ConstantGroup } from './base.constants';

const EMAIL = new ConstantEntity({
  value: 0,
  name: 'EMAIL',
  translationTag: 'preferenceType',
});

const NUMBER = new ConstantEntity({
  value: 1,
  name: 'NUMBER',
  translationTag: 'preferenceType',
});

const TEXT = new ConstantEntity({
  value: 2,
  name: 'TEXT',
  translationTag: 'preferenceType',
});

const NUMBER_ARRAY = new ConstantEntity({
  value: 3,
  name: 'NUMBER_ARRAY',
  translationTag: 'preferenceType',
});

const EMAIL_ARRAY = new ConstantEntity({
  value: 4,
  name: 'EMAIL_ARRAY',
  translationTag: 'preferenceType',
});

const LANGUAGE = new ConstantEntity({
  value: 5,
  name: 'LANGUAGE',
  translationTag: 'preferenceType',
});

const PRICE_PLAN_UUID = new ConstantEntity({
  value: 6,
  name: 'PRICE_PLAN_UUID',
  translationTag: 'preferenceType',
});

const PREFERENCE_TYPES: ConstantEntity[] = [
  EMAIL,
  NUMBER,
  TEXT,
  NUMBER_ARRAY,
  EMAIL_ARRAY,
  LANGUAGE,
  PRICE_PLAN_UUID,
];

export class PreferenceTypesConstants extends ConstantGroup {
  list(): ConstantEntity[] {
    return PREFERENCE_TYPES;
  }

  static get EMAIL() {
    return EMAIL;
  }

  static get NUMBER() {
    return NUMBER;
  }

  static get TEXT() {
    return TEXT;
  }

  static get NUMBER_ARRAY() {
    return NUMBER_ARRAY;
  }

  static get EMAIL_ARRAY() {
    return EMAIL_ARRAY;
  }

  static get LANGUAGE() {
    return LANGUAGE;
  }

  static get PRICE_PLAN_UUID() {
    return PRICE_PLAN_UUID;
  }
}
