<div class="main-container">
  <div mat-dialog-title class="d-flex justify-content-between header">
    <div class="font-headline-4 color-primary-dark">{{ data.title }}</div>
    <span
      class="material-symbols-outlined close-icon cursor-pointer color-text-black"
      [mat-dialog-close]="false"
    >
      close
    </span>
  </div>
  <div mat-dialog-content>
    <form #f="ngForm" *ngIf="datumForm" [formGroup]="datumForm">
      <div class="mat-form-field-container">
        <span class="fake-label m3-label-large">{{ data.inputLabel | translate }}</span>
        <mat-form-field class="input-full-width">
          <mat-label></mat-label>
          <input
            matInput
            formControlName="datum"
            trim="blur"
            [type]="data.isAPassword ? 'password' : 'text'"
            placeholder="{{ data.inputLabel | translate }}"
          />
        </mat-form-field>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="buttons-list">
    <button
      type="button"
      mat-flat-button
      class="font-button color-feedback-action-action button-fixed-height"
      (click)="close()"
      cdkFocusInitial
    >
      <span class="btn-text">{{ 'buttons.cancel' | translate }}</span>
    </button>
    <button
      color="primary"
      type="button"
      mat-flat-button
      class="font-button color-feedback-action-action button-fixed-height"
      (click)="close(true)"
      cdkFocusInitial
      [disabled]="!datumForm.valid"
    >
      <span class="btn-text"> {{ data.confirmBtnLabel | translate }}</span>
    </button>
    <!--     <button mat-flat-button color="primary" [disabled]="!datumForm.valid" (click)="close(true)">
      {{ data.confirmBtnLabel | translate }}
    </button> -->
  </div>
</div>
