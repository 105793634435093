import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';

export const sameValueAs =
  (group: FormGroup, controlName: string): ValidatorFn =>
  (control: FormControl) => {
    const myValue = control.value;
    const compareValue = group.controls[controlName].value;

    return myValue === compareValue ? null : { valueDifferentFrom: controlName };
  };
