import * as moment from 'moment';
import { Expose } from 'class-transformer';
import { BaseEntity } from './base.entity';
import { celsiusToFahrenheit, formattedNumber } from '@utils/utils';

export class BaseHistoricalEntity extends BaseEntity {
  @Expose()
  public readingTime: string;

  public get readingTimeAsDate(): Date {
    return moment(this.readingTime).toDate(); // new Date(this.readingTime);
  }

  @Expose()
  public value: number;

  public get valueToFahrenheit(): number {
    return celsiusToFahrenheit(this.value);
  }

  public get valueAsNumber(): number {
    return Number(this.value);
  }

  public formattedValue(
    language: string,
    minimumFractionDigits: number,
    maximumFractionDigits: number
  ): string {
    return formattedNumber(language, this.value, minimumFractionDigits, maximumFractionDigits);
  }

  public formattedValueToFahrenheit(
    language: string,
    minimumFractionDigits: number,
    maximumFractionDigits: number
  ): string {
    return formattedNumber(
      language,
      celsiusToFahrenheit(this.value),
      minimumFractionDigits,
      maximumFractionDigits
    );
  }

  @Expose()
  public seriesName: string;

  public get isValidValue(): boolean {
    return ![undefined, null].includes(this.value);
  }
}
