
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@config/app.config';
import { ApiService } from '@services/api/api.service';

@Injectable()
export class BackendService extends ApiService {

  constructor(protected http: HttpClient) {
    super(http);
    this.baseUrlApi = AppConfig.BACKEND_BASE_URL;
  }

}
