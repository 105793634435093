import { DataProvidersConstants } from '@constants/data-providers.constants';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { Expose, Transform } from 'class-transformer';

export class BaseEntity {
  public translatedConstant(
    translationMWService: TranslationMWService,
    labelPrefix: string,
    constantName: string
  ): string {
    const translated = translationMWService.instant(labelPrefix + constantName);

    return translated.startsWith(labelPrefix) ? constantName : translated;
  }
}

export class EmailRequestBaseEntity {
  @Expose()
  public emailResult: boolean;
}

export class BaseUUIDEntity extends BaseEntity {
  @Expose()
  public id: string;
}

export class BaseDataProviderSplitEntity extends BaseUUIDEntity {
  @Expose()
  @Transform(({ value }) => Number(value), { toClassOnly: true })
  public dataProvider: number;

  private isATypeOfPricePlan(dataProvider: number): boolean {
    return this.dataProvider === dataProvider;
  }

  public get isForLite(): boolean {
    return this.isATypeOfPricePlan(DataProvidersConstants.CTCLITE.numberValue);
  }

  public get isForPlus(): boolean {
    return this.isATypeOfPricePlan(DataProvidersConstants.CTCPLUS.numberValue);
  }
}

export class Base64Sha256dEntity extends BaseUUIDEntity {
  @Expose()
  public stringBase64Data: string;

  @Expose()
  public sha256: string;
}
