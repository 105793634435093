export const TEMPERATURE_CELSIUS_COLORS: string[] = [
  '#CDD5EC',
  '#B9CBF1',
  '#A8C4EB',
  '#98B9E4',
  '#85ADE1',
  '#719FDB',
  '#6196D8',
  '#4D92CD',
  '#4597BC',
  '#3A9CA5',
  '#2B9B92',
  '#1AA17C',
  '#0CA66E',
  '#03AA60',
  '#13B844',
  '#3AC641',
  '#63CF2D',
  '#88DC24',
  '#A9E719',
  '#D7F309',
  '#F8FE03',
  '#FFF800',
  '#FFEB00',
  '#FFE300',
  '#FFD900',
  '#FED300',
  '#FFC800',
  '#FBB800',
  '#FF9900',
  '#FE8100',
  '#FE6300',
  '#FF4700',
  '#FF2F06',
  '#FF0900',
  '#FA0306',
  '#E90F00',
  '#DD1A00',
  '#C72803',
  '#B53406',
  '#A63C06',
  '#9B4803',
  '#944815',
  '#903F34',
  '#8C3A4C',
  '#88375F',
  '#7E376C',
  '#752F89',
  '#6D349D',
  '#6B3892',
  '#693C8A',
  '#624480',
  '#604B6F',
  '#5C4E6B',
  '#5A5760',
  '#565753',
  '#555452',
  '#52514E',
  '#505048',
  '#4B4B43',
  '#48483F',
  '#444148',
];

export const TEMPERATURE_FARENHEIT_COLORS: string[] = [
  '#85ADE1',
  '#81AADE',
  '#7BA5DF',
  '#719FDB',
  '#6C9CDA',
  '#6196D8',
  '#5C93D6',
  '#5790D3',
  '#4B93C9',
  '#4895C3',
  '#4597BC',
  '#3C9CAC',
  '#3A9CA5',
  '#319A9B',
  '#2F9A96',
  '#2B9B92',
  '#1F9F82',
  '#1AA17C',
  '#15A376',
  '#0CA66E',
  '#06A76C',
  '#03AA60',
  '#03AC57',
  '#06AF4C',
  '#13B844',
  '#24BC43',
  '#2DC043',
  '#49CA3C',
  '#57CB34',
  '#70D32A',
  '#77D72A',
  '#88DC24',
  '#9CE420',
  '#A9E719',
  '#B6E811',
  '#D0F106',
  '#D7F309',
  '#EEFB03',
  '#F8FE03',
  '#FFFF03',
  '#FFF800',
  '#FFF300',
  '#FFEB00',
  '#FFEA00',
  '#FFE700',
  '#FFE000',
  '#FFDC00',
  '#FFDA00',
  '#FFDA00',
  '#FFD600',
  '#FFD000',
  '#FECC00',
  '#FFC800',
  '#FBC200',
  '#FBB800',
  '#FBB300',
  '#FDA100',
  '#FF9900',
  '#FF8700',
  '#FE8100',
  '#FF7000',
  '#FE6300',
  '#FF5A00',
  '#FF5000',
  '#FF3E00',
  '#FF3A00',
  '#FF2600',
  '#FF1C00',
  '#FF1300',
  '#FA0300',
  '#FA0300',
  '#FA0306',
  '#EC0C00',
  '#E90F00',
  '#E11500',
  '#DD1A00',
  '#DB1D00',
  '#C92503',
  '#C72803',
  '#BB2F06',
  '#B53406',
  '#AE3706',
  '#A63C06',
  '#A24400',
  '#9F4500',
  '#974906',
  '#95490C',
  '#94451F',
  '#954424',
  '#914132',
  '#8F3F3C',
  '#8D3E44',
  '#8C3A4C',
  '#88375B',
  '#88375F',
  '#813A66',
  '#7E376C',
  '#7B3474',
  '#752F89',
  '#732F91',
  '#722F96',
  '#6D349D',
  '#6C349A',
  '#6B3892',
  '#6B3A8F',
  '#693C8C',
  '#634187',
  '#634386',
  '#62477C',
];

export const HUMIDITY_COLORS: string[] = [
  '#06A450',
  '#00AF52',
  '#00B04C',
  '#00B052',
  '#00AF4B',
  '#00A568',
  '#009A80',
  '#00889E',
  '#007AAD',
  '#0370BA',
  '#0C6DAF',
  '#1A68A2',
  '#27659B',
  '#3C5F8F',
  '#6C456B',
  '#90324E',
  '#BB1F27',
  '#F1030C',
  '#FE0000',
  '#FF0000',
  '#FE0000',
  '#FF0000',
  '#F30309',
  '#CF0F30',
  '#B01A5A',
  '#922079',
  '#702FA2',
  '#702FA2',
  '#7031A1',
  '#70309C',
  '#6E31A4',
];

export const CO2_COLORS: string[] = [
  '#B3FF00',
  '#B3FF00',
  '#B3FF00',
  '#B3FF00',
  '#B3FF00',
  '#B3FF00',
  '#B3FF00',
  '#B3FF00',
  '#B3FF00',
  '#B7FF00',
  '#B7FF00',
  '#B7FF00',
  '#B7FF00',
  '#B7FF00',
  '#B7FF00',
  '#B7FF00',
  '#B7FF00',
  '#B7FF00',
  '#B8FF00',
  '#B8FF00',
  '#B8FF00',
  '#B8FF00',
  '#B8FF00',
  '#B8FF00',
  '#B8FF00',
  '#B8FF00',
  '#B8FF00',
  '#B8FF00',
  '#B8FF00',
  '#B8FF00',
  '#B8FF00',
  '#B8FF00',
  '#B8FF00',
  '#B8FF00',
  '#B8FF00',
  '#BEFD00',
  '#BEFD00',
  '#BEFD00',
  '#BEFD00',
  '#BEFD00',
  '#BEFD00',
  '#BEFD00',
  '#BEFD00',
  '#BEFD00',
  '#BFFF00',
  '#BFFF00',
  '#BFFF00',
  '#BFFF00',
  '#BFFF00',
  '#BFFF00',
  '#BFFF00',
  '#BFFF00',
  '#BFFF00',
  '#C0FF00',
  '#C0FF00',
  '#C0FF00',
  '#C0FF00',
  '#C0FF00',
  '#C0FF00',
  '#C0FF00',
  '#C0FF00',
  '#C0FF00',
  '#C0FE00',
  '#C0FE00',
  '#C0FE00',
  '#C0FE00',
  '#C0FE00',
  '#C0FE00',
  '#C0FE00',
  '#C0FE00',
  '#C2FF00',
  '#C2FF00',
  '#C2FF00',
  '#C2FF00',
  '#C2FF00',
  '#C2FF00',
  '#C2FF00',
  '#C2FF00',
  '#C2FF00',
  '#C3FF00',
  '#C3FF00',
  '#C3FF00',
  '#C3FF00',
  '#C3FF00',
  '#C3FF00',
  '#C3FF00',
  '#C3FF00',
  '#C3FF00',
  '#C7FF00',
  '#C7FF00',
  '#C7FF00',
  '#C7FF00',
  '#C7FF00',
  '#C7FF00',
  '#C7FF00',
  '#C7FF00',
  '#C7FF00',
  '#C7FF00',
  '#C7FF00',
  '#C7FF00',
  '#C7FF00',
  '#C7FF00',
  '#C7FF00',
  '#C7FF00',
  '#C7FF00',
  '#C7FF00',
  '#C9FF00',
  '#C9FF00',
  '#C9FF00',
  '#C9FF00',
  '#C9FF00',
  '#C9FF00',
  '#C9FF00',
  '#C9FF00',
  '#CAFF00',
  '#CAFF00',
  '#CAFF00',
  '#CAFF00',
  '#CAFF00',
  '#CAFF00',
  '#CAFF00',
  '#CAFF00',
  '#CAFF00',
  '#CCFF00',
  '#CCFF00',
  '#CCFF00',
  '#CCFF00',
  '#CCFF00',
  '#CCFF00',
  '#CCFF00',
  '#CCFF00',
  '#CCFF00',
  '#CDFF00',
  '#CDFF00',
  '#CDFF00',
  '#CDFF00',
  '#CDFF00',
  '#CDFF00',
  '#CDFF00',
  '#CDFF00',
  '#CDFF00',
  '#CFFF00',
  '#CFFF00',
  '#CFFF00',
  '#CFFF00',
  '#CFFF00',
  '#CFFF00',
  '#CFFF00',
  '#CFFF00',
  '#CFFF00',
  '#CFFF00',
  '#CFFF00',
  '#CFFF00',
  '#CFFF00',
  '#CFFF00',
  '#CFFF00',
  '#CFFF00',
  '#CFFF00',
  '#CFFF00',
  '#D1FF00',
  '#D1FF00',
  '#D1FF00',
  '#D1FF00',
  '#D1FF00',
  '#D1FF00',
  '#D1FF00',
  '#D1FF00',
  '#D1FF00',
  '#D1FF00',
  '#D1FF00',
  '#D1FF00',
  '#D1FF00',
  '#D1FF00',
  '#D1FF00',
  '#D1FF00',
  '#D1FF00',
  '#D4FF00',
  '#D4FF00',
  '#D4FF00',
  '#D4FF00',
  '#D4FF00',
  '#D4FF00',
  '#D4FF00',
  '#D4FF00',
  '#D4FF00',
  '#D4FF00',
  '#D4FF00',
  '#D4FF00',
  '#D4FF00',
  '#D4FF00',
  '#D4FF00',
  '#D4FF00',
  '#D4FF00',
  '#D4FF00',
  '#D5FF00',
  '#D5FF00',
  '#D5FF00',
  '#D5FF00',
  '#D5FF00',
  '#D5FF00',
  '#D5FF00',
  '#D5FF00',
  '#D5FF00',
  '#D9FF00',
  '#D9FF00',
  '#D9FF00',
  '#D9FF00',
  '#D9FF00',
  '#D9FF00',
  '#D9FF00',
  '#D9FF00',
  '#DAFF00',
  '#DAFF00',
  '#DAFF00',
  '#DAFF00',
  '#DAFF00',
  '#DAFF00',
  '#DAFF00',
  '#DAFF00',
  '#DAFF00',
  '#DCFE00',
  '#DCFE00',
  '#DCFE00',
  '#DCFE00',
  '#DCFE00',
  '#DCFE00',
  '#DCFE00',
  '#DCFE00',
  '#DCFE00',
  '#DEFE00',
  '#DEFE00',
  '#DEFE00',
  '#DEFE00',
  '#DEFE00',
  '#DEFE00',
  '#DEFE00',
  '#DEFE00',
  '#DEFE00',
  '#E0FF00',
  '#E0FF00',
  '#E0FF00',
  '#E0FF00',
  '#E0FF00',
  '#E0FF00',
  '#E0FF00',
  '#E0FF00',
  '#E0FF00',
  '#DFFF00',
  '#DFFF00',
  '#DFFF00',
  '#DFFF00',
  '#DFFF00',
  '#DFFF00',
  '#DFFF00',
  '#DFFF00',
  '#E0FF00',
  '#E0FF00',
  '#E0FF00',
  '#E0FF00',
  '#E0FF00',
  '#E0FF00',
  '#E0FF00',
  '#E0FF00',
  '#E0FF00',
  '#E1FF00',
  '#E1FF00',
  '#E1FF00',
  '#E1FF00',
  '#E1FF00',
  '#E1FF00',
  '#E1FF00',
  '#E1FF00',
  '#E1FF00',
  '#E5FF00',
  '#E5FF00',
  '#E5FF00',
  '#E5FF00',
  '#E5FF00',
  '#E5FF00',
  '#E5FF00',
  '#E5FF00',
  '#E5FF00',
  '#E8FF00',
  '#E8FF00',
  '#E8FF00',
  '#E8FF00',
  '#E8FF00',
  '#E8FF00',
  '#E8FF00',
  '#E8FF00',
  '#E8FF00',
  '#E9FF00',
  '#E9FF00',
  '#E9FF00',
  '#E9FF00',
  '#E9FF00',
  '#E9FF00',
  '#E9FF00',
  '#E9FF00',
  '#EAFE00',
  '#EAFE00',
  '#EAFE00',
  '#EAFE00',
  '#EAFE00',
  '#EAFE00',
  '#EAFE00',
  '#EAFE00',
  '#EAFE00',
  '#EBFF00',
  '#EBFF00',
  '#EBFF00',
  '#EBFF00',
  '#EBFF00',
  '#EBFF00',
  '#EBFF00',
  '#EBFF00',
  '#EBFF00',
  '#EBFF00',
  '#EBFF00',
  '#EBFF00',
  '#EBFF00',
  '#EBFF00',
  '#EBFF00',
  '#EBFF00',
  '#EBFF00',
  '#EBFF00',
  '#EAFF00',
  '#EAFF00',
  '#EAFF00',
  '#EAFF00',
  '#EAFF00',
  '#EAFF00',
  '#EAFF00',
  '#EAFF00',
  '#EAFF00',
  '#EEFF00',
  '#EEFF00',
  '#EEFF00',
  '#EEFF00',
  '#EEFF00',
  '#EEFF00',
  '#EEFF00',
  '#EEFF00',
  '#EEFF00',
  '#F0FF00',
  '#F0FF00',
  '#F0FF00',
  '#F0FF00',
  '#F0FF00',
  '#F0FF00',
  '#F0FF00',
  '#F0FF00',
  '#F1FF00',
  '#F1FF00',
  '#F1FF00',
  '#F1FF00',
  '#F1FF00',
  '#F1FF00',
  '#F1FF00',
  '#F1FF00',
  '#F1FF00',
  '#F1FF00',
  '#F1FF00',
  '#F1FF00',
  '#F1FF00',
  '#F1FF00',
  '#F1FF00',
  '#F1FF00',
  '#F1FF00',
  '#F1FF00',
  '#F3FF00',
  '#F3FF00',
  '#F3FF00',
  '#F3FF00',
  '#F3FF00',
  '#F3FF00',
  '#F3FF00',
  '#F3FF00',
  '#F3FF00',
  '#F3FF00',
  '#F3FF00',
  '#F3FF00',
  '#F3FF00',
  '#F3FF00',
  '#F3FF00',
  '#F3FF00',
  '#F3FF00',
  '#F3FF00',
  '#F5FF00',
  '#F5FF00',
  '#F5FF00',
  '#F5FF00',
  '#F5FF00',
  '#F5FF00',
  '#F5FF00',
  '#F5FF00',
  '#F5FF00',
  '#F5FF00',
  '#F5FF00',
  '#F5FF00',
  '#F5FF00',
  '#F5FF00',
  '#F5FF00',
  '#F5FF00',
  '#F5FF00',
  '#F6FD00',
  '#F6FD00',
  '#F6FD00',
  '#F6FD00',
  '#F6FD00',
  '#F6FD00',
  '#F6FD00',
  '#F6FD00',
  '#F6FD00',
  '#F6FD00',
  '#F6FD00',
  '#F6FD00',
  '#F6FD00',
  '#F6FD00',
  '#F6FD00',
  '#F6FD00',
  '#F6FD00',
  '#F6FD00',
  '#F8FD00',
  '#F8FD00',
  '#F8FD00',
  '#F8FD00',
  '#F8FD00',
  '#F8FD00',
  '#F8FD00',
  '#F8FD00',
  '#F8FD00',
  '#F8FB03',
  '#F8FB03',
  '#F8FB03',
  '#F8FB03',
  '#F8FB03',
  '#F8FB03',
  '#F8FB03',
  '#F8FB03',
  '#F9FA00',
  '#F9FA00',
  '#F9FA00',
  '#F9FA00',
  '#F9FA00',
  '#F9FA00',
  '#F9FA00',
  '#F9FA00',
  '#F9FA00',
  '#F9FA00',
  '#F9FA00',
  '#F9FA00',
  '#F9FA00',
  '#F9FA00',
  '#F9FA00',
  '#F9FA00',
  '#F9FA00',
  '#F9FA00',
  '#FAF500',
  '#FAF500',
  '#FAF500',
  '#FAF500',
  '#FAF500',
  '#FAF500',
  '#FAF500',
  '#FAF500',
  '#FAF500',
  '#FBF500',
  '#FBF500',
  '#FBF500',
  '#FBF500',
  '#FBF500',
  '#FBF500',
  '#FBF500',
  '#FBF500',
  '#FBF500',
  '#FBF503',
  '#FBF503',
  '#FBF503',
  '#FBF503',
  '#FBF503',
  '#FBF503',
  '#FBF503',
  '#FBF503',
  '#FBF303',
  '#FBF303',
  '#FBF303',
  '#FBF303',
  '#FBF303',
  '#FBF303',
  '#FBF303',
  '#FBF303',
  '#FBF303',
  '#FAF303',
  '#FAF303',
  '#FAF303',
  '#FAF303',
  '#FAF303',
  '#FAF303',
  '#FAF303',
  '#FAF303',
  '#FAF303',
  '#F9F100',
  '#F9F100',
  '#F9F100',
  '#F9F100',
  '#F9F100',
  '#F9F100',
  '#F9F100',
  '#F9F100',
  '#F9F100',
  '#F9F103',
  '#F9F103',
  '#F9F103',
  '#F9F103',
  '#F9F103',
  '#F9F103',
  '#F9F103',
  '#F9F103',
  '#F9F103',
  '#F9F103',
  '#F9F103',
  '#F9F103',
  '#F9F103',
  '#F9F103',
  '#F9F103',
  '#F9F103',
  '#F9F103',
  '#FAEE00',
  '#FAEE00',
  '#FAEE00',
  '#FAEE00',
  '#FAEE00',
  '#FAEE00',
  '#FAEE00',
  '#FAEE00',
  '#FAEE00',
  '#F9EC00',
  '#F9EC00',
  '#F9EC00',
  '#F9EC00',
  '#F9EC00',
  '#F9EC00',
  '#F9EC00',
  '#F9EC00',
  '#F9EC00',
  '#F9EC00',
  '#F9EC00',
  '#F9EC00',
  '#F9EC00',
  '#F9EC00',
  '#F9EC00',
  '#F9EC00',
  '#F9EC00',
  '#F9EC00',
  '#F9EB00',
  '#F9EB00',
  '#F9EB00',
  '#F9EB00',
  '#F9EB00',
  '#F9EB00',
  '#F9EB00',
  '#F9EB00',
  '#F9EB00',
  '#FAE900',
  '#FAE900',
  '#FAE900',
  '#FAE900',
  '#FAE900',
  '#FAE900',
  '#FAE900',
  '#FAE900',
  '#FAE900',
  '#F9E603',
  '#F9E603',
  '#F9E603',
  '#F9E603',
  '#F9E603',
  '#F9E603',
  '#F9E603',
  '#F9E603',
  '#FBE303',
  '#FBE303',
  '#FBE303',
  '#FBE303',
  '#FBE303',
  '#FBE303',
  '#FBE303',
  '#FBE303',
  '#FBE303',
  '#FDE406',
  '#FDE406',
  '#FDE406',
  '#FDE406',
  '#FDE406',
  '#FDE406',
  '#FDE406',
  '#FDE406',
  '#FDE406',
  '#F8E409',
  '#F8E409',
  '#F8E409',
  '#F8E409',
  '#F8E409',
  '#F8E409',
  '#F8E409',
  '#F8E409',
  '#F8E409',
  '#F8E209',
  '#F8E209',
  '#F8E209',
  '#F8E209',
  '#F8E209',
  '#F8E209',
  '#F8E209',
  '#F8E209',
  '#F8E209',
  '#F9E106',
  '#F9E106',
  '#F9E106',
  '#F9E106',
  '#F9E106',
  '#F9E106',
  '#F9E106',
  '#F9E106',
  '#F9DF06',
  '#F9DF06',
  '#F9DF06',
  '#F9DF06',
  '#F9DF06',
  '#F9DF06',
  '#F9DF06',
  '#F9DF06',
  '#F9DF06',
  '#FADD06',
  '#FADD06',
  '#FADD06',
  '#FADD06',
  '#FADD06',
  '#FADD06',
  '#FADD06',
  '#FADD06',
  '#FADD06',
  '#FADB03',
  '#FADB03',
  '#FADB03',
  '#FADB03',
  '#FADB03',
  '#FADB03',
  '#FADB03',
  '#FADB03',
  '#FADB03',
  '#FDDA03',
  '#FDDA03',
  '#FDDA03',
  '#FDDA03',
  '#FDDA03',
  '#FDDA03',
  '#FDDA03',
  '#FDDA03',
  '#FDDA03',
  '#FEDA03',
  '#FEDA03',
  '#FEDA03',
  '#FEDA03',
  '#FEDA03',
  '#FEDA03',
  '#FEDA03',
  '#FEDA03',
  '#FEDA09',
  '#FEDA09',
  '#FEDA09',
  '#FEDA09',
  '#FEDA09',
  '#FEDA09',
  '#FEDA09',
  '#FEDA09',
  '#FEDA09',
  '#FBD706',
  '#FBD706',
  '#FBD706',
  '#FBD706',
  '#FBD706',
  '#FBD706',
  '#FBD706',
  '#FBD706',
  '#FBD706',
  '#FAD509',
  '#FAD509',
  '#FAD509',
  '#FAD509',
  '#FAD509',
  '#FAD509',
  '#FAD509',
  '#FAD509',
  '#FAD509',
  '#FBD409',
  '#FBD409',
  '#FBD409',
  '#FBD409',
  '#FBD409',
  '#FBD409',
  '#FBD409',
  '#FBD409',
  '#FBD409',
  '#FED309',
  '#FED309',
  '#FED309',
  '#FED309',
  '#FED309',
  '#FED309',
  '#FED309',
  '#FED309',
  '#FED109',
  '#FED109',
  '#FED109',
  '#FED109',
  '#FED109',
  '#FED109',
  '#FED109',
  '#FED109',
  '#FED109',
  '#FFCF0C',
  '#FFCF0C',
  '#FFCF0C',
  '#FFCF0C',
  '#FFCF0C',
  '#FFCF0C',
  '#FFCF0C',
  '#FFCF0C',
  '#FFCF0C',
  '#FECE09',
  '#FECE09',
  '#FECE09',
  '#FECE09',
  '#FECE09',
  '#FECE09',
  '#FECE09',
  '#FECE09',
  '#FECE09',
  '#FACF06',
  '#FACF06',
  '#FACF06',
  '#FACF06',
  '#FACF06',
  '#FACF06',
  '#FACF06',
  '#FACF06',
  '#FACF06',
  '#FACF06',
  '#FACF06',
  '#FACF06',
  '#FACF06',
  '#FACF06',
  '#FACF06',
  '#FACF06',
  '#FACF06',
  '#F9CC06',
  '#F9CC06',
  '#F9CC06',
  '#F9CC06',
  '#F9CC06',
  '#F9CC06',
  '#F9CC06',
  '#F9CC06',
  '#F9CC06',
  '#FACB03',
  '#FACB03',
  '#FACB03',
  '#FACB03',
  '#FACB03',
  '#FACB03',
  '#FACB03',
  '#FACB03',
  '#FACB03',
  '#FACA06',
  '#FACA06',
  '#FACA06',
  '#FACA06',
  '#FACA06',
  '#FACA06',
  '#FACA06',
  '#FACA06',
  '#FACA06',
  '#F9C806',
  '#F9C806',
  '#F9C806',
  '#F9C806',
  '#F9C806',
  '#F9C806',
  '#F9C806',
  '#F9C806',
  '#F9C806',
  '#F8C606',
  '#F8C606',
  '#F8C606',
  '#F8C606',
  '#F8C606',
  '#F8C606',
  '#F8C606',
  '#F8C606',
  '#F8C606',
  '#F6C303',
  '#F6C303',
  '#F6C303',
  '#F6C303',
  '#F6C303',
  '#F6C303',
  '#F6C303',
  '#F6C303',
  '#FBC20F',
  '#FBC20F',
  '#FBC20F',
  '#FBC20F',
  '#FBC20F',
  '#FBC20F',
  '#FBC20F',
  '#FBC20F',
  '#FBC20F',
  '#FDC00F',
  '#FDC00F',
  '#FDC00F',
  '#FDC00F',
  '#FDC00F',
  '#FDC00F',
  '#FDC00F',
  '#FDC00F',
  '#FDC00F',
  '#FBBD0F',
  '#FBBD0F',
  '#FBBD0F',
  '#FBBD0F',
  '#FBBD0F',
  '#FBBD0F',
  '#FBBD0F',
  '#FBBD0F',
  '#FBBD0F',
  '#FABA0F',
  '#FABA0F',
  '#FABA0F',
  '#FABA0F',
  '#FABA0F',
  '#FABA0F',
  '#FABA0F',
  '#FABA0F',
  '#FABA0F',
  '#FBB80F',
  '#FBB80F',
  '#FBB80F',
  '#FBB80F',
  '#FBB80F',
  '#FBB80F',
  '#FBB80F',
  '#FBB80F',
  '#FBB811',
  '#FBB811',
  '#FBB811',
  '#FBB811',
  '#FBB811',
  '#FBB811',
  '#FBB811',
  '#FBB811',
  '#FBB811',
  '#FBB611',
  '#FBB611',
  '#FBB611',
  '#FBB611',
  '#FBB611',
  '#FBB611',
  '#FBB611',
  '#FBB611',
  '#FBB611',
  '#FAB513',
  '#FAB513',
  '#FAB513',
  '#FAB513',
  '#FAB513',
  '#FAB513',
  '#FAB513',
  '#FAB513',
  '#FAB513',
  '#FBB60F',
  '#FBB60F',
  '#FBB60F',
  '#FBB60F',
  '#FBB60F',
  '#FBB60F',
  '#FBB60F',
  '#FBB60F',
  '#FBB60F',
  '#FDB40F',
  '#FDB40F',
  '#FDB40F',
  '#FDB40F',
  '#FDB40F',
  '#FDB40F',
  '#FDB40F',
  '#FDB40F',
  '#FDB30C',
  '#FDB30C',
  '#FDB30C',
  '#FDB30C',
  '#FDB30C',
  '#FDB30C',
  '#FDB30C',
  '#FDB30C',
  '#FDB30C',
  '#FBAF09',
  '#FBAF09',
  '#FBAF09',
  '#FBAF09',
  '#FBAF09',
  '#FBAF09',
  '#FBAF09',
  '#FBAF09',
  '#FBAF09',
  '#FBAD0C',
  '#FBAD0C',
  '#FBAD0C',
  '#FBAD0C',
  '#FBAD0C',
  '#FBAD0C',
  '#FBAD0C',
  '#FBAD0C',
  '#FBAD0C',
  '#FDAE0C',
  '#FDAE0C',
  '#FDAE0C',
  '#FDAE0C',
  '#FDAE0C',
  '#FDAE0C',
  '#FDAE0C',
  '#FDAE0C',
  '#FDAE0C',
  '#FDAE0C',
  '#FDAE0C',
  '#FDAE0C',
  '#FDAE0C',
  '#FDAE0C',
  '#FDAE0C',
  '#FDAE0C',
  '#FDAE0C',
  '#FBAD0C',
  '#FBAD0C',
  '#FBAD0C',
  '#FBAD0C',
  '#FBAD0C',
  '#FBAD0C',
  '#FBAD0C',
  '#FBAD0C',
  '#FBAD0C',
  '#FFA60C',
  '#FFA60C',
  '#FFA60C',
  '#FFA60C',
  '#FFA60C',
  '#FFA60C',
  '#FFA60C',
  '#FFA60C',
  '#FFA60C',
  '#FFA60F',
  '#FFA60F',
  '#FFA60F',
  '#FFA60F',
  '#FFA60F',
  '#FFA60F',
  '#FFA60F',
  '#FFA60F',
  '#FFA60F',
  '#FFA60F',
  '#FFA60F',
  '#FFA60F',
  '#FFA60F',
  '#FFA60F',
  '#FFA60F',
  '#FFA60F',
  '#FFA60F',
  '#FFA60F',
  '#FEA50F',
  '#FEA50F',
  '#FEA50F',
  '#FEA50F',
  '#FEA50F',
  '#FEA50F',
  '#FEA50F',
  '#FEA50F',
  '#FEA50F',
  '#FEA50F',
  '#FEA50F',
  '#FEA50F',
  '#FEA50F',
  '#FEA50F',
  '#FEA50F',
  '#FEA50F',
  '#FEA50F',
  '#FDA30F',
  '#FDA30F',
  '#FDA30F',
  '#FDA30F',
  '#FDA30F',
  '#FDA30F',
  '#FDA30F',
  '#FDA30F',
  '#FDA30F',
  '#FDA30F',
  '#FDA30F',
  '#FDA30F',
  '#FDA30F',
  '#FDA30F',
  '#FDA30F',
  '#FDA30F',
  '#FDA30F',
  '#FDA30F',
  '#FDA311',
  '#FDA311',
  '#FDA311',
  '#FDA311',
  '#FDA311',
  '#FDA311',
  '#FDA311',
  '#FDA311',
  '#FDA311',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#FA9D13',
  '#F99A13',
  '#F99A13',
  '#F99A13',
  '#F99A13',
  '#F99A13',
  '#F99A13',
  '#F99A13',
  '#F99A13',
  '#F99A13',
  '#F99711',
  '#F99711',
  '#F99711',
  '#F99711',
  '#F99711',
  '#F99711',
  '#F99711',
  '#F99711',
  '#F99711',
  '#F99511',
  '#F99511',
  '#F99511',
  '#F99511',
  '#F99511',
  '#F99511',
  '#F99511',
  '#F99511',
  '#F99511',
  '#F89411',
  '#F89411',
  '#F89411',
  '#F89411',
  '#F89411',
  '#F89411',
  '#F89411',
  '#F89411',
  '#FA9511',
  '#FA9511',
  '#FA9511',
  '#FA9511',
  '#FA9511',
  '#FA9511',
  '#FA9511',
  '#FA9511',
  '#FA9511',
  '#FA9213',
  '#FA9213',
  '#FA9213',
  '#FA9213',
  '#FA9213',
  '#FA9213',
  '#FA9213',
  '#FA9213',
  '#FA9213',
  '#FA9115',
  '#FA9115',
  '#FA9115',
  '#FA9115',
  '#FA9115',
  '#FA9115',
  '#FA9115',
  '#FA9115',
  '#FA9115',
  '#F98F13',
  '#F98F13',
  '#F98F13',
  '#F98F13',
  '#F98F13',
  '#F98F13',
  '#F98F13',
  '#F98F13',
  '#F98F13',
  '#F98F15',
  '#F98F15',
  '#F98F15',
  '#F98F15',
  '#F98F15',
  '#F98F15',
  '#F98F15',
  '#F98F15',
  '#F98C15',
  '#F98C15',
  '#F98C15',
  '#F98C15',
  '#F98C15',
  '#F98C15',
  '#F98C15',
  '#F98C15',
  '#F98C15',
  '#F88915',
  '#F88915',
  '#F88915',
  '#F88915',
  '#F88915',
  '#F88915',
  '#F88915',
  '#F88915',
  '#F88915',
  '#F98915',
  '#F98915',
  '#F98915',
  '#F98915',
  '#F98915',
  '#F98915',
  '#F98915',
  '#F98915',
  '#F98915',
  '#F98A15',
  '#F98A15',
  '#F98A15',
  '#F98A15',
  '#F98A15',
  '#F98A15',
  '#F98A15',
  '#F98A15',
  '#F98A15',
  '#F68B15',
  '#F68B15',
  '#F68B15',
  '#F68B15',
  '#F68B15',
  '#F68B15',
  '#F68B15',
  '#F68B15',
  '#F38711',
  '#F38711',
  '#F38711',
  '#F38711',
  '#F38711',
  '#F38711',
  '#F38711',
  '#F38711',
  '#F38711',
  '#F38513',
  '#F38513',
  '#F38513',
  '#F38513',
  '#F38513',
  '#F38513',
  '#F38513',
  '#F38513',
  '#F38513',
  '#F58413',
  '#F58413',
  '#F58413',
  '#F58413',
  '#F58413',
  '#F58413',
  '#F58413',
  '#F58413',
  '#F58413',
  '#F68315',
  '#F68315',
  '#F68315',
  '#F68315',
  '#F68315',
  '#F68315',
  '#F68315',
  '#F68315',
  '#F68315',
  '#F58011',
  '#F58011',
  '#F58011',
  '#F58011',
  '#F58011',
  '#F58011',
  '#F58011',
  '#F58011',
  '#F58011',
  '#F37D11',
  '#F37D11',
  '#F37D11',
  '#F37D11',
  '#F37D11',
  '#F37D11',
  '#F37D11',
  '#F37D11',
  '#F37D11',
  '#F37D11',
  '#F37D11',
  '#F37D11',
  '#F37D11',
  '#F37D11',
  '#F37D11',
  '#F37D11',
  '#F37D11',
  '#F67A15',
  '#F67A15',
  '#F67A15',
  '#F67A15',
  '#F67A15',
  '#F67A15',
  '#F67A15',
  '#F67A15',
  '#F67A15',
  '#F57813',
  '#F57813',
  '#F57813',
  '#F57813',
  '#F57813',
  '#F57813',
  '#F57813',
  '#F57813',
  '#F57813',
  '#F57513',
  '#F57513',
  '#F57513',
  '#F57513',
  '#F57513',
  '#F57513',
  '#F57513',
  '#F57513',
  '#F57513',
  '#F67415',
  '#F67415',
  '#F67415',
  '#F67415',
  '#F67415',
  '#F67415',
  '#F67415',
  '#F67415',
  '#F67317',
  '#F67317',
  '#F67317',
  '#F67317',
  '#F67317',
  '#F67317',
  '#F67317',
  '#F67317',
  '#F67317',
  '#F67015',
  '#F67015',
  '#F67015',
  '#F67015',
  '#F67015',
  '#F67015',
  '#F67015',
  '#F67015',
  '#F67015',
  '#F66E17',
  '#F66E17',
  '#F66E17',
  '#F66E17',
  '#F66E17',
  '#F66E17',
  '#F66E17',
  '#F66E17',
  '#F66E17',
  '#F66D15',
  '#F66D15',
  '#F66D15',
  '#F66D15',
  '#F66D15',
  '#F66D15',
  '#F66D15',
  '#F66D15',
  '#F66D15',
  '#F56F0F',
  '#F56F0F',
  '#F56F0F',
  '#F56F0F',
  '#F56F0F',
  '#F56F0F',
  '#F56F0F',
  '#F56F0F',
  '#F66B11',
  '#F66B11',
  '#F66B11',
  '#F66B11',
  '#F66B11',
  '#F66B11',
  '#F66B11',
  '#F66B11',
  '#F66B11',
  '#F86611',
  '#F86611',
  '#F86611',
  '#F86611',
  '#F86611',
  '#F86611',
  '#F86611',
  '#F86611',
  '#F86611',
  '#F96513',
  '#F96513',
  '#F96513',
  '#F96513',
  '#F96513',
  '#F96513',
  '#F96513',
  '#F96513',
  '#F96513',
  '#F96713',
  '#F96713',
  '#F96713',
  '#F96713',
  '#F96713',
  '#F96713',
  '#F96713',
  '#F96713',
  '#F96713',
  '#FA6813',
  '#FA6813',
  '#FA6813',
  '#FA6813',
  '#FA6813',
  '#FA6813',
  '#FA6813',
  '#FA6813',
  '#FD6315',
  '#FD6315',
  '#FD6315',
  '#FD6315',
  '#FD6315',
  '#FD6315',
  '#FD6315',
  '#FD6315',
  '#FD6315',
  '#FD5E15',
  '#FD5E15',
  '#FD5E15',
  '#FD5E15',
  '#FD5E15',
  '#FD5E15',
  '#FD5E15',
  '#FD5E15',
  '#FD5E15',
  '#F96019',
  '#F96019',
  '#F96019',
  '#F96019',
  '#F96019',
  '#F96019',
  '#F96019',
  '#F96019',
  '#F96019',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
  '#FE5A17',
];
