import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { BEAuthService } from '@services/backend-services/auth/be-auth.service';
import { ILoginContext } from '@interfaces/login-context.interface';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { ShowFullRegisterFormService } from '@modules/register/show-full-register-form.service';
import { errorLoginUser } from '@utils/users';
import { BaseComponent } from '@shared/components/base.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseComponent {
  public loginError = false;
  public hidePassword = true;
  loading = false;
  progressValue = 6;

  public loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      Validators.email,
    ]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(
    public localStorageService: LocalStorageService,
    private router: Router,
    private toastr: ToastrService,
    private translationMWService: TranslationMWService,
    private beAuthService: BEAuthService,
    private showFullRegisterFormService: ShowFullRegisterFormService
  ) {
    super(localStorageService);

    this.showFullRegisterFormService.hideForm();
  }

  loginUser() {
    if (this.loginForm.valid) {
      //Show the loading bar
      this.loading = true;
      this.progressValue = 45;

      this.beAuthService
        .login(this.loginForm.value as ILoginContext)
        .pipe(first())
        .subscribe(
          (response) => {
            this.localStorageService.feedFromLoginResponse(response);
            // set user's language preference
            this.translationMWService.setTransLanguage(response.language);
            //Fill the progress bar
            this.progressValue = 100;
            // timeOut to give time to see the loading bar progress
            setTimeout(() => {
              // move to main page
              // void this.router.navigate([AppConfig.DEFAULT_MAIN_PAGE]);
              // move to role's specific main page
              void this.router.navigate([this.localStorageService.userRolesMainPage]);
            }, 300);
          },
          (error) => {
            errorLoginUser(error, this.toastr, this.translationMWService);

            this.loginError = true;
            this.loginForm.controls.password.setValue(null);
            this.loginForm.controls.password.setErrors({ [error.error]: true });
            this.loading = false;
          }
        );
    }
  }
}
