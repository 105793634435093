import { Expose, Type } from 'class-transformer';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { BaseUUIDEntity } from './base.entity';
import { CountryEntity } from './country.entity';

class BaseAddressEntity extends BaseUUIDEntity {
  @Expose()
  public address: string;

  @Expose()
  public townOrCity: string;

  @Expose()
  public stateOrRegion: string;

  @Expose()
  public zipCode: string;

  public get countrySource(): string {
    return '';
  }

  public get screenAddress(): string {
    return [this.address, this.townOrCity, this.zipCode, this.stateOrRegion]
      .filter((item) => item)
      .join(', ');
  }

  public get mapAddress(): string {
    return [this.address, this.zipCode, this.townOrCity, this.stateOrRegion, this.countrySource]
      .filter((item) => item)
      .join(', ');
  }

  public translatedCountry(translationMWService: TranslationMWService): string {
    return this.translatedConstant(
      translationMWService,
      'base.constants.country.',
      this.countrySource.toUpperCase()
    );
  }
}

export class BaseAddressStringCountryEntity extends BaseAddressEntity {
  @Expose()
  public country: string;

  public get countrySource(): string {
    return this.country;
  }
}

export class BaseAddressObjectCountryEntity extends BaseAddressEntity {
  @Expose()
  @Type(() => CountryEntity)
  public country: CountryEntity;

  public get countrySource(): string {
    return this.country.iso3;
  }
}
