import { NgModule } from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { SharedModule } from '../shared/shared.module';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { LayoutModule } from '@angular/cdk/layout';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { NavigationComponent } from './layout/navigation/navigation.component';
import { NoAuthGuard } from './guards/no-auth.guard';
import { AutofocusDirective } from 'app/shared/directives/autofocus/autofocus.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BaseComponent } from '@shared/components/base.component';
import { BackendService } from '@services/api/backend/backend.service';
import { BEAuthService } from '@services/backend-services/auth/be-auth.service';
import { SuperAdminGuard } from '@guards/super-admin.guard';
import { CompanyAdminGuard } from '@guards/company-admin.guard';
import { EmployeeGuard } from '@guards/employee.guard';
import { ChangeLanguageModule } from '@shared/components/change-language/change-language.module';
import { BaseListDrawersFiltersComponent } from '@shared/components/base-list-drawers-filters.component';
import { LowerCaseInputDirective } from '@directives/to-lowercase/to-lowercase.directive';
import { MainMenuItemComponent } from './layout/navigation/main-menu-item/main-menu-item.component';
import { DragNDropFilesDirective } from '@directives/drag-n-drop-files/drag-n-drop-files.directive';
import { BreadcrumbsComponent } from './layout/breadcrumbs/breadcrumbs.component';

@NgModule({
  imports: [
    SharedModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    TranslateModule,
    NgxMatSelectSearchModule,
    NgScrollbarModule,
    ChangeLanguageModule,
    ClipboardModule,
  ],
  providers: [
    AuthGuard,
    NoAuthGuard,
    SuperAdminGuard,
    CompanyAdminGuard,
    EmployeeGuard,
    BEAuthService,
    BackendService,
  ],
  declarations: [
    // core components
    ContentLayoutComponent,
    AuthLayoutComponent,
    NavigationComponent,
    BreadcrumbsComponent,
    BaseComponent,
    BaseListDrawersFiltersComponent,
    // directives
    AutofocusDirective,
    LowerCaseInputDirective,
    MainMenuItemComponent,
    DragNDropFilesDirective,
  ],
  exports: [
    AutofocusDirective,
    LowerCaseInputDirective,
    DragNDropFilesDirective,
    TranslateModule,
    NgxMatSelectSearchModule,
    NgScrollbarModule,
    ChangeLanguageModule,
    ClipboardModule,
    BreadcrumbsComponent,
  ],
})
export class CoreModule {}
