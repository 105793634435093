<div class="dialog-container d-flex flex-column justify-content-between flex-grow-1">
  <div class="header">
    <h2 mat-dialog-title class="font-headline-5 color-primary-dark">
      {{ 'agree-to-tos.dialog.title' | translate }}
    </h2>
  </div>
  <div mat-dialog-content class="content d-flex flex-column">
    <ng-scrollbar [visibility]="'hover'" [style]="scrollbarStyle" [autoHeightDisabled]="'true'">
      <app-en-tos-contents *ngIf="data.language === 'en'"></app-en-tos-contents>
      <app-ca-tos-contents *ngIf="data.language === 'ca'"></app-ca-tos-contents>
      <app-fr-tos-contents *ngIf="data.language === 'fr'"></app-fr-tos-contents>
      <app-es-tos-contents *ngIf="data.language === 'es'"></app-es-tos-contents>
      <app-ru-tos-contents *ngIf="data.language === 'ru'"></app-ru-tos-contents>
      <app-de-tos-contents *ngIf="data.language === 'de'"></app-de-tos-contents>
      <app-lt-tos-contents *ngIf="data.language === 'lt'"></app-lt-tos-contents>
      <app-zh-tos-contents *ngIf="data.language === 'zh'"></app-zh-tos-contents>
    </ng-scrollbar>
  </div>
  <div mat-dialog-actions class="buttons-list primary-buttons">
    <!-- <button mat-button class="later" (click)="close()" cdkFocusInitial>
      {{ 'buttons.later' | translate }}
    </button> -->
    <button mat-button class="agree font-button" (click)="agree()">
      {{ 'buttons.agree' | translate }}
    </button>
  </div>
</div>
