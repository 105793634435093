import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { locale, loadMessages } from 'devextreme/localization';
// import DevExtreme translation message files
import * as caMessages from 'assets/i18n-devextreme/ca.json';
import * as esMessages from 'devextreme/localization/messages/es.json';
import * as frMessages from 'devextreme/localization/messages/fr.json';
import * as ruMessages from 'devextreme/localization/messages/ru.json';
import * as deMessages from 'devextreme/localization/messages/de.json';
import * as ltMessages from 'assets/i18n-devextreme/lt.json';
import * as zhMessages from 'devextreme/localization/messages/zh.json';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { BehaviorSubject, Observable } from 'rxjs';

export class TranslationInstantParams {
  label: string;
  vars: any;

  constructor(label: string, vars = {}) {
    this.label = label;
    this.vars = vars;
  }
}

@Injectable({ providedIn: 'root' })
export class TranslationMWService {
  constructor(
    private translateService: TranslateService,
    private localStorageService: LocalStorageService
  ) {
    // load DevExtreme translations
    loadMessages(caMessages);
    loadMessages(esMessages);
    loadMessages(frMessages);
    loadMessages(ruMessages);
    loadMessages(deMessages);
    loadMessages(ltMessages);
    loadMessages(zhMessages);
    // subscribe to language change
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.localStorageService.currentLanguage = event.lang;
    });
  }

  public instant(label: string, vars = {}): string {
    return this.translateService.instant(label, vars) as string;
  }

  public setTransLanguage(lang: string) {
    locale(lang);
    return this.translateService.use(lang);
  }

  public get currentLanguage(): string {
    return this.translateService.currentLang;
  }
}
