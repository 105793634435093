import { Expose } from 'class-transformer';
import { BaseDataProviderSplitEntity } from './base.entity';
import { formattedDate, formattedNumber, naiveRound, utcAddDate } from '@utils/utils';
import * as moment from 'moment';

export class PricePlanEntity extends BaseDataProviderSplitEntity {
  @Expose()
  public name: string;

  @Expose()
  public createdTime: string;

  @Expose()
  public unitsOfTime: number;

  public newExpirationDate(language: string, date: string): string {
    return formattedDate(language, moment(utcAddDate(date, this.unitsOfTime, 'months')).toDate());
  }

  @Expose()
  public active: boolean;

  get changeActiveStateText(): string {
    return this.active ? 'deactivate' : 'activate';
  }

  get currentActiveStateText(): string {
    return this.active ? 'activated' : 'deactivated';
  }

  @Expose()
  public deletable: boolean;

  @Expose()
  public erpCode: string;

  @Expose()
  public free: boolean;

  get freeText(): string {
    return this.free ? 'free' : 'paid';
  }

  @Expose()
  public storagePeriod: number;

  @Expose()
  public dataFrequency: number;

  @Expose()
  public expiredWaitingPeriod: number;

  @Expose()
  public amount: number;

  public localizedAmount(language: string): string {
    return formattedNumber(language, this.amount, 2, 2);
  }

  @Expose()
  public vat: number;

  public localizedVat(language: string): string {
    return formattedNumber(language, this.vat, 0, 2);
  }

  public get vatAmount(): number {
    return naiveRound(this.amount * (this.vat / 100), 2);
  }

  public localizedVatAmount(language: string): string {
    return formattedNumber(language, this.vatAmount, 2, 2);
  }

  public get vatIncludedAmount(): number {
    return naiveRound(this.amount + this.vatAmount, 2);
  }

  public localizedVatIncludedAmount(language: string): string {
    return formattedNumber(language, this.vatIncludedAmount, 2, 2);
  }
}
