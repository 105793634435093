import { SensorConstantEntity } from '@constants/sensor-constant.entity';
import { SensorTypesConstants } from '@constants/sensor-types.constants';
import { Expose, Type } from 'class-transformer';
import { BaseEntity } from './base.entity';
import { BaseHistoricalEntity } from './base-historical.entity';
import { SensorAlarmEntity } from './sensor-alarm.entity';
import { StringIndexedEntities, formattedNumber } from '@utils/utils';

export class SensorEntity extends BaseEntity {
  @Expose()
  public sensorType: number;

  private sensorConstant$: SensorConstantEntity;

  public get sensorConstant(): SensorConstantEntity {
    if (!this.sensorConstant$) {
      this.sensorConstant$ = new SensorTypesConstants().findById(
        this.sensorType
      ) as SensorConstantEntity;
    }
    return this.sensorConstant$;
  }

  @Expose()
  public lastUpdated: string;

  @Expose()
  public value: number;

  public formattedValue(language: string): string {
    return formattedNumber(
      language,
      this.value,
      this.minimumFractionDigits,
      this.maximumFractionDigits
    );
  }

  @Expose()
  @Type(() => BaseHistoricalEntity)
  public readings: BaseHistoricalEntity[];

  private indexedReadings$: StringIndexedEntities<BaseHistoricalEntity> = {};

  private feedIndexedReadings(): void {
    for (const reading of this.readings) {
      this.indexedReadings$[reading.readingTime] = reading;
    }
  }

  public get indexedReadings(): StringIndexedEntities<BaseHistoricalEntity> {
    if (!Object.keys(this.indexedReadings$).length) {
      this.feedIndexedReadings();
    }
    return this.indexedReadings$;
  }

  public readingHTMLColor(readingTime: string): string {
    const reading = this.indexedReadings[readingTime];
    return this.sensorConstant.getHTMLColor(reading ? reading.value : undefined);
  }

  public readingMatchesErrorValue(dataProvider: string | number, readingTime: string): boolean {
    const reading = this.indexedReadings[readingTime];
    return this.sensorConstant.matchesErrorValue(dataProvider, reading ? reading.value : undefined);
  }

  public readingErrorValue(dataProvider: string | number, readingTime: string): string {
    const reading = this.indexedReadings[readingTime];
    return this.sensorConstant.getErrorValue(dataProvider, reading ? reading.value : undefined);
  }

  public readingMatchesSensorAlarm(readingTime: string, sensorAlarm: SensorAlarmEntity): boolean {
    const reading = this.indexedReadings[readingTime];
    if (sensorAlarm.sensorType !== this.sensorType) {
      return false;
    }
    return sensorAlarm.matchAlarm(reading.value);
  }

  public matchesSensorAlarm(sensorAlarm: SensorAlarmEntity) {
    if (sensorAlarm.sensorType !== this.sensorType) {
      return false;
    }
    return sensorAlarm.matchAlarm(this.value);
  }

  public get isValidValue(): boolean {
    return ![undefined, null].includes(this.value);
  }

  public get htmlColor(): string {
    return this.sensorConstant.getHTMLColor(this.value);
  }

  public get minimumFractionDigits(): number {
    return this.sensorConstant.minimumFractionDigits;
  }

  public get maximumFractionDigits(): number {
    return this.sensorConstant.maximumFractionDigits;
  }

  public matchesErrorValue(dataProvider: string | number): boolean {
    return this.sensorConstant.matchesErrorValue(dataProvider, this.value);
  }

  public errorValue(dataProvider: string | number): string {
    return this.sensorConstant.getErrorValue(dataProvider, this.value);
  }

  public get numberValue(): number {
    return Number(this.value);
  }
}
