import { StringIndexedEntities } from '@utils/utils';
import { ConstantEntity, ConstantGroup } from './base.constants';
import { SensorConstantEntity } from './sensor-constant.entity';
import {
  CO2_COLORS,
  HUMIDITY_COLORS,
  TEMPERATURE_CELSIUS_COLORS,
  TEMPERATURE_FARENHEIT_COLORS,
} from './sensor-types.colors';
import {
  CO2_ERRORS,
  HUMIDITY_ERRORS,
  NO_ERRORS,
  TEMPERATURE_CELSIUS_ERRORS,
  TEMPERATURE_FARENHEIT_ERRORS,
} from './sensor-value.errors';

const JAVELIN_DEBUG = new SensorConstantEntity({
  value: -1,
  name: 'JAVELIN_DEBUG',
  measureUnit: '',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  translationTag: 'sensorType',
  htmlColors: [],
  valueErrorCodes: NO_ERRORS,
  minColoredValue: -1,
  maxColoredValue: -1,
  alarmLimits: [],
  alarmValueRange: { from: -1, to: -1 },
  isCalculatedTemperature: false,
});

const TEMPERATURE_C = new SensorConstantEntity({
  value: 0,
  name: 'TEMPERATURE_C',
  measureUnit: '°C',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  translationTag: 'sensorType',
  htmlColors: TEMPERATURE_CELSIUS_COLORS,
  valueErrorCodes: TEMPERATURE_CELSIUS_ERRORS,
  minColoredValue: 0,
  maxColoredValue: 60,
  alarmLimits: ['MAXIMUM'],
  alarmValueRange: { from: 0, to: 100 },
  isCalculatedTemperature: false,
});

const TEMPERATURE_F = new SensorConstantEntity({
  value: 0,
  name: 'TEMPERATURE_F',
  measureUnit: '°F',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  translationTag: 'sensorType',
  htmlColors: TEMPERATURE_FARENHEIT_COLORS,
  valueErrorCodes: TEMPERATURE_FARENHEIT_ERRORS,
  minColoredValue: 32,
  maxColoredValue: 140,
  alarmLimits: ['MAXIMUM'],
  alarmValueRange: { from: 32, to: 212 },
  isCalculatedTemperature: false,
});

const HUMIDITY = new SensorConstantEntity({
  value: 1,
  name: 'HUMIDITY',
  measureUnit: '%',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  translationTag: 'sensorType',
  htmlColors: HUMIDITY_COLORS,
  valueErrorCodes: HUMIDITY_ERRORS,
  minColoredValue: 0,
  maxColoredValue: 30,
  alarmLimits: ['MINIMUM', 'MAXIMUM'],
  alarmValueRange: { from: 0, to: 100 },
  isCalculatedTemperature: false,
});

const CO2 = new SensorConstantEntity({
  value: 2,
  name: 'CO2',
  measureUnit: 'ppm',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  translationTag: 'sensorType',
  htmlColors: CO2_COLORS,
  valueErrorCodes: CO2_ERRORS,
  minColoredValue: 300,
  maxColoredValue: 2000,
  alarmLimits: [],
  alarmValueRange: { from: 0, to: 100 },
  isCalculatedTemperature: false,
});

const CO2UP = new SensorConstantEntity({
  value: 3,
  name: 'CO2UP',
  measureUnit: 'ppm',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  translationTag: 'sensorType',
  htmlColors: CO2_COLORS,
  valueErrorCodes: CO2_ERRORS,
  minColoredValue: 300,
  maxColoredValue: 2000,
  alarmLimits: [],
  alarmValueRange: { from: 0, to: 100 },
  isCalculatedTemperature: false,
});

const CO2DOWN = new SensorConstantEntity({
  value: 4,
  name: 'CO2DOWN',
  measureUnit: 'ppm',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  translationTag: 'sensorType',
  htmlColors: CO2_COLORS,
  valueErrorCodes: CO2_ERRORS,
  minColoredValue: 300,
  maxColoredValue: 2000,
  alarmLimits: [],
  alarmValueRange: { from: 0, to: 100 },
  isCalculatedTemperature: false,
});

const DEW = new SensorConstantEntity({
  value: 5,
  name: 'DEW',
  measureUnit: '',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  translationTag: 'sensorType',
  htmlColors: [],
  valueErrorCodes: NO_ERRORS,
  minColoredValue: 0,
  maxColoredValue: 0,
  alarmLimits: [],
  alarmValueRange: { from: 0, to: 100 },
  isCalculatedTemperature: true,
});

const DEW_OUTER_TEMP = new SensorConstantEntity({
  value: 6,
  name: 'DEW_OUTER_TEMP',
  measureUnit: '',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  translationTag: 'sensorType',
  htmlColors: [],
  valueErrorCodes: NO_ERRORS,
  minColoredValue: 0,
  maxColoredValue: 0,
  alarmLimits: [],
  alarmValueRange: { from: 0, to: 100 },
  isCalculatedTemperature: true,
});

const DEW_INNER_TEMP = new SensorConstantEntity({
  value: 7,
  name: 'DEW_INNER_TEMP',
  measureUnit: '',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  translationTag: 'sensorType',
  htmlColors: [],
  valueErrorCodes: NO_ERRORS,
  minColoredValue: 0,
  maxColoredValue: 0,
  alarmLimits: [],
  alarmValueRange: { from: 0, to: 100 },
  isCalculatedTemperature: true,
});

const DEW_INNER_HUM = new SensorConstantEntity({
  value: 8,
  name: 'DEW_INNER_HUM',
  measureUnit: '%',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  translationTag: 'sensorType',
  htmlColors: [],
  valueErrorCodes: NO_ERRORS,
  minColoredValue: 0,
  maxColoredValue: 0,
  alarmLimits: [],
  alarmValueRange: { from: 0, to: 100 },
  isCalculatedTemperature: false,
});

const ALL_SENSOR_TYPES: SensorConstantEntity[] = [
  TEMPERATURE_C,
  TEMPERATURE_F,
  HUMIDITY,
  CO2,
  CO2UP,
  CO2DOWN,
  DEW,
  DEW_OUTER_TEMP,
  DEW_INNER_TEMP,
  DEW_INNER_HUM,
];


const PROBES_SENSOR_TYPES: SensorConstantEntity[] = [TEMPERATURE_C, TEMPERATURE_F, HUMIDITY, CO2];

const SILOS_SENSOR_TYPES: SensorConstantEntity[] = [DEW, CO2];

const DEW_SENSOR_TYPES: SensorConstantEntity[] = [
  DEW,
  DEW_INNER_HUM,
  DEW_INNER_TEMP,
  DEW_OUTER_TEMP,
];
const CO2_SENSOR_TYPES: SensorConstantEntity[] = [CO2, CO2UP, CO2DOWN];

const SILOS_SENSOR_TYPES_SELECTOR: StringIndexedEntities<SensorConstantEntity[]> = {
  [DEW.value]: DEW_SENSOR_TYPES,
  [CO2.value]: CO2_SENSOR_TYPES,
};

export class SensorTypesConstants extends ConstantGroup {
  map(): Map<any, ConstantEntity> {
    return this.mapByTemperatureUoM('C');
  }

  mapByTemperatureUoM(tempUoM: string): Map<any, ConstantEntity> {
    const initialMap = new Map<any, ConstantEntity>();
    return this.list().reduce((map, obj) => {
      if (obj.name.startsWith('TEMPERATURE') && obj.name !== `TEMPERATURE_${tempUoM}`) {
        return map;
      }
      map[obj.value] = obj;
      return map;
    }, initialMap);
  }

  public findExtendedSensorConstantById = (value: number) =>
    this.findExtendedById(SensorConstantEntity, value);

  list(): SensorConstantEntity[] {
    return ALL_SENSOR_TYPES;
  }

  siloSensorTypesSelector(): StringIndexedEntities<SensorConstantEntity[]> {
    return SILOS_SENSOR_TYPES_SELECTOR;
  }

  listProbeSensors(tempUoM: string): SensorConstantEntity[] {
    return PROBES_SENSOR_TYPES.filter(
      (stc) => !stc.name.startsWith('TEMPERATURE') || stc.name === `TEMPERATURE_${tempUoM}`
    );
  }

  listAlarmedSensors(tempUoM: string = 'C'): SensorConstantEntity[] {
    return PROBES_SENSOR_TYPES.filter(
      (stc) =>
        stc.alarmLimits &&
        stc.alarmLimits.length &&
        (!stc.name.startsWith('TEMPERATURE') || stc.name === `TEMPERATURE_${tempUoM}`)
    );
  }

  listSiloSensors(): SensorConstantEntity[] {
    return SILOS_SENSOR_TYPES;
  }

  static get JAVELIN_DEBUG() {
    return JAVELIN_DEBUG;
  }

  static get TEMPERATURE_C() {
    return TEMPERATURE_C;
  }

  static get TEMPERATURE_F() {
    return TEMPERATURE_F;
  }

  static get HUMIDITY() {
    return HUMIDITY;
  }

  static get CO2() {
    return CO2;
  }

  static get CO2DOWN() {
    return CO2DOWN;
  }

  static get CO2UP() {
    return CO2UP;
  }

  static get DEW() {
    return DEW;
  }

  static get DEW_OUTER_TEMP() {
    return DEW_OUTER_TEMP;
  }

  static get DEW_INNER_TEMP() {
    return DEW_INNER_TEMP;
  }

  static get DEW_INNER_HUM() {
    return DEW_INNER_HUM;
  }
}
