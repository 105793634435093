<ng-container *ngIf="item.visible">
  <a
    mat-list-item
    [ngStyle]="{ 'padding-left': depth * 1 + 'rem' }"
    (click)="onItemSelected(item)"
    [ngClass]="{
      'selected-item': selectedItem && !(item?.children?.length > 0),
      expanded: expanded
    }"
    class="menu-list-item"
    *ngIf="item.roleAllowed(userRoleId)"
  >
    <span class="material-symbols-outlined"> {{ item.icon }} </span>
    <span *ngIf="sideNavOpenState || !item.icon" class="item-name">
      {{ item.name | translate }}
    </span>
    <span fxFlex *ngIf="item.children && item.children.length" class="expand-icon-container">
    </span>
  </a>
  <div *ngIf="item.roleAllowed(userRoleId)">
    <ng-container *ngFor="let child of item.children">
      <app-main-menu-item [item]="child" [depth]="depth + 1" [sideNavOpenState]="sideNavOpenState">
      </app-main-menu-item>
    </ng-container>
  </div>
</ng-container>
