<div class="container">
  <div class="auth-title-container">
    <!--   <div class="auth-title head-line-3">{{ 'auth.login.forgotPassword' | translate }}</div> -->
    <ng-container *ngIf="actionToken && confirmInvitation">
      <div class="auth-title head-line-3">
        {{ 'auth.changePassword.passwordConfirmInvitation.title' | translate }}
      </div>
      <div class="auth-subtitle m3-title-small">
        {{ 'auth.changePassword.passwordConfirmInvitation.subtitle' | translate }}
      </div>
    </ng-container>

    <ng-container *ngIf="actionToken && changePassword">
      <div class="auth-title head-line-3">
        {{ 'auth.changePassword.title' | translate }}
      </div>
      <div class="auth-subtitle m3-title-small">
        {{ 'auth.changePassword.subtitle' | translate }}
      </div>
    </ng-container>
  </div>
  <form [formGroup]="actionForm" #f="ngForm">
    <div class="mat-form-field-container inputs" *ngIf="actionToken && activateAccountTokenExpired">
      <span class="fake-label m3-label-large">{{ 'action.form.email' | translate }}</span>
      <mat-form-field class="input-full-width">
        <mat-label>{{ 'auth.login.emailPlaceholder' | translate }}</mat-label>
        <input matInput formControlName="email" name="email" autocomplete="on" type="email" />
        <mat-error *ngIf="actionForm.controls.email.hasError('required')">
          {{ 'form.error.fieldRequired' | translate }}
        </mat-error>
        <mat-error
          *ngIf="
            actionForm.controls.email.hasError('email') ||
            actionForm.controls.email.hasError('pattern')
          "
        >
          {{ 'form.error.typeEmail' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <ng-container *ngIf="actionToken && !activateAccountTokenExpired">
      <div class="mat-form-field-container inputs">
        <span class="fake-label m3-label-large">{{
          'change-password.form.new-password' | translate
        }}</span>
        <mat-form-field class="input-full-width">
          <mat-label></mat-label>
          <input
            matInput
            formControlName="newPassword"
            name="newPassword"
            autocomplete="on"
            [type]="hidePassword ? 'password' : 'text'"
            placeholder="{{ 'change-password.form.placeholders.new-password' | translate }}"
            (change)="actionForm.controls.newPasswordConfirm.updateValueAndValidity()"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="$event.stopPropagation(); hidePassword = !hidePassword"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePassword"
          >
            <span class="material-symbols-outlined">
              {{ hidePassword ? 'visibility_off' : 'visibility' }}
            </span>
          </button>
          <mat-error *ngIf="actionForm.controls.newPassword.hasError('required')">
            {{ 'form.error.fieldRequired' | translate }}
          </mat-error>
          <mat-error *ngIf="actionForm.controls.newPassword.hasError('pattern')">
            {{ 'form.error.password-pattern' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mat-form-field-container">
        <span class="fake-label m3-label-large">{{
          'change-password.form.new-password-confirm' | translate
        }}</span>
        <mat-form-field class="input-full-width">
          <mat-label></mat-label>
          <input
            matInput
            formControlName="newPasswordConfirm"
            name="newPasswordConfirm"
            autocomplete="on"
            [type]="hidePassword ? 'password' : 'text'"
            placeholder="{{ 'change-password.form.placeholders.new-password-confirm' | translate }}"
            (change)="actionForm.controls.newPasswordConfirm.updateValueAndValidity()"
          />
          <mat-error *ngIf="actionForm.controls.newPasswordConfirm.hasError('valueDifferentFrom')">
            {{ 'form.error.passwords-do-not-match' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>
    <div class="buttons-list d-flex justify-content-between">
      <button
        type="button"
        mat-button
        (click)="onCancelClicked()"
        class="cancel font-button button-outline"
        color="primary"
        mat-stroked-button
      >
        <span>{{ 'buttons.cancel' | translate }}</span>
      </button>
      <button
        type="button"
        color="primary"
        mat-flat-button
        class="font-button button-primary"
        (click)="requestAction()"
        [disabled]="actionForm.pristine || !actionForm.valid"
      >
        <span>{{ 'action.form.button.request' | translate }}</span>
      </button>
    </div>
  </form>
</div>
