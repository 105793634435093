import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from './angular-material.module';
import { InputElementsModule } from './input-elements/input-elements.module';
import { UserRolesConstants } from '@constants/user-roles.constants';
import { BoxModule } from './box/box.module';
import { TranslateModule } from '@ngx-translate/core';
import { CustomPipesModule } from '@pipes/custom-pipes.module';
import { EventTypesConstants } from '@constants/event-types.constants';
import { SensorTypesConstants } from '@constants/sensor-types.constants';
import { DevExtremeModule } from './dev-extreme.module';
import { CustomProgressBarComponent } from './components/custom-progressbar/custom-progressbar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AngularMaterialModule,
    InputElementsModule,
    BoxModule,
    TranslateModule,
    CustomPipesModule,
    DevExtremeModule,
  ],
  declarations: [CustomProgressBarComponent],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AngularMaterialModule,
    InputElementsModule,
    BoxModule,
    TranslateModule,
    CustomPipesModule,
    DevExtremeModule,
    CustomProgressBarComponent,
  ],
  providers: [UserRolesConstants, EventTypesConstants, SensorTypesConstants],
})
export class SharedModule {}
