import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryCodeSelectorComponent } from './country-code-selector.component';
import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@core/core.module';
import { AngularMaterialModule } from '@shared/angular-material.module';
import { SelectCountryModule } from '../select-country/select-country.module';




@NgModule({
  declarations: [
    CountryCodeSelectorComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    AngularMaterialModule,
    SelectCountryModule
  ],
  exports:[CountryCodeSelectorComponent]
})
export class CountryCodeSelectorModule { }
