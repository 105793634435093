import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionTokenKindsConstants } from '@constants/action-tokens.constants';
import { ConstantsDict } from '@constants/base.constants';
import { ActionTokenEntity } from '@entities/action-token.entity';
import { BEActionTokensService } from '@services/backend-services/action-tokens/be-action-tokens.service';
import { sameValueAs } from '@shared/validators/match.validator';
import { generatePassword, initializeClass } from '@utils/utils';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import * as HttpStatus from 'http-status-codes';
import { IActionTokenChangePasswordRequestContext } from '@interfaces/action-token-change-password-context.interface';
import { IActionTokenRequestContext } from '@interfaces/action-token-request-context.interface';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { BaseComponent } from '@shared/components/base.component';

@Component({
  selector: 'app-password-confirm-invitation',
  templateUrl: './change-password-confirm-invitation.component.html',
  styleUrls: ['./change-password-confirm-invitation.component.scss'],
})
export class ChangePasswordConfirmInvitationComponent extends BaseComponent implements OnInit {
  public actionTokenId: string;
  public actionToken: ActionTokenEntity;
  public actionTokenExpired = false;
  public actionTokenKindsConstsDict: ConstantsDict;
  public hidePassword = true;

  public actionForm: FormGroup;

  constructor(
    @Inject(Window) private window: Window,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private translationMWService: TranslationMWService,
    public localStorageService: LocalStorageService,
    private beActionTokensService: BEActionTokensService
  ) {
    super(localStorageService);

    this.actionForm = new FormGroup({
      kind: new FormControl(''),
      domain: new FormControl(''),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
      newPassword: new FormControl(undefined, [Validators.required, Validators.pattern(/^.{8,}$/)]),
      newPasswordConfirm: new FormControl(undefined, []),
    });
    this.actionForm.controls.newPasswordConfirm.setValidators([
      Validators.required,
      sameValueAs(this.actionForm, 'newPassword'),
    ]);

    this.actionTokenKindsConstsDict = new ActionTokenKindsConstants().dict();
    const currentDomain =
      this.window.location.hostname +
      (this.window.location.port.length ? ':'.concat(this.window.location.port) : '');
    this.actionForm.controls.domain.setValue(currentDomain);
  }

  public clearPassword() {
    this.actionForm.controls.newPassword.setValue('');
    this.actionForm.controls.newPasswordConfirm.updateValueAndValidity();
  }

  public renewPassword() {
    this.actionForm.controls.newPassword.setValue(generatePassword());
    this.actionForm.controls.newPasswordConfirm.updateValueAndValidity();
  }

  ngOnInit() {
    this.actionTokenId = this.activatedRoute.snapshot.paramMap.get('id');
    this.beActionTokensService
      .get(this.actionTokenId)
      .pipe(first())
      .subscribe(
        (actionToken) => {
          this.actionToken = actionToken;
          this.actionForm.controls.email.setValue(this.actionToken.user.email);
          this.actionForm.controls.kind.setValue(this.actionToken.kind);
          this.translationMWService.setTransLanguage(this.actionToken.language);
        },
        (error) => {
          if (error.status === HttpStatus.StatusCodes.NOT_FOUND) {
            this.toastr.error(
              this.translationMWService.instant('action.notfound.toastr.error.message'),
              this.translationMWService.instant('action.notfound.toastr.error.title')
            );
            // navigate to main site
            const redirectPage = this.localStorageService.userRolesMainPage
              ? this.localStorageService.userRolesMainPage
              : 'auth/login';
            void this.router.navigate([redirectPage]);
          }
          if (error.status === HttpStatus.StatusCodes.REQUEST_TIMEOUT) {
            this.actionToken = initializeClass(
              ActionTokenEntity,
              error.error.data as Record<string, unknown>
            );
            this.actionForm.controls.email.setValue(this.actionToken.user.email);
            this.actionForm.controls.kind.setValue(this.actionToken.kind);
            const newDummyPasswd = generatePassword();
            this.actionForm.controls.newPassword.setValue(newDummyPasswd);
            this.actionForm.controls.newPasswordConfirm.setValue(newDummyPasswd);
            this.actionTokenExpired = true;
            this.translationMWService.setTransLanguage(this.actionToken.language).subscribe(() => {
              this.toastr.warning(
                this.translationMWService.instant('action.expired.toastr.error.message'),
                this.translationMWService.instant('action.expired.toastr.error.title')
              );
            });
          }
        }
      );
  }

  get activateAccountTokenExpired(): boolean {
    return (
      [
        ActionTokenKindsConstants.ACTIVATEACCOUNT.value,
        ActionTokenKindsConstants.PASSWDCONFIRMINVITATION.value,
      ].includes(this.actionToken.kind) && this.actionTokenExpired
    );
  }

  get resettingPassword(): boolean {
    return [
      ActionTokenKindsConstants.CHANGEPASSWORD.value,
      ActionTokenKindsConstants.PASSWDCONFIRMINVITATION.value,
    ].includes(this.actionToken.kind);
  }

  get confirmInvitation(): boolean {
    return [ActionTokenKindsConstants.PASSWDCONFIRMINVITATION.value].includes(
      this.actionToken.kind
    );
  }

  get changePassword(): boolean {
    return [ActionTokenKindsConstants.CHANGEPASSWORD.value].includes(this.actionToken.kind);
  }

  onCancelClicked() {
    this.actionTokenExpired = false;
    // navigate to login page
    void this.router.navigate(['auth/login']);
  }

  requestAction() {
    if (this.actionForm.valid) {
      /*
       * ACTIVATEACCOUNT / PASSWDCONFIRMINVITATION
       */
      if (this.activateAccountTokenExpired) {
        // PUT new action to activate account
        this.beActionTokensService
          .request({
            ...this.actionForm.value,
            language: this.currentLanguage,
          } as IActionTokenRequestContext)
          .pipe(first())
          .subscribe(() => {
            // show account's email confirmation instructions
            this.toastr.info(
              this.translationMWService.instant(
                'register.toastr.activate-account-instructions.message',
                {
                  email: this.actionToken.user.email,
                }
              ),
              this.translationMWService.instant(
                'register.toastr.activate-account-instructions.title'
              ),
              {
                disableTimeOut: true,
                closeButton: true,
              }
            );
            // navigate to main site
            void this.router.navigate([this.localStorageService.userRolesMainPage]);
          });
      }
      /*
       * CHANGEPASSWORD / PASSWDCONFIRMINVITATION
       */
      if (this.resettingPassword) {
        // POST action to change password
        this.beActionTokensService
          .process(this.actionTokenId, {
            ...this.actionForm.value,
            password: this.actionForm.value.newPassword,
          } as IActionTokenChangePasswordRequestContext)
          .pipe(first())
          .subscribe(
            () => {
              this.toastr.success(
                this.translationMWService.instant('change-password.toastr.success.message')
              );
              // navigate to login page
              void this.router.navigate(['/auth/login']);
            },
            () => {
              this.toastr.error(
                this.translationMWService.instant('change-password.toastr.error.message')
              );
            }
          );
      }
    } // if (this.actionForm.valid)
  } // requestAction()
}
