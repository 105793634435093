<div class="d-flex justify-content-center">
  <div><span class="head-line-5">CTC 云</span></div>
</div>
<p class="font-body-1">
  <span class="font-subtitle-2">CTC 云支架 </span><br />
  根据 7 月 11 日第 34/2002 号法律关于信息社会服务和电子商务的第 10
  条中规定的信息义务，将反映以下数据：
</p>

<span class="font-body-1">实体 : Gescaser, S.A.</span><br />
<span class="font-body-1">CIF : A25276635</span><br />
<span class="font-body-1">地址 : Carrer Sant Pere 1, 25100 Almacelles (莱里达)</span><br />
<span class="font-body-1"
  >在莱里达商业登记处注册，第 81 卷，第 44 对开页，第 L1450 页，第 1 个铭文。 </span
><br />
<span class="font-body-1">电话 : 973740695</span><br />
<span class="font-body-1"
  >电子邮件 :<a href="mailto:gescaser@gescaser.com">gescaser@gescaser.com</a></span
><br />

<p class="font-body-1">
  <span class="font-subtitle-2">对象 </span><br />
  数据控制者向用户提供本文件，以遵守 2002 年 7 月 11 日关于信息社会服务和电子商务的第 34/2002
  号法律的规定以及现行的数据保护法规，并通过网络支持和移动应用程序（&ldquo;Web/App&rdquo;）。
</p>
<p class="font-body-1">
  任何访问此应用程序的人都承担用户的角色，承诺遵守并严格遵守此处制定的规定，以及可能适用的任何其他法律规定。
</p>
<p class="font-body-1">
  数据控制者保留修改网站/应用程序上可能出现的任何类型的信息的权利，而无需事先通知或通知用户此类修改，在网站/应用程序上发布即为足够。
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">责任 </span><br />
  数据控制者免除因网站/应用程序上发布的信息而产生的任何类型的责任，前提是此类信息已被与其无关的第三方操纵或引入。
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">链接政策 </span><br />
  从网站/应用程序，它可能被重定向到来自第三方网站的内容。由于数据控制者无法控制第三方在其网站/应用程序上介绍的内容，因此不对此类内容承担任何责任。
</p>
<p class="font-body-1">
  但是，数据控制者承诺立即删除任何可能违反国家或国际法律、道德或公共秩序的内容。
</p>
<p class="font-body-1">
  控制者不对存储在论坛、聊天、博客生成器、评论、社交网络或任何其他允许第三方在
  Web/Web/应用程序上独立发布内容的方式的信息和内容负责。如果用户认为网站/应用程序上的任何内容可能容易受到此分类的影响，请立即通知其。
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">数据保护 </span><br />
  数据控制者致力于遵守当前的数据保护法规，包括 GDPR 和
  LOPDGDD。同样，数据控制者告知其遵守关于信息社会服务和电子商务的第 34/2002
  号法律，并将始终请求您同意出于商业目的处理您的数据。
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">知识产权和工业产权 </span><br />
  网站/应用程序，包括其编程、编辑、编译和运行所需的其他元素、设计、徽标、文本和/或图形，均为控制者的财产，或获得作者的许可或明确授权。所有内容均受知识产权和工业产权法规保护。
</p>
<p class="font-body-1">
  任何未经控制者事先授权的使用都将被视为严重侵犯作者的知识产权或工业产权。不属于数据控制者所有且可能出现在网站/应用程序上的设计、徽标、文本和/或图形属于其各自所有者。
</p>
<p class="font-body-1">
  <span class="font-subtitle-2">适用法律和管辖权 </span><br />
  用户和数据控制者之间的关系将受西班牙境内现行法规的约束。如有争议，双方应依法向相应的法官和法院申请。
</p>
