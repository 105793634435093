import { Expose } from 'class-transformer';
import { BaseEntity } from './base.entity';

export class LanguageEntity extends BaseEntity {
  @Expose()
  public iso2: string;

  @Expose()
  public name: string;
}
