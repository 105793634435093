import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CountriesConstants } from '@constants/countries.constants';
import { CountryConstantEntity } from '@constants/country-constant.entity';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-country-code-selector',
  templateUrl: './country-code-selector.component.html',
  styleUrls: ['./country-code-selector.component.scss'],
})
export class CountryCodeSelectorComponent implements OnChanges {
  public filteredOptions: Observable<CountryConstantEntity[]>;

  @Input() showClearButton = false;
  @Input() formStyle = true;
  @Input() countryFormControl: FormControl;
  @Input() countryControlLabel = 'country';

  @Output() countryCodeSelected: EventEmitter<CountryConstantEntity> =
    new EventEmitter<CountryConstantEntity>();

  constructor(
    private countriesConstants: CountriesConstants,
    private translationMWService: TranslationMWService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.countryFormControl && changes.countryFormControl.currentValue) {
      this.filteredOptions = this.countryFormControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.filter(value))
      );
    }
    this.filteredOptions.subscribe((values) => {
      if (values && values.length) {
        this.countryCodeSelected.emit(undefined);
      }
    });
  }

  private filter(entry: any): CountryConstantEntity[] {
    const filterValue =
      entry instanceof CountryConstantEntity ? entry.name.toLowerCase() : entry.toLowerCase();

    return !entry
      ? this.countriesConstants.list()
      : this.countriesConstants
          .list()
          .filter((constant) =>
            this.translationMWService
              .instant(constant.translationPath)
              .toLowerCase()
              .includes(filterValue)
          );
  }

  displayFn = (country: CountryConstantEntity): string =>
    country && country.translationPath
      ? this.translationMWService.instant(country.translationPath)
      : '';

  optionSelected() {
    this.countryCodeSelected.emit(
      this.countryFormControl.value instanceof CountryConstantEntity
        ? this.countryFormControl.value
        : undefined
    );
  }
}
